import useStyle from "./SliderArrows.styles";
import slider_arrow_next from "../../assets/images/slider-arrow-next.svg";
import slider_arrow_prev from "../../assets/images/slider-arrow-prev.svg";

function SliderArrowNext(props) {
  const { className, style, onClick } = props;
  const styles = useStyle();
  return (
    <div
      className={`${styles.arrowNext} ${className}`}
      style={{ ...style }}
      onClick={onClick}
    >
      <img src={slider_arrow_next} alt="" />
    </div>
  );
}

function SliderArrowPrev(props) {
  const { className, style, onClick } = props;
  const styles = useStyle();
  return (
    <div
      className={`${styles.arrowPrev} ${className}`}
      style={{ ...style }}
      onClick={onClick}
    >
      <img src={slider_arrow_prev} alt="" />
    </div>
  );
}

export { SliderArrowNext, SliderArrowPrev };
