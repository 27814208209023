import React from 'react';
import { makeStyles, useTheme } from '@material-ui/styles';
import classnames from 'classnames';

// styles
var useStyles = makeStyles((theme) => ({
  dotBase: {
    width: 5,
    height: 5,
    backgroundColor: theme.palette.text.hint,
    borderRadius: '50%',
    transition: theme.transitions.create('background-color'),
  },
  dotLarge: {
    width: 8,
    height: 8,
  },
}));

export default function Dot({ size, color }) {
  var styles = useStyles();
  var theme = useTheme();

  return (
    <div
      className={classnames(styles.dotBase, {
        [styles.dotLarge]: size === 'large',
        [styles.dotSmall]: size === 'small',
      })}
      style={{
        backgroundColor:
          color && theme.palette[color] && theme.palette[color].main,
      }}
    />
  );
}
