export const getLookupOptions = (dir) => {
  return {
    residentNationalOptions: [
      {
        dropdownValue: "",
        label: dir === "ltr" ? "Select" : "Select",
      },
      {
        dropdownValue: true,
        label: dir === "ltr" ? "Yes" : "نعم",
      },
      {
        dropdownValue: false,
        label: dir === "ltr" ? "No" : "لا",
      },
    ],
    socialStatusOptions: [
      {
        dropdownValue: "",
        label: dir === "ltr" ? "Select" : "Select",
      },
      {
        dropdownValue: 1,
        label: dir === "ltr" ? "Married" : "متزوج/ ة",
      },
      {
        dropdownValue: 2,
        label: dir === "ltr" ? "Single" : "أعزب/ عزباء",
      },
      {
        dropdownValue: 3,
        label: dir === "ltr" ? "Divorced" : "مطلق/ة",
      },
      {
        dropdownValue: 4,
        label: dir === "ltr" ? "Widowed" : "أرمل/ة",
      },
    ],
    IsLocalResidentOptions: [
      {
        dropdownValue: "",
        label: dir === "ltr" ? "Select" : "Select",
      },
      {
        dropdownValue: true,
        label: dir === "ltr" ? "Yes" : "نعم",
      },
      {
        dropdownValue: false,
        label: dir === "ltr" ? "No" : "لا",
      },
    ],
    IsVisitVisa: [
      {
        dropdownValue: "",
        label: dir === "ltr" ? "Select" : "Select",
      },
      {
        dropdownValue: true,
        label: dir === "ltr" ? "Yes" : "نعم",
      },
      {
        dropdownValue: false,
        label: dir === "ltr" ? "No" : "لا",
      },
    ],
    freshGraduateOptions: [
      {
        dropdownValue: "",
        label: dir === "ltr" ? "Select" : "Select",
      },
      {
        dropdownValue: true,
        label: dir === "ltr" ? "Yes" : "نعم",
      },
      {
        dropdownValue: false,
        label: dir === "ltr" ? "No" : "لا",
      },
    ],
    VisaOptions: [
      {
        dropdownValue: "",
        label: dir === "ltr" ? "Select" : "Select",
      },
      {
        dropdownValue: true,
        label: dir === "ltr" ? "Family Visa" : "اقامة ذويهم",
      },
      {
        dropdownValue: false,
        label: dir === "ltr" ? "Employment Visa" : "تأشيرة عمل",
      },
    ],
    locationOptions: [
      {
        dropdownValue: 1,
        label: dir === "ltr" ? "ADM" : "بلدية مدينة أبو ظبي",
      },
      {
        dropdownValue: 2,
        label: dir === "ltr" ? "AAM" : "بلدينة مدينة العين",
      },
      {
        dropdownValue: 3,
        label: dir === "ltr" ? "DRM" : "بلدية منطقة الظفرة",
      },
    ],
    hiringEntityOptions: [
      {
        dropdownValue: 1,
        label: dir === "ltr" ? "DMT" : "دائرة البلديات والنقل",
      },
      {
        dropdownValue: 2,
        label: dir === "ltr" ? "ADM" : "بلدية مدينة أبو ظبي",
      },
      {
        dropdownValue: 3,
        label: dir === "ltr" ? "AAM" : "بلدينة مدينة العين",
      },
      {
        dropdownValue: 4,
        label: dir === "ltr" ? "DRM" : "بلدية منطقة الظفرة",
      },
      {
        dropdownValue: 5,
        label: dir === "ltr" ? "ITC" : "مركز النقل المتكامل",
      },
    ],
    workNatureOptions: [
      {
        dropdownValue: 1,
        label: dir === "ltr" ? "Supervisory" : "إشرافي",
      },
      {
        dropdownValue: 2,
        label: dir === "ltr" ? "Management" : "إداري",
      },
      {
        dropdownValue: 3,
        label: dir === "ltr" ? "Leadership" : "قيادي",
      },
    ],
    genderOptions: [
      {
        dropdownValue: "",
        label: dir === "ltr" ? "Select" : "Select",
      },
      {
        dropdownValue: 1,
        label: dir === "ltr" ? "Male" : "ذكر",
      },
      {
        dropdownValue: 2,
        label: dir === "ltr" ? "Female" : "أنثى",
      },
    ],
    RelationshipOptions: [
      {
        dropdownValue: "",
        label: dir === "ltr" ? "Select" : "Select",
      },
      {
        dropdownValue: 1,
        label: dir === "ltr" ? "Spouse" : "زوج/ة",
      },
      {
        dropdownValue: 2,
        label: dir === "ltr" ? "Child" : "ابن/ة",
      },
    ],
    bloodGroupOptions: [
      {
        dropdownValue: "",
        label: dir === "ltr" ? "Select" : "Select",
      },
      {
        
        dropdownValue: 1,
        label: dir === "ltr" ? "A+" : "A+",
      },
      {
        
        dropdownValue: 2,
        label: dir === "ltr" ? "A-" : "A-",
      },
      {
        
        dropdownValue: 3,
        label: dir === "ltr" ? "B+" : "B+",
      },
      {
        
        dropdownValue: 4,
        label: dir === "ltr" ? "B-" : "B-",
      },
      {
        
        dropdownValue: 5,
        label: dir === "ltr" ? "O+" : "O+",
      },
      {
        
        dropdownValue: 6,
        label: dir === "ltr" ? "O-" : "O-",
      },
      {
        
        dropdownValue: 7,
        label: dir === "ltr" ? "AB+" : "AB+",
      },
      {
        
        dropdownValue: 8,
        label: dir === "ltr" ? "AB-" : "AB-",
      },
    ],
    peopleofDeterminationOptions: [
      {
        dropdownValue: "",
        label: dir === "ltr" ? "Select" : "Select",
      },
      {
        dropdownValue: true,
        label: dir === "ltr" ? "Yes" : "نعم",
      },
      {
        dropdownValue: false,
        label: dir === "ltr" ? "No" : "لا",
      },
    ],
    expiryOptions: [
      {
        dropdownValue: "",
        label: dir === "ltr" ? "Select" : "Select",
      },
      {
        dropdownValue: true,
        label: dir === "ltr" ? "Yes" : "نعم",
      },
      {
        dropdownValue: false,
        label: dir === "ltr" ? "No" : "لا",
      },
    ],
    CountryOptions: [
      {
        dropdownValue: "",
        label: dir === "ltr" ? "Select" : "Select",
      },
      {
        dropdownValue: "UAE",
        label: dir === "ltr" ? "UAE" : "UAE",
      },
      {
        dropdownValue: "Others",
        label: dir === "ltr" ? "Others" : "Others",
      },
    ],
    TypeofCompany: [
      {
        dropdownValue: "",
        label: dir === "ltr" ? "Select" : "Select",
      },
     {
        dropdownValue: 1,
        label: dir === "ltr" ? "Government" : "جهة حكومية",
      },
	 {
        dropdownValue: 2,
        label: dir === "ltr" ? "Private" : "خاصة",
      },
    ],
    emirateOptions: [
      {
        dropdownValue: "",
        label: dir === "ltr" ? "Select" : "Select",
      },
      {
        dropdownValue: 1,
        label: dir === "ltr" ? "Abu Dhabi" : "أبو ظبي",
      },
      {
        dropdownValue: 2,
        label: dir === "ltr" ? "Dubai" : "دبي",
      },
      {
        dropdownValue: 3,
        label: dir === "ltr" ? "Sharjah" : "الشارقة",
      },
      {
        dropdownValue: 4,
        label: dir === "ltr" ? "Ajman" : "عجمان",
      },
      {
        dropdownValue: 5,
        label: dir === "ltr" ? "Fujairah" : "الفجيرة",
      },
      {
        dropdownValue: 6,
        label: dir === "ltr" ? "Ra's al-Khaimah" : "رأس الخيمة",
      },
      {
        dropdownValue: 7,
        label: dir === "ltr" ? "Umm al-Quwain" : "أم القيوين",
      },
    ],
    preferredCityToWorkOptions: [
      {
        dropdownValue: "",
        label: dir === "ltr" ? "Select" : "Select",
      },
      {
        dropdownValue: 1,
        label: dir === "ltr" ? "Abu Dhabi City" : "مدينة أبو ظبي",
      },
      {
        dropdownValue: 2,
        label: dir === "ltr" ? "Al Ain City" : "مدينة العين",
      },
      {
        dropdownValue: 3,
        label: dir === "ltr" ? "Al Dhafra Region" : "منطقة الظفرة",
      },
    ],
    titleOptions: [
      {
        dropdownValue: "",
        label: dir === "ltr" ? "Select" : "اختر",
      },
      {
        dropdownValue: 1,
        label: dir === "ltr" ? "Mr." : "السيد",
      },
      {
        dropdownValue: 2,
        label: dir === "ltr" ? "Ms." : "آنسة",
      },
      {
        dropdownValue: 3,
        label: dir === "ltr" ? "Mrs." : "السّيدة",
      },
      {
        dropdownValue: 4,
        label: dir === "ltr" ? "Dr." : "دكتور",
      },
      {
        dropdownValue: 5,
        label: dir === "ltr" ? "Engr." : "المهندس",
      },
    ],
    MaritalStatusOptions: [
      {
        dropdownValue: "",
        label: dir === "ltr" ? "Select" : "Select",
      },
      {
        dropdownValue: 1,
        label: dir === "ltr" ? "Single" : "أعزب/عزباء",
      },
      {
        dropdownValue: 2,
        label: dir === "ltr" ? "Married" : "متزوج/ة",
      },
      
      {
        dropdownValue: 3,
        label: dir === "ltr" ? "Divorced" : "منفصل/ة",
      },
      // {
      //   dropdownValue: 4,
      //   label: dir === "ltr" ? "Widowed" : "أرمل/ة",
      // },
    ],
    RegionofBrithOptions: [
      {
        dropdownValue: "",
        label: dir === "ltr" ? "Select" : "Select",
      },
      {
        dropdownValue: 1,
        label: dir === "ltr" ? "Abu Dhabi City" : "مدينة أبو ظبي",
      },
      {
        dropdownValue: 2,
        label: dir === "ltr" ? "Al Ain City" : "مدينة العين",
      },
      {
        dropdownValue: 3,
        label: dir === "ltr" ? "Al Dhafra Region" : "منطقة الظفرة",
      },
    ],
    nationalServiceOptions: [
      {
        dropdownValue: "",
        label: dir === "ltr" ? "Select" : "Select",
      },
      {
        dropdownValue: 0,
        label: dir === "ltr" ? "No" : "لا",
      },
      {
        dropdownValue: 1,
        label: dir === "ltr" ? "Yes" : "نعم",
      },
      {
        dropdownValue: 2,
        label: dir === "ltr" ? "Postponed" : "مؤجلة",
      },
    ],
    hasDisabilityOptions: [
      {
        dropdownValue: "",
        label: dir === "ltr" ? "Select" : "Select",
      },
      {
        dropdownValue: true,
        label: dir === "ltr" ? "Yes" : "نعم",
      },
      {
        dropdownValue: false,
        label: dir === "ltr" ? "No" : "لا",
      },
    ],
    hasUaeNationalityOptions: [
      {
        dropdownValue: "",
        label: dir === "ltr" ? "Select" : "Select",
      },
      {
        dropdownValue: true,
        label: dir === "ltr" ? "Yes" : "نعم",
      },
      {
        dropdownValue: false,
        label: dir === "ltr" ? "No" : "لا",
      },
    ],
    visaCopyInProgressOptions: [
      {
        dropdownValue: "",
        label: dir === "ltr" ? "Select" : "Select",
      },
      {
        dropdownValue: true,
        label: dir === "ltr" ? "Yes" : "نعم",
      },
      {
        dropdownValue: false,
        label: dir === "ltr" ? "No" : "لا",
      },
    ],
    emiratesIdInProgressOptions: [
      {
        dropdownValue: "",
        label: dir === "ltr" ? "Select" : "Select",
      },
      {
        dropdownValue: true,
        label: dir === "ltr" ? "Yes" : "نعم",
      },
      {
        dropdownValue: false,
        label: dir === "ltr" ? "No" : "لا",
      },
    ],
    status: [
      {
        dropdownValue: "",
        label: dir === "ltr" ? "Select" : "Select",
      },
      {
        dropdownValue: 1,
        label: dir === "ltr" ? "Currently working" : "العمل الحالي",
      },
      {
        dropdownValue: 2,
        label: dir === "ltr" ? "Previously working" : "العمل السابق",
      },
      {
        dropdownValue: 3,
        label: dir === "ltr" ? "Not working" : "لا اعمل",
      },
      {
        dropdownValue: 4,
        label: dir === "ltr" ? "Other" : "أخرى",
      },
    ],
    StatusOnbaording: [
      {
        dropdownValue: "",
        label: dir === "ltr" ? "Select" : "Select",
      },
      {
        dropdownValue: 1,
        label: dir === "ltr" ? "In Progress" : "قيد التطبيق",
      },
      {
        dropdownValue: 2,
        label: dir === "ltr" ? "Completed" : "منجز",
      },
    ],
    Nationality: [
      {
        dropdownValue: "",
        label: dir === "ltr" ? "Select" : "Select",
      },
      {
        dropdownValue: 1,
        label: dir === "ltr" ? "Emirati" : "",
      },
      {
        dropdownValue: 2,
        label: dir === "ltr" ? "Egyptian" : "",
      },
      {
        dropdownValue: 3,
        label: dir === "ltr" ? "Jordanian" : "",
      },
      {
        dropdownValue: 4,
        label: dir === "ltr" ? "Kuwaiti" : "",
      },
      {
        dropdownValue: 5,
        label: dir === "ltr" ? "Lebanese" : "",
      },
      {
        dropdownValue: 6,
        label: dir === "ltr" ? "Omani" : "",
      },
      {
        dropdownValue: 7,
        label: dir === "ltr" ? "Qatari" : "",
      },
      {
        dropdownValue: 8,
        label: dir === "ltr" ? "Saudi" : "",
      },
      {
        dropdownValue: 9,
        label: dir === "ltr" ? "Syrian" : "",
      },
    ],
    visaTypeOptions: [
      {
        dropdownValue: "",
        label: dir === "ltr" ? "Select Visa Type" : "اختر نوع التأشيرة",
      },
      {
        dropdownValue: 1,
        label: dir === "ltr" ? "Employment Visa" : "تأشيرة عمل",
      },
      {
        dropdownValue: 2,
        label: dir === "ltr" ? "Golden Visa" : "اقامة ذهبية",
      },
      {
        dropdownValue: 3,
        label: dir === "ltr" ? "Family Visa" : "اقامة ذويهم",
      },
      {
        dropdownValue: 4,
        label: dir === "ltr" ? "Freelancer Visa" : "تأشيرة عمل حر",
      },
      {
        dropdownValue: 5,
        label: dir === "ltr" ? "Student Visa" : "تأشيرة طالب",
      },
    ],
    ReligionOptions: [
      {
        dropdownValue: "",
        label: dir === "ltr" ? "Select" : "اختيار",
      },
      {
        dropdownValue: 1,
        label: dir === "ltr" ? "Islam" : "الإسلام",
      },
      {
        dropdownValue: 2,
        label: dir === "ltr" ? "Christianity" : "النصرانية",
      },
      {
        dropdownValue: 3,
        label: dir === "ltr" ? "Hinduism" : "الهندوسية",
      },
      {
        dropdownValue: 4,
        label: dir === "ltr" ? "Buddhism" : "البوذية",
      },
      {
        dropdownValue: 5,
        label: dir === "ltr" ? "Judaism" : "اليهودية",
      },
      {
        dropdownValue: 6,
        label: dir === "ltr" ? "Sikhism" : "السيخية",
      },
     /* {
        dropdownValue: 7,
        label: dir === "ltr" ? "Other" : "أخرى",
      },*/
    ],
    educationLevelOptions: [
      {
        dropdownValue: "",
        label: dir === "ltr" ? "Select" : "Select",
      },
      {
        dropdownValue: 1,
        label: dir === "ltr" ? "High School" : "ثانوي",
      },
      {
        dropdownValue: 2,
        label: dir === "ltr" ? "Bachelor" : "بكالوريوس",
      },
      {
        dropdownValue: 3,
        label: dir === "ltr" ? "Diploma" : "دبلومة",
      },
      {
        dropdownValue: 4,
        label: dir === "ltr" ? "Master" : "ماجستير",
      },
      {
        dropdownValue: 5,
        label: dir === "ltr" ? "PHD" : "دكتوراة",
      },
      {
        dropdownValue: 6,
        label: dir === "ltr" ? "Other" : "أخرى",
      },
    ],
    educationLevelOptionsOnboarding: [
      {
        dropdownValue: "",
        label: dir === "ltr" ? "Select" : "Select",
      },
      {
        dropdownValue: "PHD",
        label: dir === "ltr" ? "PHD Degree" : "درجة الدكتوراه وما يعادله",
      },
      {
        dropdownValue: "Masters",
        label: dir === "ltr" ? "Master Degree" : "درجة الماجستير او ما يعادله",
      },
      {
        dropdownValue: "Bachelors",
        label:
          dir === "ltr"
            ? "Bachelor or Diploma or High School"
            : "درجة البكالوريوس  او دبلومة او معهد عالي",
      },
    ],
    degreeOptions: [
      {
        dropdownValue: "",
        label: dir === "ltr" ? "Select" : "Select",
      },
      {
        dropdownValue: 1,
        label:
          dir === "ltr"
            ? "PHD degree or equivalent "
            : "درجة الدكتوراه وما يعادله",
      },
      {
        dropdownValue: 2,
        label:
          dir === "ltr"
            ? "Master degree or equivalent"
            : "درجة الماجستير او ما يعادله",
      },
      {
        dropdownValue: 3,
        label:
          dir === "ltr"
            ? "Bachelor degree or equivalent"
            : "درجة البكالوريوس او ما يعادله",
      },
      {
        dropdownValue: 4,
        label: dir === "ltr" ? "Diploma or equivalent" : "دبلومة او ما يعادلها",
      },
      {
        dropdownValue: 5,
        label:
          dir === "ltr"
            ? "High school or equivalent"
            : "الثانوية العامة او ما يعادلها",
      },
      {
        dropdownValue: 6,
        label:
          dir === "ltr" ? "Less than high school" : "اقل من الثانوية العامة",
      },
      {
        dropdownValue: 7,
        label: dir === "ltr" ? "Higher Diploma" : "دبلوم عالي",
      },
    ],
    Numberofchildren: [
      {
        dropdownValue: "",
        label: dir === "ltr" ? "Select" : "Select",
      },
      {
        dropdownValue: 1,
        label: dir === "ltr" ? "1" : "١",
      },
      {
        dropdownValue: 2,
        label: dir === "ltr" ? "2" : "٢",
      },
      {
        dropdownValue: 3,
        label: dir === "ltr" ? "3" : "٣",
      },
      {
        dropdownValue: 4,
        label: dir === "ltr" ? "4" : "٤",
      },
      {
        dropdownValue: 5,
        label: dir === "ltr" ? "5" : "٥",
      },
      {
        dropdownValue: 6,
        label: dir === "ltr" ? "6" : "٦",
      },
      {
        dropdownValue: 7,
        label: dir === "ltr" ? "7" : "٧",
      },
    ],
  };
};
