import { makeStyles } from "@material-ui/core";

export default makeStyles(() => ({
  container: {
    padding: "10px 0",
    "@media (max-width: 770px)": {
      padding: "0",
    },
  },
  inputField: {
    width: "31.5%",
    minWidth: "110px !important",
    height: "35px !important",
    margin: "10px 0.9%",
    "@media (max-width: 770px)": {
      width: "100%",
    },
    "& input": {
      fontSize: "0.72em",
    },
  },
  dropdown: {
    width: "31.5%",
    minWidth: "110px !important",
    margin: "10px 0.9%",
    "& label": {
      top: "-12px !important",
      fontSize: "0.72em",
    },
    "@media (max-width: 770px)": {
      width: "100%",
    },
  },
  dropdownInput: {
    height: "21px !important",
    padding: "6px 15px !important",
    fontSize: "0.72em"
  },
  Educationdropdown: {
    width: "32%",
    // minWidth: "110px !important",
    margin: "10px 0.9%",
    "& label": {
      top: "-12px !important",
      fontSize: "0.72em",
    },
    "@media (max-width: 770px)": {
      width: "100%",
    },
  },
  textButton: {
    color: "#005F74",
    fontSize: "0.7rem",
    lineHeight: "1.5",
    "&:hover": {
      color: "black",
    },
  },
  textButtonRemove: {
    fontSize: "0.7rem",
    lineHeight: "1.5",
    float: "right",
  },
  icon: {
    color: "#005F74",
  },
  divider: {
    margin: "5px 0",
  },
  error: {
    border: "1px solid #f44336 !important",
    backgroundColor: " rgb(255 190 190 / 50%)"
  },
}));
