import { makeStyles } from "@material-ui/core";

export default makeStyles(() => ({
  button: {
    width: "100px",
    height: "30px",
  },
  margin: {
    marginRight: "20px !important",
  },
  disabled: {
    opacity: 0.7
  }
}));
