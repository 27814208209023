import { Divider, Grid, Snackbar } from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";
// import { Snackbar, Alert } from "@mui/material";
import { Alert } from "@material-ui/lab";
import { useDispatch, useSelector } from "react-redux";
import CustomDatePicker from "../../../components/CustomDatePicker/CustomDatePicker";
import Dropdown from "../../../components/DropDown/DropDown";
import SearchableDropdownOB from "../../../components/SearchableDropdownOB/SearchableDropdownOB";
import InputField from "../../../components/InputField/InputField";
import { getLookupOptions } from "../../../lookupData.options";
import {
  setActiveStep,
  updateFormData,
  submitFormData,
  fetchLookupData,
  fetchApplicantData,
} from "../../../store/reducers/onboardingReducer";
import ActionButtons from "../ActionButtons/ActionButtons";
import useStyle from "./PersonalInformationstyles";
import { useFormik, Field } from "formik";
import {
  getPersonalInfoValidation,
  initialValues as defaultInitialValues,
} from "./validation";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import FileUploader from "../../../components/FileUploader/FileUploader";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import ClipLoader from "react-spinners/ClipLoader"; // Optional, if using react-spinners

const PersonalInformation = ({ onNationalityChange, nationality }) => {
  const styles = useStyle();
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false); // Loader state
  const {
    activeStep,
    personalInfo,
    status,
    lookupData,
    selectedFile,
    // parentId,
    personalInformation,
  } = useSelector((state) => state.onboarding);

  const { dir } = useSelector((state) => state.generalStates);
  const personalDetailsOptions = useMemo(() => getLookupOptions(dir), [dir]);

  // File state
  const [personalPhotoFile, setPersonalPhotoFile] = useState([]);
  const [passportFile, setPassportFile] = useState([]);
  const [familyBookFile, setFamilyBookFile] = useState([]);
  const [visaCopyFile, setVisaCopyFile] = useState([]);
  const [emiratesIdCopyFile, setEmiratesIdCopyFile] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [nationalityOptions, setNationalityOptions] = useState([]);
  const [regionOptions, setRegionOptions] = useState([]);
  const [emiratesOptions, setEmiratesOptions] = useState([]);
  const [districtsOptions, setDistrictsOptions] = useState([]);

  const [nationalityLookup, setNationalityLookup] = useState({});
  const [countryLookup, setCountryLookup] = useState({});
  const [regionLookup, setRegionLookup] = useState({});
  const [emiratesLookup, setEmiratesLookup] = useState([]);
  const [districtsLookup, setDistrictsLookup] = useState([]);
  const [attachments, setAttachments] = useState([]);

  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [snackBarSeverity, setSnackBarSeverity] = useState("success");
  // State to track whether the file uploader is active
  // const [isFileUploaderActive, setIsFileUploaderActive] = useState(false);

  // console.log("personal Information compoenent:", personalInformation);

  // Function to map the response to a File object
  function mapToFileObject(response) {
    const { FileName, ContentType, InputStream } = response;

    const byteCharacters = atob(InputStream._buffer);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const file = new File([byteArray], FileName, { type: ContentType });
    return file;
  }

  // Fetch lookup data on component mount
  useEffect(() => {
    if (status === "idle") {
      dispatch(
        fetchLookupData([
          "GetNationality",
          "Country",
          "Region",
          "AcademiceSpeciality",
          "Emirates",
          "Districts",
        ])
      );
    }
  }, [dispatch, status]);

  const queryParams = new URLSearchParams(window.location.search);
  const registrationId = queryParams.get("crmreferenceId");
  const requestID = queryParams.get("requestId");

  useEffect(async () => {
    if (registrationId != null && registrationId != "") {
      setIsLoading(true);
      await dispatch(fetchApplicantData({ registrationId, stepNo: 1 }));
      setIsLoading(false);
    }
  }, [dispatch, registrationId]);

  useEffect(() => {
    if (status === "succeeded") {
      const nationalityMap = {};

      // Check if `lookupData` and `lookupData.GetNationality` are available
      if (lookupData?.GetNationality?.length) {
        const mappedOptions = lookupData.GetNationality.map((item) => {
          nationalityMap[item.Key] = {
            labelEN: item.Value,
            labelAR: item.ValueAR,
          };
          return {
            labelEN: item.Value,
            labelAR: item.ValueAR,
            value: item.Key, // Store the GUID here
          };
        });

        setNationalityOptions(mappedOptions);
        setNationalityLookup(nationalityMap); // Store the lookup map
      }
      //  else {
      //   // Handle the case when lookupData or GetNationality is missing or empty
      //   // console.warn("GetNationality data is missing or empty");
      // }

      // Check for `Country` data
      if (lookupData?.Country?.length) {
        const countryMap = {};
        const mappedOptions = lookupData.Country.map((item) => {
          countryMap[item.Key] = {
            labelEN: item.Value,
            labelAR: item.ValueAR,
          };
          return {
            labelEN: item.Value,
            labelAR: item.ValueAR,
            value: item.Key,
          };
        });
        setCountryOptions(mappedOptions);
        setCountryLookup(countryMap); // Store the lookup map
      }
      // Check for `Emirates` data
      if (lookupData?.Emirates?.length) {
        const EmiratesMap = {};
        const mappedOptions = lookupData.Emirates.map((item) => {
          EmiratesMap[item.Key] = {
            labelEN: item.Value,
            labelAR: item.ValueAR,
            // regionID: item.RegionId, // Assuming this exists in the district data
          };
          return {
            labelEN: item.Value,
            labelAR: item.ValueAR,
            value: item.Key,
          };
        });
        setEmiratesOptions(mappedOptions);
        setEmiratesLookup(EmiratesMap); // Store the lookup map
      }
      // Check for `Region` data
      if (lookupData?.Region?.length) {
        const regionMap = {};
        const mappedOptions = lookupData.Region.map((item) => {
          regionMap[item.Key] = {
            labelEN: item.Value,
            labelAR: item.ValueAR,
            dmtHubId: item.DmtHubId, // Store the DmtHubId for filtering districts
          };
          return {
            labelEN: item.Value,
            labelAR: item.ValueAR,
            value: item.Key,
          };
        });
        setRegionOptions(mappedOptions);
        setRegionLookup(regionMap); // Store the lookup map
      }
      // Check for `Region` data
      if (lookupData?.Districts?.length) {
        const DistrictsMap = {};
        const mappedOptions = lookupData.Districts.map((item) => {
          DistrictsMap[item.Key] = {
            labelEN: item.Value,
            labelAR: item.ValueAR,
            regionID: item.RegionId, // Assuming this exists in the district data
          };
          return {
            labelEN: item.Value,
            labelAR: item.ValueAR,
            value: item.Key,
          };
        });
        setDistrictsOptions(mappedOptions);
        setDistrictsLookup(DistrictsMap); // Store the lookup map
      }
    }
  }, [lookupData, status]);

  // Set initial values for the form
  const initialValues = {
    ...defaultInitialValues,
    // ...personalInformation,
    // ...personalInfo?.PersonalInformation, // Merge with the applicant's info if available
  };

  const formik = useFormik({
    initialValues: initialValues, // Use the dynamically set initial values
    validationSchema: getPersonalInfoValidation(t),
    onSubmit: async () => {
      await onClickSubmit();
    },
    // enableReinitialize: true,
    // validateOnChange: true, // Corrected syntax
    // validateOnBlur: false,  // Corrected syntax
  });
  // useEffect(() => {
  //   formik.validateForm(); // Explicitly trigger validation when component mounts
  // }, []);

  // useEffect(() => {
  //   if (personalInformation) {
  //     const { attachtments, attachments, ...personalInfoWithoutAttachments } = personalInformation;
  //     const validAttachments = attachments || attachtments; // Handle wrong spelling

  //     // Set formik values without attachments (text fields)
  //     formik.setValues({
  //       ...formik.values,
  //       ...personalInfoWithoutAttachments, // Update formik with personal info excluding attachments
  //     });

  //     if (validAttachments) {
  //       const newAttachments = [];
  //       validAttachments.forEach((attachment, index) => {
  //         const byteString = attachment.InputStream._buffer; // Base64 string
  //         const contentType = attachment.ContentType;
  //         const fileName = attachment.FileName;

  //         // Create base64 image source
  //         const base64Image = `data:${contentType};base64,${byteString}`;

  //         // Create file object for sending via FormData
  //         const fileFromBackend = {
  //           name: fileName,
  //           type: contentType,
  //           size: attachment.ContentLength, // Ensure the file size is valid
  //           image: base64Image,
  //         };

  //         newAttachments.push(fileFromBackend);

  //         if (fileFromBackend.name.includes('Photo_')) {
  //           setPersonalPhotoFile(fileFromBackend);
  //         }
  //         else if (fileFromBackend.name.includes('Passport_')) {
  //           setPassportFile(fileFromBackend);
  //         }
  //         else if (fileFromBackend.name.includes('Visa_')) {
  //           setVisaCopyFile(fileFromBackend);
  //         }
  //         else if (fileFromBackend.name.includes('FamilyBook_')) {
  //           setFamilyBookFile(fileFromBackend);
  //         }
  //         else if (fileFromBackend.name.includes('EmiratesID_')) {
  //           setEmiratesIdCopyFile(fileFromBackend);
  //         }
  //       });

  //       // Optionally save all attachments to a state for later use
  //       setAttachments(newAttachments);
  //     }
  //   }
  // }, [personalInformation]);

  // useEffect(() => {
  //   if (personalInformation) {
  //     const { attachtments, attachments, ...personalInfoWithoutAttachments } = personalInformation;
  //     const validAttachments = attachments || attachtments; // Handle wrong spelling
  //     // Set formik values without attachments (text fields)
  //     formik.setValues({...formik.values,
  //       ...personalInfoWithoutAttachments, // Update formik with personal info excluding attachments
  //       });
  //       if (validAttachments) { const newAttachments = [];
  //         validAttachments.forEach((attachment, index) =>
  //           { const byteString = attachment.InputStream._buffer; // Base64 string
  //              const contentType = attachment.ContentType;
  //              const fileName = attachment.FileName;
  //              // Create base64 image source
  //              const base64Image = `data:${contentType};base64,${byteString}`;
  //              // Create file object for sending via FormData
  //              const fileFromBackend =
  //              { name: fileName,
  //               type: contentType,
  //               size: attachment.ContentLength, // Ensure the file size is valid
  //               image: base64Image,
  //              };
  //              newAttachments.push(fileFromBackend);
  //              if(fileFromBackend.name.includes('Photo_'))
  //                {
  //                 setPersonalPhotoFile(fileFromBackend);
  //                 formik.setFieldValue("PersonalPhoto", fileFromBackend);
  //                 formik.setFieldError("PersonalPhoto", null); // Clear any existing error
  //                  }
  //                  else if(fileFromBackend.name.includes('Passport_'))
  //                 {
  //                    setPassportFile(fileFromBackend);
  //                    formik.setFieldValue("Passportcopy", fileFromBackend);
  //                    formik.setFieldError("Passportcopy", null); // Clear any existing error
  //                    }
  //                    else if(fileFromBackend.name.includes('Visa_'))
  //                     {
  //                       setVisaCopyFile(fileFromBackend);
  //                       formik.setFieldValue("Visacopy", fileFromBackend);
  //                       formik.setFieldError("Visacopy", null); // Clear any existing error
  //                        }  else if(fileFromBackend.name.includes('FamilyBook_'))
  //                          {
  //                           setFamilyBookFile(fileFromBackend);
  //                            formik.setFieldValue("FamilyBook", fileFromBackend);
  //                            formik.setFieldError("FamilyBook", null); // Clear any existing error
  //                            } else if(fileFromBackend.name.includes('EmiratesID_'))
  //                             {
  //                               setEmiratesIdCopyFile(fileFromBackend);
  //                               formik.setFieldValue("Emiratesidcopy", fileFromBackend);
  //                               formik.setFieldError("Emiratesidcopy", null); // Clear any existing error
  //                                 } });       // Optionally save all attachments to a state for later use
  //                                 setAttachments(newAttachments);}}
  //                               }, [personalInformation]);

  useEffect(() => {
    if (personalInformation) {
      const { attachtments, attachments, ...personalInfoWithoutAttachments } =
        personalInformation;

      const validAttachments = attachments || attachtments; // Handle wrong spelling

      // Set formik values without attachments (text fields)

      formik.setValues({
        ...formik.values,

        ...personalInfoWithoutAttachments, // Update formik with personal info excluding attachments
      });

      if (validAttachments) {
        const newAttachments = [];

        validAttachments.forEach((attachment, index) => {
          const fileObject = mapToFileObject(attachment);

          const byteString = attachment.InputStream._buffer; // Base64 string

          const contentType = attachment.ContentType;

          const fileName = attachment.FileName;

          // Create base64 image source

          const base64Image = `data:${contentType};base64,${byteString}`;

          // Create file object for sending via FormData

          let fileFromBackend = {
            name: fileName,
            type: contentType,
            size: attachment.ContentLength, // Ensure the file size is valid
            image: base64Image,
          };

          newAttachments.push(fileFromBackend);

          if (fileFromBackend.name.includes("Photo_")) {
            setPersonalPhotoFile(fileFromBackend);
            // console.log("Setting Formik Values: ", fileFromBackend);
            // formik.setFieldValue("PersonalPhoto", fileFromBackend);
            formik.setFieldValue("PersonalPhoto", fileObject);
            formik.setFieldError("PersonalPhoto", null); // Clear any existing error
          } else if (fileFromBackend.name.includes("Passport_")) {
            setPassportFile(fileFromBackend);

            // formik.setFieldValue("Passportcopy", fileFromBackend);

            formik.setFieldValue("Passportcopy", fileObject);
            formik.setFieldError("Passportcopy", null); // Clear any existing error
          } else if (fileFromBackend.name.includes("Visa_")) {
            setVisaCopyFile(fileFromBackend);

            // formik.setFieldValue("Visacopy", fileFromBackend);
            formik.setFieldValue("Visacopy", fileObject);
            formik.setFieldError("Visacopy", null); // Clear any existing error
          } else if (fileFromBackend.name.includes("FamilyBook_")) {
            setFamilyBookFile(fileFromBackend);

            // formik.setFieldValue("FamilyBook", fileFromBackend);
            formik.setFieldValue("FamilyBook", fileObject);

            formik.setFieldError("FamilyBook", null); // Clear any existing error
          } else if (fileFromBackend.name.includes("EmiratesID_")) {
            setEmiratesIdCopyFile(fileFromBackend);

            // formik.setFieldValue("Emiratesidcopy", fileFromBackend);
            formik.setFieldValue("Emiratesidcopy", fileObject);
            formik.setFieldError("Emiratesidcopy", null); // Clear any existing error
          }
        });

        // Optionally save all attachments to a state for later use

        setAttachments(newAttachments);
      }
    }
  }, [personalInformation]);

  // const onClickSubmit = async () => {
  //   setIsLoading(true);
  //   // Log all formik values before validation
  //   console.log("Formik Values before validation:", formik.values);
  //   // Create a new object for personalInfo, excluding image fields
  //   const {
  //     PersonalPhoto,
  //     Passportcopy,
  //     FamilyBook,
  //     Visacopy,
  //     Emiratesidcopy,
  //     ...personalInfoWithoutImages
  //   } = formik.values;
  //   personalInfoWithoutImages.RequestID = requestID;
  //   // Dispatch the current form data to Redux, including the step number
  //   dispatch(
  //     updateFormData({ step: activeStep, data: personalInfoWithoutImages })
  //   );

  //   const dataToSubmit = {
  //     personalInfo: personalInfoWithoutImages, // Ensure personalInfo is defined and has the expected structure
  //     selectedFile: [], // Ensure selectedFile is defined if applicable
  //     activeStep: activeStep, // Pass the active step here
  //     // parentId: parentId, // Add parentId here
  //   };
  //   // console.log(dataToSubmit.selectedFile.push("personalPhotoFile"));

  // // Collect valid files for submission

  // const imageFiles = [personalPhotoFile, passportFile, familyBookFile, visaCopyFile, emiratesIdCopyFile];

  // // Helper function to convert base64 to File
  // const createFileFromBase64 = async (base64, fileName, contentType) => {
  //   const blob = await fetch(base64).then(res => res.blob());
  //   return new File([blob], fileName, { type: contentType });
  // };

  // // Process each image file and add to selectedFile
  // for (let index = 0; index < imageFiles.length; index++) {
  //   const file = imageFiles[index];
  //   if (file && file.size > 0) {
  //     // If the file contains base64 data, convert it to a File object
  //     if (file.image) {
  //       const { image: base64Image, name, type } = file;
  //       let fileName = "";
  //       if(index == 0)
  //       {
  //         fileName = personalPhotoFile.name;
  //         if(!fileName.startsWith("Photo_"))
  //         {
  //           fileName = "Photo_" + fileName;
  //         }
  //       }
  //       else if(index == 1)
  //       {
  //         fileName = passportFile.name;
  //         if(!fileName.startsWith("Passport_"))
  //         {
  //           fileName = "Passport_" + fileName;
  //         }
  //       }
  //       else if(index == 2)
  //       {
  //         fileName = familyBookFile.name;
  //         if(!fileName.startsWith("FamilyBook_"))
  //         {
  //           fileName = "FamilyBook_" + fileName;
  //         }
  //       }
  //       else if(index == 3)
  //       {
  //         fileName = visaCopyFile.name;
  //         if(!fileName.startsWith("Visa_"))
  //         {
  //           fileName = "Visa_" + fileName;
  //         }
  //       }
  //       else if(index == 4)
  //       {
  //         fileName = emiratesIdCopyFile.name;
  //         if(!fileName.startsWith("EmiratesID_"))
  //         {
  //           fileName = "EmiratesID_" + fileName;
  //         }
  //       }
  //       const convertedFile = await createFileFromBase64(base64Image, fileName, type);
  //       dataToSubmit.selectedFile.push(convertedFile); // Add valid files to the submission
  //     } else {
  //       dataToSubmit.selectedFile.push(file); // Add non-base64 files (if any)
  //     }
  //     console.log(`Attachment ${index + 1}:`, file);
  //   } else {
  //     console.log(`Attachment ${index + 1} is empty or invalid`);
  //   }
  // }

  // console.log("Data to submit:", dataToSubmit);

  //   dispatch(submitFormData(dataToSubmit)).then((result) => {
  //     setIsLoading(true);
  //     if (result.meta.requestStatus === "fulfilled") {
  //       dispatch(setActiveStep(activeStep + 1));
  //       setIsLoading(false);
  //     } else {
  //       console.error(
  //         "Error submitting data:",
  //         result.payload || result.error.message
  //       );
  //       setIsLoading(false);
  //     }
  //   });
  // };

  const onClickSubmit = async () => {
    setIsLoading(true);

    setSnackBarOpen(false); // Close snackbar if it's open
    // Log all formik values before validation
    // console.log("Formik Values before validation:", formik.values);
    // Create a new object for personalInfo, excluding image fields
    const {
      PersonalPhoto,
      Passportcopy,
      FamilyBook,
      Visacopy,
      Emiratesidcopy,
      ...personalInfoWithoutImages
    } = formik.values;
    personalInfoWithoutImages.RequestID = requestID;
    // Dispatch the current form data to Redux, including the step number
    dispatch(
      updateFormData({ step: activeStep, data: personalInfoWithoutImages })
    );

    const dataToSubmit = {
      personalInfo: personalInfoWithoutImages, // Ensure personalInfo is defined and has the expected structure
      selectedFile: [], // Ensure selectedFile is defined if applicable
      activeStep: activeStep, // Pass the active step here
      // parentId: parentId, // Add parentId here
    };
    // console.log(dataToSubmit.selectedFile.push("personalPhotoFile"));

    // Collect valid files for submission

    const imageFiles = [
      personalPhotoFile,
      passportFile,
      familyBookFile,
      visaCopyFile,
      emiratesIdCopyFile,
    ];

    // Helper function to convert base64 to File
    const createFileFromBase64 = async (base64, fileName, contentType) => {
      const blob = await fetch(base64).then((res) => res.blob());
      return new File([blob], fileName, { type: contentType });
    };

    // Process each image file and add to selectedFile
    for (let index = 0; index < imageFiles.length; index++) {
      const file = imageFiles[index];
      if (file && file.size > 0) {
        // If the file contains base64 data, convert it to a File object
        if (file.image) {
          const { image: base64Image, name, type } = file;
          let fileName = "";
          if (index == 0) {
            fileName = personalPhotoFile.name;
            if (!fileName.startsWith("Photo_")) {
              fileName = "Photo_" + fileName;
            }
          } else if (index == 1) {
            fileName = passportFile.name;
            if (!fileName.startsWith("Passport_")) {
              fileName = "Passport_" + fileName;
            }
          } else if (index == 2) {
            fileName = familyBookFile.name;
            if (!fileName.startsWith("FamilyBook_")) {
              fileName = "FamilyBook_" + fileName;
            }
          } else if (index == 3) {
            fileName = visaCopyFile.name;
            if (!fileName.startsWith("Visa_")) {
              fileName = "Visa_" + fileName;
            }
          } else if (index == 4) {
            fileName = emiratesIdCopyFile.name;
            if (!fileName.startsWith("EmiratesID_")) {
              fileName = "EmiratesID_" + fileName;
            }
          }
          const convertedFile = await createFileFromBase64(
            base64Image,
            fileName,
            type
          );
          dataToSubmit.selectedFile.push(convertedFile); // Add valid files to the submission
        } else {
          dataToSubmit.selectedFile.push(file); // Add non-base64 files (if any)
        }
        // console.log(`Attachment ${index + 1}:`, file);
      }
    }
    try {
      const result = await dispatch(submitFormData(dataToSubmit));
  
      // Check if submission was successful
      if (result.meta.requestStatus === "fulfilled") {
        setSnackBarMessage("Form submitted successfully");
        setSnackBarSeverity("success");
        dispatch(setActiveStep(activeStep + 1));
      } else {
        console.error("Error submitting data:", result.payload || result.error.message);
        setSnackBarMessage(
          result.payload?.message || result.error.message || t("Something went wrong. We are having trouble completing your request, please try again")
        );
        setSnackBarSeverity("error");
      }
    } catch (error) {
      console.error("Error submitting data:", error.message);
      setSnackBarMessage(
        error.message || t("Something went wrong. We are having trouble completing your request, please try again")
      );
      setSnackBarSeverity("error");
    } finally {
      setIsLoading(false); // Stop loading state
      setSnackBarOpen(true); // Open Snackbar with the message
    }
    
  };

  const handlePhoneChange = (value, country) => {
    formik.setFieldValue("MobileNumber", value);
    formik.setFieldValue("CountryCode", country.countryCode);
  };

  const { values, touched, errors, setFieldValue } = formik;

  // Determine the selected nationality label
  const selectedNationality = nationalityOptions.find(
    (option) => option.value === formik.values.Nationality
  );
  const nationalityLabel = selectedNationality
    ? dir === "rtl"
      ? selectedNationality.labelAR
      : selectedNationality.labelEN
    : "";

  // Strings for UAE in both languages
  const uaeLabelEN = "United Arab Emirates";
  const uaeLabelAR = "الإمارات العربية المتحدة";

  // Condition to check if the selected nationality is not UAE
  const isNotUAE =
    nationalityLabel !== uaeLabelEN && nationalityLabel !== uaeLabelAR;

  // Condition to check if the selected nationality is UAE
  const isUAE =
    nationalityLabel === uaeLabelEN || nationalityLabel === uaeLabelAR;

  // Labels for Abu Dhabi in both languages
  const abuDhabiLabelEN = "Abu Dhabi";
  const abuDhabiLabelAR = "أبو ظبي";

  // Get the selected emirate label
  const emirateLabel = emiratesLookup[formik.values.Emirate]
    ? dir === "rtl"
      ? emiratesLookup[formik.values.Emirate].labelAR
      : emiratesLookup[formik.values.Emirate].labelEN
    : "";

  // Condition to check if the selected emirate is Abu Dhabi
  const isAbuDhabi =
    emirateLabel === abuDhabiLabelEN || emirateLabel === abuDhabiLabelAR;

  // Get the selected region label
  const selectedRegionLabel = regionLookup[formik.values.Region]
    ? dir === "rtl"
      ? regionLookup[formik.values.Region].labelAR
      : regionLookup[formik.values.Region].labelEN
    : "";

  // Check if a region is selected
  const isRegionSelected = !!formik.values.Region;
  // console.log("region check karan hai", isRegionSelected);

  const filteredDistrictsOptions = formik.values.Region
    ? districtsOptions.filter(
        (districts) =>
          districtsLookup[districts.value].regionID ===
          regionLookup[formik.values.Region].dmtHubId
      )
    : [];

  // Filter districts based on the selected region's DmtHubId
  // console.log("filteredDistrictsOptions",filteredDistrictsOptions)

  const selectedOption = nationalityOptions.find(
    (option) => option.value === formik.values.Nationality
  );

  // console.log("Selected Option:", selectedOption);

  const dropdownValue = selectedOption
    ? {
        label: dir === "rtl" ? selectedOption.labelAR : selectedOption.labelEN,
        value: formik.values.Nationality,
      }
    : { label: "", value: "" }; // Default

  // console.log("Dropdown Value:", dropdownValue);

  const selectedOptionEmirates = emiratesOptions.find(
    (option) => option.value === formik.values.Emirate
  );

  // console.log("Selected Option:", selectedOption);

  const dropdownValueEmirates = selectedOptionEmirates
    ? {
        label:
          dir === "rtl"
            ? selectedOptionEmirates.labelAR
            : selectedOptionEmirates.labelEN,
        value: formik.values.Emirate,
      }
    : { label: "", value: "" }; // Default

  const selectedOptionRegion = regionOptions.find(
    (option) => option.value === formik.values.Region
  );

  // console.log("Selected Option:", selectedOption);

  const dropdownValueRegion = selectedOptionRegion
    ? {
        label:
          dir === "rtl"
            ? selectedOptionRegion.labelAR
            : selectedOptionRegion.labelEN,
        value: formik.values.Region,
      }
    : { label: "", value: "" }; // Default

  // console.log("Dropdown Value:", dropdownValue);

  const selectedOptionDistrict = districtsOptions.find(
    (option) => option.value === formik.values.District
  );

  // console.log("Selected Option:", selectedOption);

  const dropdownValueDistrict = selectedOptionDistrict
    ? {
        label:
          dir === "rtl"
            ? selectedOptionDistrict.labelAR
            : selectedOptionDistrict.labelEN,
        value: formik.values.District,
      }
    : { label: "", value: "" }; // Default

  // console.log("Dropdown Value:", dropdownValue);
  const loaderContainerStyle = {
    display: "flex",
    justifyContent: "center", // Centers horizontally
    alignItems: "center", // Centers vertically
    //height: '100vh'            // Full viewport height
  };

  const loaderStyle = {
    border: "8px solid #f3f3f3", // Light grey
    borderTop: "8px solid #3498db", // Blue
    borderRadius: "50%",
    width: "60px",
    height: "60px",
    animation: "spin 2s linear infinite",
  };

  const spinKeyframes = `@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }`;

  if (isLoading) {
    return (
      <div style={loaderContainerStyle}>
        <style>{spinKeyframes}</style> {/* Add the spin animation */}
        <div style={loaderStyle}></div> {/* Custom loader */}
      </div>
    );
  }
  const isArabic = i18n.language === 'ar';
  // const isArabic = false; 
  return (
    <>
      {/* Snackbar Component for displaying messages */}
      <Snackbar
        open={snackBarOpen}
        autoHideDuration={6000} // Duration in milliseconds
        onClose={() => setSnackBarOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: isArabic ? "left" : "right",  }} // Positioning
        style={{ top: '100px' }} // Adjust top positioning as needed
        // className={isArabic ? "rtl-snackbar" : "ltr-snackbar"}
    >
        <Alert
          onClose={() => setSnackBarOpen(false)}
          severity={snackBarSeverity}
          sx={{
            width: "auto",
            direction: isArabic ? "rtl" : "ltr", // Use a variable to determine language
            textAlign: isArabic ? "left" : "right", // Align text based on language
            margin: "16px", // Optional margin to keep spacing
            // top: "200px !important",
          }}
        >
          {snackBarMessage}
        </Alert>
      </Snackbar>

      <form onSubmit={formik.handleSubmit}>
        <Grid
          className={styles.container}
          container
          direction="row"
          justifyContent="flex-start"
          spacing={2} // Add spacing between Grid items
        >
          <Dropdown
            containerClassName={styles.dropdown}
            className={classNames(styles.dropdownInput, {
              [styles.error]:
                formik.touched.Title && Boolean(formik.errors.Title),
            })}
            value={formik.values.Title}
            onChange={(event) => {
              // console.log("Dropdown value changed:", event.target.value);
              formik.handleChange(event);
            }}
            // onChange={formik.handleChange}
            helperText={formik.touched.Title && formik.errors.Title}
            label="Title"
            name="Title"
            options={personalDetailsOptions.titleOptions}
            onBlur={(event) => {
              formik.handleBlur(event); // Trigger validation on blur (default Formik behavior)
            }}
            onFocus={() => {
              // Mark the field as touched when focused
              formik.setFieldTouched("Title", true, true);
            }}
          />
          <InputField
            containerClass={styles.inputField}
            className={classNames({
              [styles.error]:
                formik.touched.FirstName && Boolean(formik.errors.FirstName),
            })}
            placeholder="First Name English"
            name="FirstName"
            value={formik.values.FirstName}
            onChange={(event) => {
              // console.log("InputField value changed:", event.target.value);
              formik.handleChange(event);
            }}
            helperText={formik.touched.FirstName && formik.errors.FirstName}
            onBlur={(event) => {
              formik.handleBlur(event); // Trigger validation on blur (default Formik behavior)
            }}
            onFocus={() => {
              // Mark the field as touched when focused
              formik.setFieldTouched("FirstName", true, true);
            }}
          />
          <InputField
            containerClass={styles.inputField}
            className={classNames({
              [styles.error]:
                formik.touched.FatherName && Boolean(formik.errors.FatherName),
            })}
            placeholder="Father Name English"
            name="FatherName"
            value={formik.values.FatherName}
            onChange={formik.handleChange}
            helperText={formik.touched.FatherName && formik.errors.FatherName}
            onBlur={(event) => {
              formik.handleBlur(event); // Trigger validation on blur (default Formik behavior)
            }}
            onFocus={() => {
              // Mark the field as touched when focused
              formik.setFieldTouched("FatherName", true, true);
            }}
          />
          <InputField
            containerClass={styles.inputField}
            className={classNames({
              [styles.error]:
                formik.touched.LastName && Boolean(formik.errors.LastName),
            })}
            placeholder="Last Name English"
            name="LastName"
            value={formik.values.LastName}
            onChange={formik.handleChange}
            helperText={formik.touched.LastName && formik.errors.LastName}
            onBlur={(event) => {
              formik.handleBlur(event); // Trigger validation on blur (default Formik behavior)
            }}
            onFocus={() => {
              // Mark the field as touched when focused
              formik.setFieldTouched("LastName", true, true);
            }}
          />

          <InputField
            containerClass={styles.inputField}
            className={classNames({
              [styles.error]:
                formik.touched.GrandFatherName &&
                Boolean(formik.errors.GrandFatherName),
            })}
            placeholder="Grand Father Name English"
            name="GrandFatherName"
            value={formik.values.GrandFatherName}
            onChange={formik.handleChange}
            helperText={
              formik.touched.GrandFatherName && formik.errors.GrandFatherName
            }
            onBlur={(event) => {
              formik.handleBlur(event); // Trigger validation on blur (default Formik behavior)
            }}
            onFocus={() => {
              // Mark the field as touched when focused
              formik.setFieldTouched("GrandFatherName", true, true);
            }}
          />
          <InputField
            containerClass={styles.inputField}
            className={classNames({
              [styles.error]:
                formik.touched.FirstNameAR &&
                Boolean(formik.errors.FirstNameAR),
            })}
            placeholder="First Name Arabic"
            name="FirstNameAR"
            value={formik.values.FirstNameAR}
            onChange={(event) => {
              // console.log("InputField value changed:", event.target.value);
              formik.handleChange(event);
            }}
            helperText={formik.touched.FirstNameAR && formik.errors.FirstNameAR}
            onBlur={(event) => {
              formik.handleBlur(event); // Trigger validation on blur (default Formik behavior)
            }}
            onFocus={() => {
              // Mark the field as touched when focused
              formik.setFieldTouched("FirstNameAR", true, true);
            }}
          />

          <InputField
            containerClass={styles.inputField}
            className={classNames({
              [styles.error]:
                formik.touched.FatherNameAR &&
                Boolean(formik.errors.FatherNameAR),
            })}
            placeholder="Father Name Arabic"
            name="FatherNameAR"
            value={formik.values.FatherNameAR}
            onChange={formik.handleChange}
            helperText={
              formik.touched.FatherNameAR && formik.errors.FatherNameAR
            }
            onBlur={(event) => {
              formik.handleBlur(event); // Trigger validation on blur (default Formik behavior)
            }}
            onFocus={() => {
              // Mark the field as touched when focused
              formik.setFieldTouched("FatherNameAR", true, true);
            }}
          />

          <InputField
            containerClass={styles.inputField}
            className={classNames({
              [styles.error]:
                formik.touched.LastNameAR && Boolean(formik.errors.LastNameAR),
            })}
            placeholder="Last Name Arabic"
            name="LastNameAR"
            value={formik.values.LastNameAR}
            onChange={formik.handleChange}
            helperText={formik.touched.LastNameAR && formik.errors.LastNameAR}
            onBlur={(event) => {
              formik.handleBlur(event); // Trigger validation on blur (default Formik behavior)
            }}
            onFocus={() => {
              // Mark the field as touched when focused
              formik.setFieldTouched("LastNameAR", true, true);
            }}
          />

          <InputField
            containerClass={styles.inputField}
            className={classNames({
              [styles.error]:
                formik.touched.GrandFatherNameAR &&
                Boolean(formik.errors.GrandFatherNameAR),
            })}
            placeholder="Grand Father Name Arabic"
            name="GrandFatherNameAR"
            value={formik.values.GrandFatherNameAR}
            onChange={formik.handleChange}
            helperText={
              formik.touched.GrandFatherNameAR &&
              formik.errors.GrandFatherNameAR
            }
            onBlur={(event) => {
              formik.handleBlur(event); // Trigger validation on blur (default Formik behavior)
            }}
            onFocus={() => {
              // Mark the field as touched when focused
              formik.setFieldTouched("GrandFatherNameAR", true, true);
            }}
          />
          <Dropdown
            containerClassName={styles.dropdown}
            className={classNames(styles.dropdownInput, {
              [styles.error]:
                formik.touched.Gender && Boolean(formik.errors.Gender),
            })}
            value={formik.values.Gender}
            onChange={(event) => {
              // console.log("Dropdown value changed:", event.target.Gender);
              formik.handleChange(event);
            }}
            // onChange={formik.handleChange}
            helperText={formik.touched.Gender && formik.errors.Gender}
            label="Gender"
            name="Gender"
            options={personalDetailsOptions.genderOptions}
            onBlur={(event) => {
              formik.handleBlur(event); // Trigger validation on blur (default Formik behavior)
            }}
            onFocus={() => {
              // Mark the field as touched when focused
              formik.setFieldTouched("Gender", true, true);
            }}
          />
          <InputField
            containerClass={styles.inputField}
            className={classNames({
              [styles.error]:
                formik.touched.MotherName && Boolean(formik.errors.MotherName),
            })}
            placeholder="Mother Name"
            name="MotherName"
            value={formik.values.MotherName}
            onChange={formik.handleChange}
            helperText={formik.touched.MotherName && formik.errors.MotherName}
            onBlur={(event) => {
              formik.handleBlur(event); // Trigger validation on blur (default Formik behavior)
            }}
            onFocus={() => {
              // Mark the field as touched when focused
              formik.setFieldTouched("MotherName", true, true);
            }}
          />
          <InputField
            containerClass={styles.inputField}
            className={classNames({
              [styles.error]:
                formik.touched.PreferredName &&
                Boolean(formik.errors.PreferredName),
            })}
            placeholder="Preferred Name"
            name="PreferredName"
            value={formik.values.PreferredName}
            onChange={formik.handleChange}
            helperText={
              formik.touched.PreferredName && formik.errors.PreferredName
            }
            onBlur={(event) => {
              formik.handleBlur(event); // Trigger validation on blur (default Formik behavior)
            }}
            onFocus={() => {
              // Mark the field as touched when focused
              formik.setFieldTouched("PreferredName", true, true);
            }}
          />
          <Dropdown
            containerClassName={styles.dropdown}
            className={classNames(styles.dropdownInput, {
              [styles.error]:
                formik.touched.LocalOrResident &&
                Boolean(formik.errors.LocalOrResident),
            })}
            label={t("Local Or Resident")}
            name="LocalOrResident"
            value={formik.values.LocalOrResident}
            onChange={(event) => {
              // Update Formik's field value for LocalOrResident
              const selectedValue = event.target.value === true; // Adjust this check based on how you are storing true/false values
              formik.setFieldValue("LocalOrResident", selectedValue);

              // Check if LocalOrResident is true
              if (selectedValue) {
                // When true, clear specific fields
                formik.setFieldValue("IsVisitVisa", "");
                // Add other fields to clear based on your requirements when true
              } else {
                // When false, clear other specific fields
                formik.setFieldValue("EmiratesID", "");
                formik.setFieldValue("Emiratesidcopy", "");
                formik.setFieldValue("Emirate", "");
                formik.setFieldValue("ResidenceAddressinUAE", "");
                setEmiratesIdCopyFile("");
                // Add other fields to clear based on your requirements when false
              }
              // Call Formik's handleChange if you want to update state for other purposes
              formik.handleChange(event);
            }}
            helperText={
              formik.touched.LocalOrResident && formik.errors.LocalOrResident
            }
            options={personalDetailsOptions.IsLocalResidentOptions}
            onBlur={(event) => {
              formik.handleBlur(event); // Trigger validation on blur (default Formik behavior)
            }}
            onFocus={() => {
              // Mark the field as touched when focused
              formik.setFieldTouched("LocalOrResident", true, true);
            }}
          />
          {formik.values.LocalOrResident === true && (
            <>
              <InputField
                containerClass={styles.inputField}
                className={classNames({
                  [styles.error]:
                    formik.touched.EmiratesID &&
                    Boolean(formik.errors.EmiratesID),
                })}
                placeholder="Emirates ID"
                name="EmiratesID"
                value={formik.values.EmiratesID}
                onChange={formik.handleChange}
                helperText={
                  formik.touched.EmiratesID && formik.errors.EmiratesID
                }
                onBlur={(event) => {
                  formik.handleBlur(event); // Trigger validation on blur (default Formik behavior)
                }}
                onFocus={() => {
                  // Mark the field as touched when focused
                  formik.setFieldTouched("EmiratesID", true, true);
                }}
              />
            </>
          )}
          {formik.values.LocalOrResident === false && (
            <>
              <Dropdown
                containerClassName={styles.dropdown}
                className={classNames(styles.dropdownInput, {
                  [styles.error]:
                    formik.touched.IsVisitVisa &&
                    Boolean(formik.errors.IsVisitVisa),
                })}
                label="Have Visit Visa"
                name="IsVisitVisa"
                value={formik.values.IsVisitVisa}
                onChange={(event) => {
                  // Update Formik's field value for LocalOrResident
                  const selectedValue = event.target.value === true; // Adjust this check based on how you are storing true/false values
                  formik.setFieldValue("IsVisitVisa", selectedValue);

                  // Check if LocalOrResident is true
                  if (!selectedValue) {
                    formik.setFieldValue("unifiedNumber", "");
                  }
                  formik.handleChange(event);
                }}
                helperText={
                  formik.touched.IsVisitVisa && formik.errors.IsVisitVisa
                }
                options={personalDetailsOptions.IsLocalResidentOptions}
                onBlur={(event) => {
                  formik.handleBlur(event); // Trigger validation on blur (default Formik behavior)
                }}
                onFocus={() => {
                  // Mark the field as touched when focused
                  formik.setFieldTouched("IsVisitVisa", true, true);
                }}
              />
              {(formik.values.IsVisitVisa === true ||
                formik.values.IsVisitVisa === "true") && (
                <>
                  <>
                    <InputField
                      containerClass={styles.inputField}
                      className={classNames({
                        [styles.error]:
                          formik.touched.unifiedNumber &&
                          Boolean(formik.errors.unifiedNumber),
                      })}
                      placeholder="Enter your unified number"
                      name="unifiedNumber"
                      value={formik.values.unifiedNumber}
                      onChange={formik.handleChange}
                      helperText={
                        formik.touched.unifiedNumber &&
                        formik.errors.unifiedNumber
                      }
                      onBlur={(event) => {
                        formik.handleBlur(event); // Trigger validation on blur (default Formik behavior)
                      }}
                      onFocus={() => {
                        // Mark the field as touched when focused
                        formik.setFieldTouched("unifiedNumber", true, true);
                      }}
                    />
                  </>
                </>
              )}
            </>
          )}
          <SearchableDropdownOB
            containerClass={styles.inputField}
            className={classNames({
              [styles.error]:
                formik.touched.Nationality &&
                Boolean(formik.errors.Nationality),
            })}
            placeholder="Nationality"
            name="Nationality"
            options={nationalityOptions.map((option) => ({
              label: dir === "rtl" ? option.labelAR : option.labelEN, // Conditional rendering based on language direction
              value: option.value,
            }))}
            // Setting the value by finding the selected option in nationalityOptions

            onChange={(_event, value) => {
              // Log the selected value to the console
              // console.log("Dropdown value changed:", value);
              // Log the key being set in Formik state
              const selectedKey = value?.value;
              const selectedLabel = value?.label; // Get the label as well
              // console.log(
              //   "Setting Formik field 'Nationality' to key:",
              //   selectedKey
              // );
              // Store only the key in Formik state
              formik.setFieldValue("Nationality", selectedKey);

              if (selectedLabel === t("United Arab Emirates")) {
                sessionStorage.setItem(
                  "isVisaSectionEnabled",
                  JSON.stringify(false)
                );
                //empty visa image and other fields
                formik.setFieldValue("Country", "");
                formik.setFieldValue("HomeCountryAddress", "");
                // formik.setFieldValue("Visacopy", "");
                setVisaCopyFile("");
              } else {
                sessionStorage.setItem(
                  "isVisaSectionEnabled",
                  JSON.stringify(true)
                );
                // formik.setFieldValue("FamilyBook", "");
                setFamilyBookFile("");
              }
              onNationalityChange(selectedLabel); // Notify parent of the change
            }}
            value={dropdownValue}
            helperText={formik.touched.Nationality && formik.errors.Nationality}
            onBlur={(event) => {
              formik.handleBlur(event); // Trigger validation on blur (default Formik behavior)
            }}
            onFocus={() => {
              // Mark the field as touched when focused
              formik.setFieldTouched("Nationality", true, true);
            }}
          />
          <CustomDatePicker
            containerClass={styles.inputField}
            className={classNames(styles.inputField, {
              [styles.error]:
                formik.touched.DateofBirth &&
                Boolean(formik.errors.DateofBirth),
            })}
            // value={formik.values.DateOfBirth}
            value={formik.values.DateofBirth} // Ensure it's a Date object
            helperText={formik.touched.DateofBirth && formik.errors.DateofBirth}
            placeholder="Birth Date"
            name="DateofBirth"
            onChange={(date) => {
              // Set the value directly as a Date object for Formik state
              formik.setFieldValue("DateofBirth", date); // Store the Date object
            }}
            onBlur={(event) => {
              formik.handleBlur(event); // Trigger validation on blur (default Formik behavior)
            }}
            onFocus={() => {
              // Mark the field as touched when focused
              formik.setFieldTouched("DateofBirth", true, true);
            }}
          />
          <SearchableDropdownOB
            containerClass={styles.inputField}
            className={classNames({
              [styles.error]:
                formik.touched.CountryofBirth &&
                Boolean(formik.errors.CountryofBirth),
            })}
            placeholder="Country of Birth"
            name="CountryofBirth"
            onChange={(_event, value) => {
              // Log the selected value to the console
              // console.log("Dropdown value changed:", value);
              // Log the key being set in Formik state
              const selectedKey = value?.value;
              // console.log(
              //   "Setting Formik field 'CountryofBirth' to key:",
              //   selectedKey
              // );
              // Store only the key in Formik state
              formik.setFieldValue("CountryofBirth", selectedKey);
            }}
            // options={countryOptions}
            // value={formik.values.CountryofBirth}
            value={
              nationalityOptions.find(
                (option) => option.value === formik.values.CountryofBirth
              ) // Use lookup to show the label
                ? {
                    label:
                      dir === "rtl"
                        ? countryLookup[formik.values.CountryofBirth].labelAR
                        : countryLookup[formik.values.CountryofBirth].labelEN,
                    value: formik.values.CountryofBirth,
                  }
                : { label: "", value: "" }
            } // Fallback in case of no value
            // options={formattedNationalityOptions}
            options={countryOptions.map((option) => ({
              label: dir === "rtl" ? option.labelAR : option.labelEN, // Conditional rendering based on language direction
              value: option.value,
            }))}
            helperText={
              formik.touched.CountryofBirth && formik.errors.CountryofBirth
            }
            onBlur={(event) => {
              formik.handleBlur(event); // Trigger validation on blur (default Formik behavior)
            }}
            onFocus={() => {
              // Mark the field as touched when focused
              formik.setFieldTouched("CountryofBirth", true, true);
            }}
          />
          <InputField
            containerClass={styles.inputField}
            className={classNames({
              [styles.error]:
                formik.touched.RegionofBirth &&
                Boolean(formik.errors.RegionofBirth),
            })}
            placeholder="Region of Birth"
            name="RegionofBirth"
            value={formik.values.RegionofBirth}
            onChange={formik.handleChange}
            helperText={
              formik.touched.RegionofBirth && formik.errors.RegionofBirth
            }
            onBlur={(event) => {
              formik.handleBlur(event); // Trigger validation on blur (default Formik behavior)
            }}
            onFocus={() => {
              // Mark the field as touched when focused
              formik.setFieldTouched("RegionofBirth", true, true);
            }}
          />
          <InputField
            containerClass={styles.inputField}
            className={classNames({
              [styles.error]:
                formik.touched.PlaceofBirth &&
                Boolean(formik.errors.PlaceofBirth),
            })}
            value={formik.values.PlaceofBirth}
            helperText={
              formik.touched.PlaceofBirth && formik.errors.PlaceofBirth
            }
            placeholder="Place of Birth"
            name="PlaceofBirth"
            onChange={formik.handleChange}
            onBlur={(event) => {
              formik.handleBlur(event); // Trigger validation on blur (default Formik behavior)
            }}
            onFocus={() => {
              // Mark the field as touched when focused
              formik.setFieldTouched("PlaceofBirth", true, true);
            }}
          />
          {/* <SearchableDropdownOB
            containerClass={styles.inputField}
            className={classNames({
              [styles.error]:
                formik.touched.RegionofBirth &&
                Boolean(formik.errors.RegionofBirth),
            })}
            onChange={(_event, value) => {
              // Log the selected value to the console
              console.log("Dropdown value changed:", value);
              // Log the key being set in Formik state
              const selectedKey = value?.value;
              console.log(
                "Setting Formik field 'RegionofBirth' to key:",
                selectedKey
              );
              // Store only the key in Formik state
              formik.setFieldValue("RegionofBirth", selectedKey);
            }}
            options={regionOptions.map((option) => ({
              label: dir === "rtl" ? option.labelAR : option.labelEN, // Conditional rendering based on language direction
              value: option.value,
            }))}
            // value={formik.values.RegionofBirth}
            value={
              regionLookup[formik.values.RegionofBirth] // Use lookup to show the label
                ? {
                    label:
                      dir === "rtl"
                        ? regionLookup[formik.values.RegionofBirth].labelAR
                        : regionLookup[formik.values.RegionofBirth].labelEN,
                    value: formik.values.RegionofBirth,
                  }
                : { label: "Select Region of Birth", value: "" }
            } // Fallback in case of no value
            helperText={
              formik.touched.RegionofBirth && formik.errors.RegionofBirth
            }
            placeholder="Region of Birth"
            name="RegionofBirth"
          /> */}

          <InputField
            containerClass={styles.inputField}
            className={classNames({
              [styles.error]:
                formik.touched.Email && Boolean(formik.errors.Email),
            })}
            placeholder="Email"
            name="Email"
            value={String(formik.values.Email)} // Convert value to string
            onChange={(event) => {
              const emailValue = String(event.target.value); // Convert input value to string
              // console.log("InputField value changed:", emailValue);
              formik.setFieldValue("Email", emailValue); // Set the Formik field value
            }}
            helperText={formik.touched.Email && formik.errors.Email}
            onBlur={(event) => {
              formik.handleBlur(event); // Trigger validation on blur (default Formik behavior)
            }}
            onFocus={() => {
              // Mark the field as touched when focused
              formik.setFieldTouched("Email", true, true);
            }}
          />

          <Grid item xs={12} sm={12} md={4}>
            <PhoneInput
              country={formik.values.CountryCode}
              value={formik.values.MobileNumber}
              containerClass={styles.MBinputField}
              // component={PHInputField}
              className={classNames(
                styles.inputField, // existing styles
                // styles.uniquePhoneInputClass, // Use the dynamic class
                {
                  [styles.error]:
                    formik.touched.MobileNumber &&
                    Boolean(formik.errors.MobileNumber),
                  uniquePhoneInputClass: true, // add unique class here
                }
              )}
              onChange={handlePhoneChange}
              inputProps={{
                name: "MobileNumber",
              }}
            />
            <div
              style={{
                color: "red",
                fontSize: "0.575rem",
                marginTop: "-0.25rem",
              }}
            >
              {formik.touched.MobileNumber && formik.errors.MobileNumber}
            </div>
          </Grid>

          <Dropdown
            containerClassName={styles.dropdown}
            className={classNames(styles.dropdownInput, {
              [styles.error]:
                formik.touched.Religion && Boolean(formik.errors.Religion),
            })}
            value={formik.values.Religion}
            onChange={formik.handleChange}
            helperText={formik.touched.Religion && formik.errors.Religion}
            label="Religion"
            name="Religion"
            options={personalDetailsOptions.ReligionOptions}
            onBlur={(event) => {
              formik.handleBlur(event); // Trigger validation on blur (default Formik behavior)
            }}
            onFocus={() => {
              // Mark the field as touched when focused
              formik.setFieldTouched("ReligionOptions", true, true);
            }}
          />

          <Dropdown
            containerClassName={styles.dropdown}
            className={classNames(styles.dropdownInput, {
              [styles.error]:
                formik.touched.MaritalStatus &&
                Boolean(formik.errors.MaritalStatus),
            })}
            value={formik.values.MaritalStatus}
            onChange={formik.handleChange}
            helperText={
              formik.touched.MaritalStatus && formik.errors.MaritalStatus
            }
            label="Marital Status"
            name="MaritalStatus"
            options={personalDetailsOptions.MaritalStatusOptions}
            onBlur={(event) => {
              formik.handleBlur(event); // Trigger validation on blur (default Formik behavior)
            }}
            onFocus={() => {
              // Mark the field as touched when focused
              formik.setFieldTouched("MaritalStatus", true, true);
            }}
          />

          <Dropdown
            containerClassName={styles.dropdown}
            className={classNames(styles.dropdownInput, {
              [styles.error]:
                formik.touched.HasDisability &&
                Boolean(formik.errors.HasDisability),
            })}
            label="Do you have any disability ?"
            name="HasDisability"
            value={formik.values.HasDisability}
            // onChange={formik.handleChange}
            onChange={(event) => {
              // Update Formik's field value for LocalOrResident
              const selectedValue = event.target.value === true; // Adjust this check based on how you are storing true/false values
              formik.setFieldValue("HasDisability", selectedValue);

              // Check if LocalOrResident is true
              if (!selectedValue) {
                formik.setFieldValue("DisabilityDetails", "");
              }
              formik.handleChange(event);
            }}
            helperText={
              formik.touched.HasDisability && formik.errors.HasDisability
            }
            options={personalDetailsOptions.hasDisabilityOptions}
            onBlur={(event) => {
              formik.handleBlur(event); // Trigger validation on blur (default Formik behavior)
            }}
            onFocus={() => {
              // Mark the field as touched when focused
              formik.setFieldTouched("HasDisability", true, true);
            }}
          />

          {formik.values.HasDisability && (
            <>
              <InputField
                containerClass={styles.inputField}
                className={classNames({
                  [styles.error]:
                    formik.touched.DisabilityDetails &&
                    Boolean(formik.errors.DisabilityDetails),
                })}
                value={formik.values.DisabilityDetails}
                helperText={
                  formik.touched.DisabilityDetails &&
                  formik.errors.DisabilityDetails
                }
                placeholder="Disability Details"
                name="DisabilityDetails"
                onChange={formik.handleChange}
                onBlur={(event) => {
                  formik.handleBlur(event); // Trigger validation on blur (default Formik behavior)
                }}
                onFocus={() => {
                  // Mark the field as touched when focused
                  formik.setFieldTouched("DisabilityDetails", true, true);
                }}
              />
            </>
          )}
          <Dropdown
            containerClassName={styles.dropdown}
            className={classNames(styles.dropdownInput, {
              [styles.error]:
                formik.touched.BloodGroup && Boolean(formik.errors.BloodGroup),
            })}
            value={formik.values.BloodGroup}
            onChange={formik.handleChange}
            helperText={formik.touched.BloodGroup && formik.errors.BloodGroup}
            label="Blood Group"
            name="BloodGroup"
            options={personalDetailsOptions.bloodGroupOptions}
            onBlur={(event) => {
              formik.handleBlur(event); // Trigger validation on blur (default Formik behavior)
            }}
            onFocus={() => {
              // Mark the field as touched when focused
              formik.setFieldTouched("bloodGroupOptions", true, true);
            }}
          />
          <InputField
            containerClass={styles.inputField}
            className={classNames({
              [styles.error]:
                formik.touched.Height && Boolean(formik.errors.Height),
            })}
            placeholder="Height (cm)"
            name="Height"
            value={formik.values.Height}
            // onChange={formik.handleChange}
            onChange={(event) => {
              const value = event.target.value;
              // console.log("Raw input value:", value); // Log the raw input value

              // Allow empty string or valid decimal number
              if (/^\d*\.?\d*$/.test(value)) {
                formik.setFieldValue("Height", value); // Keep as string for user input
              }
            }}
            helperText={formik.touched.Height && formik.errors.Height}
            onBlur={(event) => {
              formik.handleBlur(event); // Trigger validation on blur (default Formik behavior)
            }}
            onFocus={() => {
              // Mark the field as touched when focused
              formik.setFieldTouched("Height", true, true);
            }}
          />

          <InputField
            containerClass={styles.inputField}
            className={classNames({
              [styles.error]:
                formik.touched.Weight && Boolean(formik.errors.Weight),
            })}
            placeholder="Weight (kg)"
            name="Weight"
            value={formik.values.Weight}
            // onChange={formik.handleChange}
            onChange={(event) => {
              const value = event.target.value;
              // console.log("Raw input value:", value); // Log the raw input value

              // Allow empty string or valid decimal number
              if (/^\d*\.?\d*$/.test(value)) {
                formik.setFieldValue("Weight", value); // Keep as string for user input
              }
            }}
            helperText={formik.touched.Weight && formik.errors.Weight}
            onBlur={(event) => {
              formik.handleBlur(event); // Trigger validation on blur (default Formik behavior)
            }}
            onFocus={() => {
              // Mark the field as touched when focused
              formik.setFieldTouched("Weight", true, true);
            }}
          />
          {/* <InputField
            containerClass={styles.inputField}
            className={classNames({
              [styles.error]:
                formik.touched.City && Boolean(formik.errors.City),
            })}
            placeholder="City"
            name="City"
            value={formik.values.City}
            onChange={formik.handleChange}
            helperText={formik.touched.City && formik.errors.City}
          /> */}
        </Grid>
        {(formik.values.LocalOrResident === true ||
          formik.values.IsVisitVisa === true) && (
          <>
            <Divider className={styles.divider} />
            <Grid
              className={styles.container}
              container
              direction="row"
              justifyContent="flex-start"
              spacing={2} // Add spacing between Grid items
            >
              <Grid item xs={12} sm={12} md={12}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "#e9ecef", // Light grey background
                    padding: "10px",
                    borderLeft: "2px solid teal", // Border on the left side
                    fontSize: "16px",
                    // fontWeight: "bold",
                    color: "#333", // Darker text color
                  }}
                >
                  {t("Residence Address in UAE")}
                </div>
              </Grid>

              <SearchableDropdownOB
                containerClass={styles.inputField}
                className={classNames({
                  [styles.error]:
                    formik.touched.Emirate && Boolean(formik.errors.Emirate),
                })}
                // value={
                //   emiratesLookup[formik.values.Emirate]
                //     ? {
                //         label:
                //           dir === "rtl"
                //             ? emiratesLookup[formik.values.Emirate].labelAR
                //             : emiratesLookup[formik.values.Emirate].labelEN,
                //         value: formik.values.Emirate,
                //       }
                //     : { label: "", value: "" } // Fallback
                // }
                onChange={(_event, value) => {
                  const selectedKey = value?.value;
                  formik.setFieldValue("Emirate", selectedKey);
                  formik.setFieldValue("Region", ""); // Reset Region when Emirate changes
                  formik.setFieldValue("District", ""); // Reset District when Emirate changes
                }}
                helperText={formik.touched.Emirate && formik.errors.Emirate}
                name="Emirate"
                options={emiratesOptions.map((option) => ({
                  label: dir === "rtl" ? option.labelAR : option.labelEN,
                  value: option.value,
                }))}
                placeholder="Emirate"
                value={dropdownValueEmirates}
                onBlur={(event) => {
                  formik.handleBlur(event); // Trigger validation on blur (default Formik behavior)
                }}
                onFocus={() => {
                  // Mark the field as touched when focused
                  formik.setFieldTouched("Emirate", true, true);
                }}
              />

              {isAbuDhabi && (
                <SearchableDropdownOB
                  containerClass={styles.inputField}
                  className={classNames({
                    [styles.error]:
                      formik.touched.Region && Boolean(formik.errors.Region),
                  })}
                  // value={
                  //   regionLookup[formik.values.Region]
                  //     ? {
                  //         label:
                  //           dir === "rtl"
                  //             ? regionLookup[formik.values.Region].labelAR
                  //             : regionLookup[formik.values.Region].labelEN,
                  //         value: formik.values.Region,
                  //       }
                  //     : { label: "", value: "" } // Fallback
                  // }
                  onChange={(_event, value) => {
                    const selectedKey = value?.value;
                    formik.setFieldValue("Region", selectedKey);
                    formik.setFieldValue("District", ""); // Reset District when Region changes
                  }}
                  helperText={formik.touched.Region && formik.errors.Region}
                  name="Region"
                  options={regionOptions.map((option) => ({
                    label: dir === "rtl" ? option.labelAR : option.labelEN,
                    value: option.value,
                  }))}
                  placeholder="Region"
                  value={dropdownValueRegion}
                  onBlur={(event) => {
                    formik.handleBlur(event); // Trigger validation on blur (default Formik behavior)
                  }}
                  onFocus={() => {
                    // Mark the field as touched when focused
                    formik.setFieldTouched("Region", true, true);
                  }}
                />
              )}

              {isRegionSelected && isAbuDhabi && (
                <SearchableDropdownOB
                  containerClass={styles.inputField}
                  className={classNames({
                    [styles.error]:
                      formik.touched.District &&
                      Boolean(formik.errors.District),
                  })}
                  onChange={(_event, value) => {
                    const selectedKey = value.value;
                    formik.setFieldValue("District", selectedKey);
                  }}
                  helperText={formik.touched.District && formik.errors.District}
                  name="District"
                  options={filteredDistrictsOptions.map((option) => ({
                    label: dir === "rtl" ? option.labelAR : option.labelEN,
                    value: option.value,
                  }))}
                  placeholder="Select District"
                  value={dropdownValueDistrict}
                  onBlur={(event) => {
                    formik.handleBlur(event); // Trigger validation on blur (default Formik behavior)
                  }}
                  onFocus={() => {
                    // Mark the field as touched when focused
                    formik.setFieldTouched("District", true, true);
                  }}
                />
              )}
              <InputField
                containerClass={styles.inputField}
                className={classNames({
                  [styles.error]:
                    formik.touched.ResidenceAddressinUAE &&
                    Boolean(formik.errors.ResidenceAddressinUAE),
                })}
                placeholder="Residence Address in UAE"
                name="ResidenceAddressinUAE"
                value={formik.values.ResidenceAddressinUAE}
                onChange={formik.handleChange}
                helperText={
                  formik.touched.ResidenceAddressinUAE &&
                  formik.errors.ResidenceAddressinUAE
                }
                onBlur={(event) => {
                  formik.handleBlur(event); // Trigger validation on blur (default Formik behavior)
                }}
                onFocus={() => {
                  // Mark the field as touched when focused
                  formik.setFieldTouched("ResidenceAddressinUAE", true, true);
                }}
              />
            </Grid>
          </>
        )}

        {isNotUAE && (
          <>
            <Divider className={styles.divider} />
            <Grid
              className={styles.container}
              container
              direction="row"
              justifyContent="flex-start"
              spacing={2} // Add spacing between Grid items
            >
              <Grid item xs={12} sm={12} md={12}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "#e9ecef", // Light grey background
                    padding: "10px",
                    borderLeft: "2px solid teal", // Border on the left side
                    fontSize: "16px",
                    // fontWeight: "bold",
                    color: "#333", // Darker text color
                  }}
                >
                  {t("Home Country Address")}
                </div>
              </Grid>
              <SearchableDropdownOB
                containerClass={styles.inputField}
                className={classNames({
                  [styles.error]:
                    formik.touched.Country && Boolean(formik.errors.Country),
                })}
                placeholder="Country"
                name="Country"
                onChange={(_event, value) => {
                  // Log the selected value to the console
                  // console.log("Dropdown value changed:", value);
                  // Log the key being set in Formik state
                  const selectedKey = value?.value;
                  // console.log(
                  //   "Setting Formik field 'Country' to key:",
                  //   selectedKey
                  // );
                  // Store only the key in Formik state
                  formik.setFieldValue("Country", selectedKey);
                }}
                // options={countryOptions}
                options={countryOptions.map((option) => ({
                  label: dir === "rtl" ? option.labelAR : option.labelEN, // Conditional rendering based on language direction
                  value: option.value,
                }))}
                // value={formik.values.Country}
                value={
                  countryLookup[formik.values.Country] // Use lookup to show the label
                    ? {
                        label:
                          dir === "rtl"
                            ? countryLookup[formik.values.Country].labelAR
                            : countryLookup[formik.values.Country].labelEN,
                        value: formik.values.Country,
                      }
                    : { label: "", value: "" }
                } // Fallback in case of no value
                helperText={formik.touched.Country && formik.errors.Country}
                onBlur={(event) => {
                  formik.handleBlur(event); // Trigger validation on blur (default Formik behavior)
                }}
                onFocus={() => {
                  // Mark the field as touched when focused
                  formik.setFieldTouched("Country", true, true);
                }}
              />

              <InputField
                containerClass={styles.inputField}
                className={classNames({
                  [styles.error]:
                    formik.touched.HomeCountryAddress &&
                    Boolean(formik.errors.HomeCountryAddress),
                })}
                placeholder="Home Country Address"
                name="HomeCountryAddress"
                value={formik.values.HomeCountryAddress}
                onChange={formik.handleChange}
                helperText={
                  formik.touched.HomeCountryAddress &&
                  formik.errors.HomeCountryAddress
                }
                onBlur={(event) => {
                  formik.handleBlur(event); // Trigger validation on blur (default Formik behavior)
                }}
                onFocus={() => {
                  // Mark the field as touched when focused
                  formik.setFieldTouched("HomeCountryAddress", true, true);
                }}
              />
            </Grid>
          </>
        )}
        <Divider className={styles.divider} />

        <Grid
          className={styles.container}
          container
          direction="row"
          justifyContent="flex-start"
          spacing={2} // Add spacing between Grid items
        >
          <Grid item xs={12} sm={4} md={4}>
            <>
              <div
                className={`file-uploader-container ${
                  touched.PersonalPhoto && errors.PersonalPhoto ? "error" : ""
                }`}
              >
                <FileUploader
                  headingText="Upload Personal Photo"
                  helperText="Or Drag And Drop Personal Photo Here"
                  state={personalPhotoFile}
                  setState={(file) => {
                    setPersonalPhotoFile(file); // Track the uploaded file
                    // Clear the validation error if a file is uploaded
                    if (file) {
                      formik.setFieldValue("PersonalPhoto", file); // Update Formik state
                      formik.setFieldError("PersonalPhoto", null); // Clear existing error
                    } else {
                      formik.setFieldError(
                        "PersonalPhoto",
                        "Personal Photo is required."
                      ); // Set error if file is null
                    }
                  }}
                  name="PersonalPhoto"
                  error={
                    formik.touched.PersonalPhoto &&
                    Boolean(errors.PersonalPhoto)
                  }
                  onBlur={(event) => {
                    formik.handleBlur(event); // Trigger validation on blur (default Formik behavior)
                  }}
                  onFocus={() => {
                    // Mark the field as touched when focused to show errors
                    formik.setFieldTouched("PersonalPhoto", true, true);
                  }}
                />
              </div>
              <div
                style={{
                  color: "red",
                  fontSize: "0.575rem",
                  marginTop: "-0.25rem",
                }}
              >
                {touched.PersonalPhoto && errors.PersonalPhoto}
              </div>
            </>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <>
              <FileUploader
                headingText="Upload Passport Copy"
                helperText="Or Drag And Drop Passport Here"
                state={passportFile}
                setState={(file) => {
                  setPassportFile(file); // Track the uploaded file
                  // Clear the validation error if a file is uploaded
                  if (file) {
                    formik.setFieldValue("Passportcopy", file); // Update Formik state
                    formik.setFieldError("Passportcopy", null); // Clear existing error
                  } else {
                    formik.setFieldError(
                      "Passportcopy",
                      "Passport copy is required."
                    ); // Set error if file is null
                  }
                }}
                name="Passportcopy"
                error={
                  formik.touched.Passportcopy &&
                  Boolean(formik.errors.Passportcopy)
                }
                onBlur={(event) => {
                  formik.handleBlur(event); // Trigger validation on blur (default Formik behavior)
                }}
                onFocus={() => {
                  // Mark the field as touched when focused to show errors
                  formik.setFieldTouched("Passportcopy", true, true);
                }}
              />
              {/* helperText={touched.passportcopy && errors.passportcopy} */}
              <div
                style={{
                  color: "red",
                  fontSize: "0.575rem",
                  marginTop: "-0.25rem",
                }}
              >
                {formik.touched.Passportcopy && formik.errors.Passportcopy}
              </div>
            </>
          </Grid>

          {isNotUAE && (
            <Grid item xs={12} sm={4} md={4}>
              <>
                <FileUploader
                  headingText="Upload Visa Copy"
                  helperText="Or Drag And Drop Visa Copy Here"
                  state={visaCopyFile}
                  setState={(file) => {
                    setVisaCopyFile(file); // Track the uploaded file
                    // Clear the validation error if a file is uploaded
                    if (file) {
                      formik.setFieldValue("Visacopy", file); // Update Formik state
                      formik.setFieldError("Visacopy", null); // Clear existing error
                    } else {
                      formik.setFieldError(
                        "Visacopy",
                        "Visa Copy is required."
                      ); // Set error if file is null
                    }
                  }}
                  name="Visacopy"
                  error={
                    formik.touched.Visacopy && Boolean(formik.errors.Visacopy)
                  }
                  onBlur={(event) => {
                    formik.handleBlur(event); // Trigger validation on blur (default Formik behavior)
                  }}
                  onFocus={() => {
                    // Mark the field as touched when focused to show errors
                    formik.setFieldTouched("Visacopy", true, true);
                  }}
                />
                <div
                  style={{
                    color: "red",
                    fontSize: "0.575rem",
                    marginTop: "-0.25rem",
                  }}
                >
                  {formik.touched.Visacopy && formik.errors.Visacopy}
                </div>
              </>
            </Grid>
          )}

          {isUAE && (
            <>
              <Grid item xs={12} sm={4} md={4}>
                <FileUploader
                  headingText="Upload Family Book"
                  helperText="Or Drag And Drop Family Book Here"
                  state={familyBookFile}
                  setState={(file) => {
                    setFamilyBookFile(file); // Track the uploaded file
                    // Clear the validation error if a file is uploaded
                    if (file) {
                      formik.setFieldValue("FamilyBook", file); // Update Formik state
                      formik.setFieldError("FamilyBook", null); // Clear existing error
                    } else {
                      formik.setFieldError(
                        "FamilyBook",
                        "Family Book is required."
                      ); // Set error if file is null
                    }
                  }}
                  name="FamilyBook"
                  error={
                    formik.touched.FamilyBook &&
                    Boolean(formik.errors.FamilyBook)
                  }
                  onBlur={(event) => {
                    formik.handleBlur(event); // Trigger validation on blur (default Formik behavior)
                  }}
                  onFocus={() => {
                    // Mark the field as touched when focused to show errors
                    formik.setFieldTouched("FamilyBook", true, true);
                  }}
                />
                <div
                  style={{
                    color: "red",
                    fontSize: "0.575rem",
                    marginTop: "-0.25rem",
                  }}
                >
                  {formik.touched.FamilyBook && formik.errors.FamilyBook}
                </div>
              </Grid>
            </>
          )}
          {formik.values.LocalOrResident === true && (
            <>
              <Grid item xs={12} sm={4} md={4}>
                <>
                  <FileUploader
                    headingText="Upload Emirates ID Copy"
                    helperText="Or Drag And Drop Emirates ID Copy Here"
                    state={emiratesIdCopyFile}
                    setState={(file) => {
                      setEmiratesIdCopyFile(file); // Track the uploaded file
                      // Clear the validation error if a file is uploaded
                      if (file) {
                        formik.setFieldValue("Emiratesidcopy", file); // Update Formik state
                        formik.setFieldError("Emiratesidcopy", null); // Clear existing error
                      } else {
                        formik.setFieldError(
                          "Emiratesidcopy",
                          "Emirates Copy is required."
                        ); // Set error if file is null
                      }
                    }}
                    name="Emiratesidcopy"
                    error={
                      formik.touched.Emiratesidcopy &&
                      Boolean(formik.errors.Emiratesidcopy)
                    }
                    onBlur={(event) => {
                      formik.handleBlur(event); // Trigger validation on blur (default Formik behavior)
                    }}
                    onFocus={() => {
                      // Mark the field as touched when focused to show errors
                      formik.setFieldTouched("Emiratesidcopy", true, true);
                    }}
                  />
                  <div
                    style={{
                      color: "red",
                      fontSize: "0.575rem",
                      marginTop: "-0.25rem",
                    }}
                  >
                    {formik.touched.Emiratesidcopy &&
                      formik.errors.Emiratesidcopy}
                  </div>
                  {/* {!isFileUploaderActive &&
                    personalInformation?.attachtments?.length > 0 && (
                      <img
                        src={`data:${personalInformation.attachtments[0]?.ContentType};base64,${personalInformation.attachtments[0]?.InputStream?._buffer}`}
                        alt="Applicant's Personal Photo"
                        style={{
                          width: "150px",
                          height: "150px",
                          objectFit: "cover",
                        }}
                      />
                    )} */}
                </>
              </Grid>
            </>
          )}
        </Grid>
        <Divider className={styles.divider} />
        {/* <ActionButtons activeStep={activeStep} /> */}
        <ActionButtons
          nationality={selectedNationality}
          activeStep={activeStep}
        />
      </form>
    </>
  );
};

export default PersonalInformation;
