import { Button, Divider, Grid } from "@material-ui/core";
import { Add, Remove } from "@material-ui/icons";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import CustomDatePicker from "../../../components/CustomDatePicker/CustomDatePicker";
import Dropdown from "../../../components/DropDown/DropDown";
import InputField from "../../../components/InputField/InputField";
import { getLookupOptions } from "../../../lookupData.options";
import { generalReducerActions } from "../../../store/reducers/generalReducer";
import {
  getExperienceDetailsById,
  getExperienceFieldOptions,
  registrationDataActions,
  saveExperienceDetails,
} from "../../../store/reducers/registrationReducer";
import ActionButtons from "../ActionButtons/ActionButtons";
import useStyle from "./ExperienceDetails.styles";
import { Form, FieldArray, Field, Formik } from 'formik';
import { getExperienceDetailsValidation } from "./validation";
import classNames from "classnames";

const ExperienceDetails = () => {
  const { t } = useTranslation();
  const styles = useStyle();
  const { dir } = useSelector((state) => state.generalStates);
  const { experienceFieldOptions, activeStep, experienceDetails } = useSelector((state) => state.registration);
  const dispatch = useDispatch();

  const experienceDetailsOptions = useMemo(() => getLookupOptions(dir), [dir]);
  const [id] = useState(localStorage.getItem('LoginId') || localStorage.getItem('RegistrationId'));
  const [experienceDetailsData, setExperienceDetailsData] = useState([
    {
      Status: "",
      CompanyName: "",
      ExperienceFieldId: "",
      CurrentSalary: "",
      EndDate: "",
      StartDate: "",
      JobName: "",
      RegistrationFormId: id,
    },
  ]);

  const onClickSubmit = async (values) => {
    let experienceData = [...values.experience];
    experienceData.forEach(data => {
      for (let key in data) {
        if (data[key]?.length === 0 || data[key] === null) {
          delete data[key];
        }
      }
    })
    dispatch(generalReducerActions.toggleLoading(true));
    const response = await dispatch(saveExperienceDetails(experienceData));
    if (response.payload.status === 200) {
      if (window.location.pathname === "/user-profile") {
        dispatch(generalReducerActions.snackBarUpdate({
          open: true,
          message: "Your profile has been updated successfully",
          severity: 'success',
        }));
      }
      dispatch(generalReducerActions.toggleLoading(false));
      dispatch(registrationDataActions.setActiveStep(activeStep + 1));
    } else {
      dispatch(generalReducerActions.toggleLoading(false));
      dispatch(generalReducerActions.snackBarUpdate({
        open: true,
        message: "Something went wrong. We are having trouble completing your request, please try again",
        severity: 'error',
      }));
    }
  };

  const formattedExperienceFieldOptions = useMemo(() => {
    const options = experienceFieldOptions?.map((data) => {
      return {
        dropdownValue: data.Key,
        label: dir === "ltr" ? data.Value : data.ValueAR,
      };
    });
    options.unshift({
      dropdownValue: "",
      label: dir === "ltr" ? "Select" : "Select",
    });
    return options;
  }, [dir, experienceFieldOptions]);

  useEffect(() => {
    dispatch(getExperienceDetailsById(id));
  }, [dispatch, id]);

  useEffect(() => {
    experienceFieldOptions.length === 0 && dispatch(getExperienceFieldOptions());
  }, [dispatch, experienceFieldOptions.length]);

  useEffect(() => {
    if (experienceDetails?.length > 0) {
      let data = experienceDetails.map(experience => {
        return {
          Status: experience.Status,
          CompanyName: experience.CompanyName,
          ExperienceFieldId: experience.ExperienceFieldId,
          CurrentSalary: experience.CurrentSalary,
          EndDate: experience.EndDate,
          StartDate: experience.StartDate,
          JobName: experience.JobName,
          RegistrationFormId: id,
        }
      })
      setExperienceDetailsData(data)
    }
  }, [experienceDetails, id]);

  return (
    <Formik
      initialValues={{
        experience: [...experienceDetailsData]
      }}
      validationSchema={getExperienceDetailsValidation(t)}
      onSubmit={(values) => onClickSubmit(values)}
      enableReinitialize
    >
      {({ values, errors, touched, handleChange }) => (
        <Form>
          <FieldArray name="experience">
            {({ push, remove }) => (
              <>
                {values.experience.map((_, index) => (
                  <React.Fragment key={index}>
                    {values.experience.length > 1 && <Button
                      className={styles.textButtonRemove}
                      variant="text"
                      color="secondary"
                      startIcon={<Remove fontSize="small" />}
                      onClick={() => remove(index)}
                    >
                      {t("Remove Experience")}
                    </Button>}
                    <Grid
                      className={styles.container}
                      container
                      direction="row"
                      justifyContent="flex-start"
                    >
                      <Field
                        name={`experience[${index}].Status`}
                        label="Status"
                        containerClassName={`${styles.dropdown}`}
                        component={Dropdown}
                        options={experienceDetailsOptions.status}
                        value={values.experience[index].Status}
                        helperText={
                          (Object.keys(touched).length > 0 && Object.keys(errors).length > 0) &&
                          (touched?.experience[index]?.Status && errors?.experience[index]?.Status)
                        }
                        className={classNames(styles.dropdownInput,
                          {
                            [styles.error]: (Object.keys(touched).length > 0 && Object.keys(errors).length > 0) &&
                              (touched?.experience[index]?.Status && Boolean(errors?.experience[index]?.Status))
                          })}
                      />
                      {
                        (values.experience[index].Status !== 3 && values.experience[index].Status !== "") && (
                          <>
                            <Field
                              containerClass={`${styles.inputField}`}
                              name={`experience.${index}.CompanyName`}
                              placeholder="Company Name"
                              helperText={
                                (Object.keys(touched).length > 0 && Object.keys(errors).length > 0) &&
                                (touched?.experience[index]?.CompanyName && errors?.experience[index]?.CompanyName)
                              }
                              className={classNames(
                                {
                                  [styles.error]: (Object.keys(touched).length > 0 && Object.keys(errors).length > 0) &&
                                    (touched?.experience[index]?.CompanyName && Boolean(errors?.experience[index]?.CompanyName))
                                })}
                              component={InputField}
                              value={values.experience[index].CompanyName}
                              startAdornment=""
                              endAdornment=""
                            />
                            <Field
                              name={`experience[${index}].ExperienceFieldId`}
                              label="Experience Field"
                              containerClassName={`${styles.dropdown}`}
                              component={Dropdown}
                              options={formattedExperienceFieldOptions}
                              value={values.experience[index].ExperienceFieldId}
                              helperText={
                                (Object.keys(touched).length > 0 && Object.keys(errors).length > 0) &&
                                (touched?.experience[index]?.ExperienceFieldId && errors?.experience[index]?.ExperienceFieldId)
                              }
                              className={classNames(styles.dropdownInput,
                                {
                                  [styles.error]: (Object.keys(touched).length > 0 && Object.keys(errors).length > 0) &&
                                    (touched?.experience[index]?.ExperienceFieldId && Boolean(errors?.experience[index]?.ExperienceFieldId))
                                })}
                            />
                            <Field
                              containerClass={`${styles.inputField}`}
                              name={`experience.${index}.JobName`}
                              placeholder="Job Name"
                              helperText={
                                (Object.keys(touched).length > 0 && Object.keys(errors).length > 0) &&
                                (touched?.experience[index]?.JobName && errors?.experience[index]?.JobName)
                              }
                              className={classNames(
                                {
                                  [styles.error]: (Object.keys(touched).length > 0 && Object.keys(errors).length > 0) &&
                                    (touched?.experience[index]?.JobName && Boolean(errors?.experience[index]?.JobName))
                                })}
                              component={InputField}
                              value={values.experience[index].JobName}
                              startAdornment=""
                              endAdornment=""
                            />
                            <Field
                              name={`experience.${index}.StartDate`}
                              placeholder="Start Date"
                              helperText={
                                (Object.keys(touched).length > 0 && Object.keys(errors).length > 0) &&
                                (touched?.experience[index]?.StartDate && errors?.experience[index]?.StartDate)
                              }
                              className={classNames(styles.inputField,
                                {
                                  [styles.error]: (Object.keys(touched).length > 0 && Object.keys(errors).length > 0) &&
                                    (touched?.experience[index]?.StartDate && Boolean(errors?.experience[index]?.StartDate))
                                })}
                              component={CustomDatePicker}
                              onChange={(date) =>
                                handleChange({
                                  target: {
                                    name: `experience.${index}.StartDate`,
                                    value: date
                                  },
                                })
                              }
                              value={values.experience[index].StartDate}
                            />

                            {values.experience[index].Status === 2 &&
                              <Field
                                name={`experience.${index}.EndDate`}
                                placeholder="End Date"
                                helperText={
                                  (Object.keys(touched).length > 0 && Object.keys(errors).length > 0) &&
                                  (touched?.experience[index]?.EndDate && errors?.experience[index]?.EndDate)
                                }
                                className={classNames(styles.inputField,
                                  {
                                    [styles.error]: (Object.keys(touched).length > 0 && Object.keys(errors).length > 0) &&
                                      (touched?.experience[index]?.EndDate && Boolean(errors?.experience[index]?.EndDate))
                                  })}
                                component={CustomDatePicker}
                                onChange={(date) =>
                                  handleChange({
                                    target: {
                                      name: `experience.${index}.EndDate`,
                                      value: date
                                    },
                                  })
                                }
                                value={values.experience[index].EndDate}
                              />
                            }
                            <Field
                              containerClass={`${styles.inputField}`}
                              name={`experience.${index}.CurrentSalary`}
                              placeholder={values.experience[index].Status === 2 ? "Previous Salary" : "Current Salary"}
                              type="number"
                              helperText={
                                (Object.keys(touched).length > 0 && Object.keys(errors).length > 0) &&
                                (touched?.experience[index]?.CurrentSalary && errors?.experience[index]?.CurrentSalary)
                              }
                              className={classNames(
                                {
                                  [styles.error]: (Object.keys(touched).length > 0 && Object.keys(errors).length > 0) &&
                                    (touched?.experience[index]?.CurrentSalary && Boolean(errors?.experience[index]?.CurrentSalary))
                                })}
                              component={InputField}
                              value={values.experience[index].CurrentSalary}
                              startAdornment=""
                              endAdornment=""
                            />
                          </>
                        )
                      }
                    </Grid>
                    {values.experience.length - 1 !== index && (
                      <Divider className={styles.divider} />
                    )}
                  </React.Fragment>
                ))}
                <Grid
                  className={styles.container}
                  container
                  direction="row"
                  justifyContent="flex-end"
                >
                  <Button
                    className={styles.textButton}
                    variant="text"
                    startIcon={<Add className={styles.icon} fontSize="small" />}
                    onClick={() => push({
                      Status: "",
                      CompanyName: "",
                      ExperienceFieldId: "",
                      CurrentSalary: "",
                      EndDate: "",
                      StartDate: "",
                      JobName: "",
                      RegistrationFormId: id,
                    })}
                  >
                    {t("Add Another Experience")}
                  </Button>
                </Grid>
                <Divider style={{ margin: "20px 0" }} />
                <ActionButtons />
              </>
            )}
          </FieldArray>
        </Form>
      )}

    </Formik >
  );
};

export default ExperienceDetails;
