import {
    SvgIcon,
    TextField,
    FormHelperText
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import useStyle from './SearchableDropdownOB.styles';

const SearchableDropdownOB = (props) => {
    const {
        containerClass,
        className,
        label = '',
        options = [],
        name = '',
        id = '',
        placeholder = '',
        multiple = false,
        field,
        helperText,
        value,
        onChange,
        ...properties
    } = props;

    const { t } = useTranslation();
    const styles = useStyle();
    // console.log(value, "value");
    return (
        <Autocomplete
            {...properties}
            {...field}
            className={`${containerClass} ${styles.containerClass}`}
            name={name}
            id={id}
            options={options}
            multiple={multiple}
            value={value || {}}
            getOptionLabel={(option) => t(option.label)}
            getOptionSelected={(option, value) => option.dropdownValue === value.dropdownValue}
            forcePopupIcon={false}
            blurOnSelect={!multiple}
            openOnFocus
            onChange={(event, value) => onChange(event, value)}
            classes={{
                listbox: styles.auto_complete_popup,
                popper: styles.dropdown,
                paper: styles.auto_complete_paper,
            }}
            renderInput={(params) => (
                <div className={`${styles.input_container}`}>
                    <label className={styles.label}>{label}</label>
                    <TextField
                        {...params}
                        placeholder={t(placeholder)}
                        InputProps={{
                            ...params.InputProps,
                            disableUnderline: true,
                            className: [styles.input, className].join(' '),
                            endAdornment: (
                                <SvgIcon
                                    className={
                                        properties.disabled
                                            ? [
                                                styles.dropdownIcon,
                                                styles.cursor_default,
                                                "search-icon",
                                            ].join(' ')
                                            : [styles.dropdownIcon, "search-icon"].join(' ')
                                    }
                                />
                            ),
                        }}
                        InputLabelProps={{
                            classes: {
                                root: styles.dropdown_label,
                                focused: styles.focus_dropdown,
                                animated: styles.no_animation,
                            },
                        }}
                    />
                    {helperText && <FormHelperText>{helperText}</FormHelperText>}
                </div>
            )}
        />
    );
};

export default SearchableDropdownOB;