import {
  SliderArrowNext,
  SliderArrowPrev,
} from "../../components/SliderArrows/SliderArrows";

const sliderSettings = {
  arrows: true,
  infinite: false,
  rtl: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  nextArrow: <SliderArrowNext />,
  prevArrow: <SliderArrowPrev />,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 770,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

export { sliderSettings };
