import { Button, Divider, Grid, Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { useMemo, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import CustomDatePicker from "../../../components/CustomDatePicker/CustomDatePicker";
import Dropdown from "../../../components/DropDown/DropDown";
import InputField from "../../../components/InputField/InputField";
import { getLookupOptions } from "../../../lookupData.options";
import ActionButtons from "../ActionButtons/ActionButtons";
import useStyle from "./EmployeeVisa.styles";
import { Form, Field, Formik } from "formik";
import { getemployeeVisaValidation } from "./validation";
import classNames from "classnames";
import {
  setActiveStep,
  submitFormData,
  updateFormData,
  fetchLookupData,
} from "../../../store/reducers/onboardingReducer";
import SearchableDropdownOB from "../../../components/SearchableDropdownOB/SearchableDropdownOB";
import ClipLoader from "react-spinners/ClipLoader"; // Optional, if using react-spinners

const EmployeeVisaInfo = ({ nationality }) => {
  // if(!visible) return null;
  const { i18n, t } = useTranslation();
  const styles = useStyle();
  const { dir } = useSelector((state) => state.generalStates);
  const isVisible = nationality !== t("United Arab Emirates");
  const {
    activeStep,
    employeeVisaInfo,
    parentId,
    uniqueRecordId,
    status,
    lookupData,
  } = useSelector((state) => state.onboarding);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false); // Loader state
  const [nationalityOptions, setNationalityOptions] = useState([]);

  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [snackBarSeverity, setSnackBarSeverity] = useState("success");

  // Fetch lookup data on component mount
  useEffect(() => {
    if (status === "idle") {
      dispatch(fetchLookupData(["GetNationality"]));
    }
  }, [dispatch, status]);

  useEffect(() => {
    if (status === "succeeded") {
      // Update nationality options
      if (lookupData.GetNationality) {
        setNationalityOptions(
          lookupData.GetNationality.map((item) => ({
            label: item.Value,
            value: item.Key,
          }))
        );
      }
    }
  }, [lookupData, status]);

  const employeeVisaInfoOptions = useMemo(() => getLookupOptions(dir), [dir]);

  const initialValues = {
    visaNumber: employeeVisaInfo?.visaNumber || "",
    visaType: employeeVisaInfo?.visaType || 0,
    issueDate: employeeVisaInfo?.issueDate,
    expiryDate: employeeVisaInfo?.expiryDate,
    freshGraduate: employeeVisaInfo.freshGraduate,
    currentJob: employeeVisaInfo?.currentJob || "",
    company: employeeVisaInfo?.company || "",
    sponsorNumber: employeeVisaInfo?.sponsorNumber || "",
    issuancePlace: employeeVisaInfo?.issuancePlace || "",
    CompanyVisaAndFamilyVisa: false,
    uniqueRecordId: employeeVisaInfo?.uniqueRecordId,
  };

  // const formatDate = (date) => {
  //   try {
  //     const formattedDate = date.toISOString();
  //     return formattedDate;
  //   } catch (_) {
  //     return null;
  //   }
  // };

  const onClickSubmit = async (values) => {
    setIsLoading(true);
    const employeeVisaInfo = { ...values };
    dispatch(updateFormData({ step: activeStep, data: employeeVisaInfo }));
    try {
      const result = await dispatch(
        submitFormData({ employeeVisaInfo, activeStep, parentId })
      );
      if (result.meta.requestStatus === "fulfilled") {
        setSnackBarMessage("Form submitted successfully");
        setSnackBarSeverity("success");
        dispatch(setActiveStep(activeStep + 1));
        // setIsLoading(false);
      } else {
        throw new Error(
          result.payload ||
            result.error.message ||
            t(
              "Something went wrong. We are having trouble completing your request, please try again"
            )
        );
        // setIsLoading(false);
      }
    } catch (error) {
      // setIsLoading(false);
      // console.error("Submission error:", error);
      setSnackBarMessage(
        t(
          "Something went wrong. We are having trouble completing your request, please try again"
        )
      );
      setSnackBarSeverity("error");
    } finally {
      setIsLoading(false); // Stop loading state in both success and error cases
      setSnackBarOpen(true); // Open Snackbar with the message
    }
  };
  const loaderContainerStyle = {
    display: "flex",
    justifyContent: "center", // Centers horizontally
    alignItems: "center", // Centers vertically
    //height: '100vh'            // Full viewport height
  };

  const loaderStyle = {
    border: "8px solid #f3f3f3", // Light grey
    borderTop: "8px solid #3498db", // Blue
    borderRadius: "50%",
    width: "60px",
    height: "60px",
    animation: "spin 2s linear infinite",
  };

  const spinKeyframes = `@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }`;

  if (isLoading) {
    return (
      <div style={loaderContainerStyle}>
        <style>{spinKeyframes}</style> {/* Add the spin animation */}
        <div style={loaderStyle}></div> {/* Custom loader */}
      </div>
    );
  }

  const isArabic = i18n.language === "ar";

  return isVisible ? (
    <>
      <Snackbar
        open={snackBarOpen}
        autoHideDuration={6000} // Duration in milliseconds
        onClose={() => setSnackBarOpen(false)}
        anchorOrigin={{
          vertical: "top",
          horizontal: isArabic ? "left" : "right",
        }} // Positioning
        style={{ top: "100px" }} // Adjust top positioning as needed
        // className={isArabic ? "rtl-snackbar" : "ltr-snackbar"}
      >
        <Alert
          onClose={() => setSnackBarOpen(false)}
          severity={snackBarSeverity}
          sx={{
            width: "auto",
            direction: isArabic ? "rtl" : "ltr", // Use a variable to determine language
            textAlign: isArabic ? "left" : "right", // Align text based on language
            margin: "16px", // Optional margin to keep spacing
            // top: "200px !important",
          }}
        >
          {snackBarMessage}
        </Alert>
      </Snackbar>
      <Formik
        initialValues={{
          employeeVisaInfo: initialValues,
        }}
        validationSchema={getemployeeVisaValidation(t)}
        onSubmit={(values) => onClickSubmit(values)}
        enableReinitialize
      >
        {({ values, errors, touched, setFieldValue, setFieldTouched }) => {
          const nationalityLabel = nationalityOptions.find(
            (option) => option.value === values.employeeVisaInfo.nationality
          )?.label;

          return (
            <>
              <Form>
                <Grid
                  className={styles.container}
                  container
                  direction="row"
                  justifyContent="flex-start"
                >
                  <Field
                    name="employeeVisaInfo.freshGraduate"
                    label="Fresh Graduate?"
                    containerClassName={styles.dropdown}
                    component={Dropdown}
                    options={employeeVisaInfoOptions.freshGraduateOptions}
                    value={values.employeeVisaInfo.freshGraduate}
                    helperText={
                      touched.employeeVisaInfo?.freshGraduate &&
                      errors.employeeVisaInfo?.freshGraduate
                    }
                    className={classNames(styles.dropdownInput, {
                      [styles.error]:
                        touched.employeeVisaInfo?.freshGraduate &&
                        Boolean(errors.employeeVisaInfo?.freshGraduate),
                    })}
                    onChange={(e) =>
                      setFieldValue(
                        "employeeVisaInfo.freshGraduate",
                        e.target.value
                      )
                    }
                    onBlur={() => {
                      setFieldTouched("employeeVisaInfo.freshGraduate", true); // Trigger validation on blur
                    }}
                    onFocus={() => {
                      setFieldTouched("employeeVisaInfo.freshGraduate", true); // Mark field as touched on focus
                    }}
                  />
                  <>
                    {nationality !== t("United Arab Emirates") && (
                      <>
                        <Field
                          name="employeeVisaInfo.visaType"
                          label="Visa Type"
                          containerClassName={styles.dropdown}
                          component={Dropdown}
                          options={employeeVisaInfoOptions.visaTypeOptions}
                          value={values.employeeVisaInfo.visaType || ""}
                          helperText={
                            touched.employeeVisaInfo?.visaType &&
                            errors.employeeVisaInfo?.visaType
                          }
                          className={classNames(styles.dropdownInput, {
                            [styles.error]:
                              touched.employeeVisaInfo?.visaType &&
                              Boolean(errors.employeeVisaInfo?.visaType),
                          })}
                          onChange={(e) =>
                            setFieldValue(
                              "employeeVisaInfo.visaType",
                              e.target.value
                            )
                          }
                          onBlur={() => {
                            setFieldTouched("employeeVisaInfo.visaType", true); // Trigger validation on blur
                          }}
                          onFocus={() => {
                            setFieldTouched("employeeVisaInfo.visaType", true); // Mark field as touched on focus
                          }}
                        />
                        <Field
                          name="employeeVisaInfo.visaNumber"
                          placeholder="Visa Number"
                          containerClass={styles.inputField}
                          component={InputField}
                          value={values.employeeVisaInfo.visaNumber}
                          helperText={
                            touched.employeeVisaInfo?.visaNumber &&
                            errors.employeeVisaInfo?.visaNumber
                          }
                          className={classNames({
                            [styles.error]:
                              touched.employeeVisaInfo?.visaNumber &&
                              Boolean(errors.employeeVisaInfo?.visaNumber),
                          })}
                          onChange={(e) =>
                            setFieldValue(
                              "employeeVisaInfo.visaNumber",
                              e.target.value
                            )
                          }
                          onBlur={() => {
                            setFieldTouched(
                              "employeeVisaInfo.visaNumber",
                              true
                            ); // Trigger validation on blur
                          }}
                          onFocus={() => {
                            setFieldTouched(
                              "employeeVisaInfo.visaNumber",
                              true
                            ); // Mark field as touched on focus
                          }}
                        />

                        <Field
                          name="employeeVisaInfo.issueDate"
                          placeholder="Issue Date"
                          component={CustomDatePicker}
                          helperText={
                            touched.employeeVisaInfo?.issueDate &&
                            errors.employeeVisaInfo?.issueDate
                          }
                          className={classNames(styles.inputField, {
                            [styles.error]:
                              touched.employeeVisaInfo?.issueDate &&
                              Boolean(errors.employeeVisaInfo?.issueDate),
                          })}
                          onChange={(date) => {
                            const isoDate =
                              date instanceof Date && !isNaN(date)
                                ? date.toISOString()
                                : "";
                            setFieldValue(
                              "employeeVisaInfo.issueDate",
                              isoDate
                            );
                          }}
                          value={values.employeeVisaInfo.issueDate || ""}
                          onBlur={() => {
                            setFieldTouched("employeeVisaInfo.issueDate", true); // Trigger validation on blur
                          }}
                          onFocus={() => {
                            setFieldTouched("employeeVisaInfo.issueDate", true); // Mark field as touched on focus
                          }}
                        />
                        <Field
                          name="employeeVisaInfo.expiryDate"
                          placeholder="Expiry Date"
                          component={CustomDatePicker}
                          helperText={
                            touched.employeeVisaInfo?.expiryDate &&
                            errors.employeeVisaInfo?.expiryDate
                          }
                          className={classNames(styles.inputField, {
                            [styles.error]:
                              touched.employeeVisaInfo?.expiryDate &&
                              Boolean(errors.employeeVisaInfo?.expiryDate),
                          })}
                          onChange={(date) => {
                            const isoDate =
                              date instanceof Date && !isNaN(date)
                                ? date.toISOString()
                                : "";
                            setFieldValue(
                              "employeeVisaInfo.expiryDate",
                              isoDate
                            );
                          }}
                          value={values.employeeVisaInfo.expiryDate || ""}
                          onBlur={() => {
                            setFieldTouched(
                              "employeeVisaInfo.expiryDate",
                              true
                            ); // Trigger validation on blur
                          }}
                          onFocus={() => {
                            setFieldTouched(
                              "employeeVisaInfo.expiryDate",
                              true
                            ); // Mark field as touched on focus
                          }}
                        />
                      </>
                    )}
                  </>

                  {values.employeeVisaInfo.freshGraduate === false && (
                    <>
                      <Field
                        name="employeeVisaInfo.currentJob"
                        placeholder="Current Job"
                        containerClass={styles.inputField}
                        component={InputField}
                        value={values.employeeVisaInfo.currentJob}
                        helperText={
                          touched.employeeVisaInfo?.currentJob &&
                          errors.employeeVisaInfo?.currentJob
                        }
                        className={classNames({
                          [styles.error]:
                            touched.employeeVisaInfo?.currentJob &&
                            Boolean(errors.employeeVisaInfo?.currentJob),
                        })}
                        onChange={(e) =>
                          setFieldValue(
                            "employeeVisaInfo.currentJob",
                            e.target.value
                          )
                        }
                        onBlur={() => {
                          setFieldTouched("employeeVisaInfo.currentJob", true); // Trigger validation on blur
                        }}
                        onFocus={() => {
                          setFieldTouched("employeeVisaInfo.currentJob", true); // Mark field as touched on focus
                        }}
                      />
                      <Field
                        name="employeeVisaInfo.company"
                        placeholder="Company"
                        containerClass={styles.inputField}
                        component={InputField}
                        value={values.employeeVisaInfo.company}
                        helperText={
                          touched.employeeVisaInfo?.company &&
                          errors.employeeVisaInfo?.company
                        }
                        className={classNames({
                          [styles.error]:
                            touched.employeeVisaInfo?.company &&
                            Boolean(errors.employeeVisaInfo?.company),
                        })}
                        onChange={(e) =>
                          setFieldValue(
                            "employeeVisaInfo.company",
                            e.target.value
                          )
                        }
                        onBlur={() => {
                          setFieldTouched("employeeVisaInfo.company", true); // Trigger validation on blur
                        }}
                        onFocus={() => {
                          setFieldTouched("employeeVisaInfo.company", true); // Mark field as touched on focus
                        }}
                      />
                      <Field
                        name="employeeVisaInfo.sponsorNumber"
                        placeholder="Sponsor Number"
                        containerClass={styles.inputField}
                        component={InputField}
                        value={values.employeeVisaInfo.sponsorNumber}
                        helperText={
                          touched.employeeVisaInfo?.sponsorNumber &&
                          errors.employeeVisaInfo?.sponsorNumber
                        }
                        className={classNames({
                          [styles.error]:
                            touched.employeeVisaInfo?.sponsorNumber &&
                            Boolean(errors.employeeVisaInfo?.sponsorNumber),
                        })}
                        onChange={(e) =>
                          setFieldValue(
                            "employeeVisaInfo.sponsorNumber",
                            e.target.value
                          )
                        }
                        onBlur={() => {
                          setFieldTouched(
                            "employeeVisaInfo.sponsorNumber",
                            true
                          ); // Trigger validation on blur
                        }}
                        onFocus={() => {
                          setFieldTouched(
                            "employeeVisaInfo.sponsorNumber",
                            true
                          ); // Mark field as touched on focus
                        }}
                      />
                      <Field
                        name="employeeVisaInfo.issuancePlace"
                        placeholder="Issuance Place"
                        containerClass={styles.inputField}
                        component={InputField}
                        value={values.employeeVisaInfo.issuancePlace}
                        helperText={
                          touched.employeeVisaInfo?.issuancePlace &&
                          errors.employeeVisaInfo?.issuancePlace
                        }
                        className={classNames({
                          [styles.error]:
                            touched.employeeVisaInfo?.issuancePlace &&
                            Boolean(errors.employeeVisaInfo?.issuancePlace),
                        })}
                        onChange={(e) =>
                          setFieldValue(
                            "employeeVisaInfo.issuancePlace",
                            e.target.value
                          )
                        }
                        onBlur={() => {
                          setFieldTouched(
                            "employeeVisaInfo.issuancePlace",
                            true
                          ); // Trigger validation on blur
                        }}
                        onFocus={() => {
                          setFieldTouched(
                            "employeeVisaInfo.issuancePlace",
                            true
                          ); // Mark field as touched on focus
                        }}
                      />
                      {/* <Field
                      name="employeeVisaInfo.CompanyVisaAndFamilyVisa"
                      label="Visa"
                      containerClassName={styles.dropdown}
                      component={Dropdown}
                      options={employeeVisaInfoOptions.VisaOptions}
                      value={values.employeeVisaInfo.CompanyVisaAndFamilyVisa}
                      helperText={
                        touched.employeeVisaInfo?.CompanyVisaAndFamilyVisa &&
                        errors.employeeVisaInfo?.CompanyVisaAndFamilyVisa
                      }
                      className={classNames(styles.dropdownInput, {
                        [styles.error]:
                          touched.employeeVisaInfo?.CompanyVisaAndFamilyVisa &&
                          Boolean(errors.employeeVisaInfo?.CompanyVisaAndFamilyVisa),
                      })}
                      onChange={(e) =>
                        setFieldValue("employeeVisaInfo.CompanyVisaAndFamilyVisa", e.target.value)
                      }
                      onBlur={() => {
                        setFieldTouched("employeeVisaInfo.CompanyVisaAndFamilyVisa", true); // Trigger validation on blur
                      }}
                      onFocus={() => {
                        setFieldTouched("employeeVisaInfo.CompanyVisaAndFamilyVisa", true); // Mark field as touched on focus
                      }}
                    /> */}
                    </>
                  )}
                </Grid>

                <Divider style={{ margin: "20px 0" }} />
                <ActionButtons activeStep={activeStep} />
              </Form>
            </>
          );
        }}
      </Formik>
    </>
  ) : null;
};

export default EmployeeVisaInfo;
