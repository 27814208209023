import React, { useMemo } from 'react';
import { ArrowBack as ArrowBackIcon, HomeOutlined, PersonAddOutlined, LockOpenOutlined, PersonOutline, LockOutlined, VpnKeyOutlined } from '@material-ui/icons';
//import useStyles from './styles';

//redux
import { useSelector, useDispatch } from 'react-redux';
import { generalReducerActions } from '../../../store/reducers/generalReducer';
import { withRouter } from 'react-router-dom';
import { authActions } from '../../../store/reducers/authReducer';
import './headerlinks.css';
import { registrationDataActions } from '../../../store/reducers/registrationReducer';
import { useTranslation } from 'react-i18next';

const linksUnAuthenticated = [
	{
		id: 0,
		label: 'Home',
		link: '/',
		icon: <HomeOutlined />,
	},
	{ id: 1, label: 'Signup', link: '/registration-form', icon: <PersonAddOutlined /> },
	{ id: 2, label: 'Login', link: '/login', icon: <LockOpenOutlined /> },
];

function HeaderLinks(props) {
	const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
	const userData = JSON.parse(localStorage.getItem("UserData"));
	const { t } = useTranslation();
  const linksAuthenticated = useMemo(() => [
    {
      id: 0,
      label: 'Home',
      link: '/',
      icon: <HomeOutlined />,
    },
    { id: 1, label: userData?.FirstName, link: '/user-profile', icon: <PersonOutline /> },
    {
      id: 2,
      label: 'Change Password',
      link: '/change-password',
      icon: <VpnKeyOutlined />,
    },
    { id: 3, label: 'Logout', type: "title" },
  ], [dispatch, userData?.FirstName, props.history]);

	return (
		<div id="staticMenu">
			{user.isAuthenticated ? (
				<ul>
					{
						linksAuthenticated.map( link => (
							(link.label !== 'Logout')? (
								(link.link  !== '/user-profile')? (
									<li key={link.id}><a href={link.link}>{t(link.label)}</a></li>
								) : (
									<li key={link.id}><a href={link.link}>{t(`User Profile`)}</a></li>
								)
							):(
								<li key={link.id}><a href="/" onClick={ () => { dispatch(authActions.logout()); dispatch(registrationDataActions.clearRegistrationStates()); props.history.replace("/login"); } } >{t(link.label)}</a></li>
							)
						))
					}
				</ul>
			) : (
				<ul>
					{
						linksUnAuthenticated.map( link => (
							<li key={link.id}><a href={link.link} >{t(link.label)}</a></li>
						))
					}
				</ul>
			)}
				
		</div>
	)

}

export default withRouter(HeaderLinks);
