import React, { useEffect, useMemo, useState } from "react";
import {
  Grid,
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  Button,
} from "@material-ui/core";
import Header from "../../components/Header/Header";
import useStyle from "./OnboardingForm.styles";
import {
  DescriptionOutlined,
  LocalMallOutlined,
  PersonOutline,
  SpeakerNotes,
  VerifiedUser,
  PeopleRounded,
  AssignmentInd,
  AttachFile,
} from "@material-ui/icons";
import PersonalInformation from "./PersonalInformation/PersonalInformation";
import ScientificQualificationDetails from "./ScientificQualificationDetails/ScientificQualificationDetails";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import "./OnboardingForm.css";
import bg from "../../assets/images/Details & Registration Page/HR_3_1366X14318.png";
import classNames from "classnames";
import EmployeeDependentDetails from "./EmployeeDependentDetails/EmployeeDependentDetails";
import EmployeePassport from "./EmployeePassport/EmployeePassport";
import EmployeeVisa from "./EmployeeVisa/EmployeeVisa";
import PreviousEmployee from "./PreviousEmployee/PreviousEmployee";
import CertificationDetails from "./Certification/CertificationDetails";
import { setActiveStep } from "../../store/reducers/onboardingReducer"; // Adjusted import path
import ActionButtons from "./ActionButtons/ActionButtons";
import LastSection from "./LastSection/LastSection";
import WarningIcon from "@material-ui/icons/Warning";

const OnboardingForm = () => {
  const { t } = useTranslation();
  const styles = useStyle();
  const { activeStep, personalInformation } = useSelector((state) => state.onboarding);
  const dispatch = useDispatch();

  const [selectedNationality, setSelectedNationality] = useState('');
  let remarks = personalInformation?.Remarks;

  // Move the useEffect outside of the useMemo
  const queryParams = new URLSearchParams(window.location.search);
  const registrationId = queryParams.get("crmreferenceId");
  // var remarks = '';
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (registrationId !== '') {
        remarks = JSON.parse(sessionStorage.getItem('Remarks'));
        const isVisaSectionEnabledFromLocalStorage = JSON.parse(sessionStorage.getItem('isVisaSectionEnabled'));
        if (isVisaSectionEnabledFromLocalStorage === false) {
          setSelectedNationality(t("United Arab Emirates"));
        }
      }
      else {
        sessionStorage.setItem('isVisaSectionEnabled', JSON.stringify(true));
        sessionStorage.removeItem('Remarks');
      }

    }, 3500); // 3 seconds timeout

    // Cleanup the timeout on component unmount
    return () => clearTimeout(timeoutId);
  }, []); // Run only once when the component mounts

  // Create steps conditionally based on selected nationality
  const steps = useMemo(() => {
    const baseSteps = [
      "Personal Information",
      "Qualification Details",
      "Certification Details",
      "Employee Dependent Details",
      "Employee Passport Info",
    ];

    // Only add Employee Visa step if nationality is not UAE
    if (selectedNationality !== t("United Arab Emirates")) {
      baseSteps.push("Employee Visa Info");
    }

    baseSteps.push("Previous Employee Info");
    return baseSteps;
  }, [selectedNationality]);

  const content = useMemo(() => {
    return [
      <PersonalInformation nationality={selectedNationality} onNationalityChange={setSelectedNationality} />,
      <ScientificQualificationDetails />,
      <CertificationDetails />,
      <EmployeeDependentDetails />,
      <EmployeePassport nationality={selectedNationality} />,
      // ...(selectedNationality !== "United Arab Emirates" ? [<EmployeeVisa nationality={selectedNationality} />] : ""),
      <EmployeeVisa nationality={selectedNationality} />,
      <PreviousEmployee nationality={selectedNationality} />,
      <LastSection />,
      <ActionButtons nationality={selectedNationality} />
    ];
  }, [selectedNationality]);
  // console.log('Side menu Length', content)
  function getStepIcons(props) {
    const { active } = props;
    const icons = {
      1: <PersonOutline fontSize="small" />,
      2: <SpeakerNotes fontSize="small" />,
      3: <LocalMallOutlined fontSize="small" />,
      4: <DescriptionOutlined fontSize="small" />,
      5: <PeopleRounded fontSize="small" />,
      6: selectedNationality !== t("United Arab Emirates") ? <AssignmentInd fontSize="small" /> : <VerifiedUser fontSize="small" />, // Conditional icon
      7: <VerifiedUser fontSize="small" />,
    };
    return (
      <div
        className={classNames(styles.iconDiv, {
          [styles.active]: active,
          [styles.hover]: !!localStorage.getItem("LoginId"),
        })}
      >
        {icons[String(props.icon)]}
      </div>
    );
  }
  useEffect(() => {
    document.title = `${window.location.pathname === "/user-profile" ? t("User Profile") : t("Onboarding Form")} - ${t("DMT Career Portal")}`;
  }, [t]);

  return (
    <React.Fragment>
      <Header bg={bg}>
        <Grid className={styles.containerHead} container direction="column">
          <div className={styles.mainHeading}>
            <p className={styles.infoText}>
              {window.location.pathname === "/user-profile" ? t("User Profile") : t("Onboarding Form")}
            </p>
          </div>
        </Grid>
      </Header>
      <Grid
        className={styles.container}
        container
        direction="row"
        justifyContent="space-between"
      >
        <div className={styles.stepperDiv}>
          <Stepper
            className={styles.stepperRow}
            activeStep={activeStep - 1}
            orientation="vertical"
            // nonLinear={!!localStorage.getItem("LoginId")}
            connector={<StepConnector className={styles.connector} />}
          >
            {steps.map((label, index) => (
              <Step
                className={`${styles.step} stepper-icon`}
                key={label}
                style={{ cursor: 'context-menu' }}// Inline style for cursor
                onClick={() => !!localStorage.getItem("LoginId") && dispatch(setActiveStep(index + 1))}
              >
                <StepLabel StepIconComponent={getStepIcons}>
                  {t(label)}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </div>
        <div className={`${styles.contentDiv} stepper-content-div`}>
          {/* <div>
            {(remarks !== undefined && remarks !== "") ? (
              <div
                style={{
                  fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
                  fontStyle: "italic",
                  marginBottom: "20px",
                }}
              >
                {t("Remarks")}: {remarks}
              </div>
            ) : null}
          </div> */}
          <div>
            {remarks !== undefined && remarks !== "" ? (
              <div
                style={{
                  fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
                  // fontStyle: "italic",
                  marginBottom: "20px",
                  // backgroundColor: "#FFC04D",
                  // border: "1px solid #FFC04D",
                  border: "2px solid #FFC04D",
                  padding: "15px",
                  display: "flex", // Use flexbox for layout
                  alignItems: "center", // Vertically align items
                }}
              >
                {/* Display icon */}
                <span style={{ marginRight: "8px", color: "#FFC04D", border: "1px solid #FFC04D", borderRadius: "50%", padding: "10px", display: "flex", justifyContent: "center", alignItems: "center", width: "40px", height: "40px", }}>
                  <WarningIcon />
                </span>

                {/* Display "Remarks" text */}
                <span style={{ fontWeight: "bold", marginRight: "8px" }}>
                  {t("Remarks")}:
                </span>


                {/* Display actual remarks */}
                <span>{remarks}</span>
              </div>
            ) : null}
          </div>
          <div className={styles.headingDiv}>{t(steps[activeStep - (activeStep === 7 && selectedNationality === t("United Arab Emirates") ? 2 : 1)])}</div>
          {content[activeStep - 1]}
          {/* {activeStep === 8 && <ActionButtons nationality={selectedNationality} />} */}
          {/* <div className="general-message">
            <div>{t("Please feel free to contact on following email and phone number in case of any inquiries and support.")}</div>
            <span className="inline-ele">{t("Email")}: <a href="mailto:Career.Info@dmt.gov.ae">Career.Info@dmt.gov.ae</a></span>
            <div>{t("This email is dedicated for inquiries and support only, please refrain from sending the CV and upload it through the registration portal only.")}</div>
          </div> */}
        </div>
      </Grid>
    </React.Fragment>
  );
};

export default OnboardingForm;
