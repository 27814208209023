import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Field, Form, ErrorMessage, FieldArray } from "formik";
import { Grid, Divider, Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import Dropdown from "../../../components/DropDown/DropDown";
import CustomDatePicker from "../../../components/CustomDatePicker/CustomDatePicker";
import FileUploader from "../../../components/FileUploader/FileUploader";
import InputField from "../../../components/InputField/InputField";
import SearchableDropdownOB from "../../../components/SearchableDropdownOB/SearchableDropdownOB";
import { getLookupOptions } from "../../../lookupData.options";
import ActionButtons from "../ActionButtons/ActionButtons";
import useStyle from "./ScientificQualificationDetails.styles";
import { getQualificationDetailsValidation } from "./validation";
import {
  setActiveStep,
  updateFormData,
  submitFormData,
  fetchLookupData,
  fetchApplicantData,
} from "../../../store/reducers/onboardingReducer";
import classNames from "classnames";
import ClipLoader from "react-spinners/ClipLoader"; // Optional, if using react-spinners

const ScientificQualificationDetails = () => {
  const { i18n, t } = useTranslation();
  const styles = useStyle();
  const { dir } = useSelector((state) => state.generalStates);
  const {
    activeStep,
    status,
    lookupData,
    parentId,
    uniqueRecordId,
    selectedFile,
    qualificationDetails,
  } = useSelector((state) => state.onboarding);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false); // Loader state
  const [educationLevel, setEducationLevel] = useState("");
  const [showFields, setShowFields] = useState(false);
  const [qualificationFiles, setQualificationFiles] = useState([]);

  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [snackBarSeverity, setSnackBarSeverity] = useState("success");

  const Bindings = {
    PHD: {
      1: [
        {
          dropdownValue: 1,
          label:
            dir === "ltr"
              ? "PHD degree or equivalent "
              : "درجة الدكتوراه وما يعادله",
        },
      ],
      2: [
        {
          dropdownValue: 2,
          label:
            dir === "ltr"
              ? "Master degree or equivalent"
              : "درجة الماجستير او ما يعادله",
        },
      ],
      3: [
        {
          dropdownValue: 3,
          label:
            dir === "ltr"
              ? "Bachelor degree or equivalent"
              : "درجة البكالوريوس او ما يعادله",
        },
        {
          dropdownValue: 4,
          label:
            dir === "ltr" ? "Diploma or equivalent" : "دبلومة او ما يعادلها",
        },
        {
          dropdownValue: 5,
          label:
            dir === "ltr"
              ? "High school or equivalent"
              : "الثانوية العامة او ما يعادلها",
        },
        {
          dropdownValue: 6,
          label:
            dir === "ltr" ? "Less than high school" : "اقل من الثانوية العامة",
        },
        {
          dropdownValue: 7,
          label: dir === "ltr" ? "Higher Diploma" : "دبلوم عالي",
        },
      ],
    },
    Masters: {
      1: [
        {
          dropdownValue: 2,
          label:
            dir === "ltr"
              ? "Master degree or equivalent"
              : "درجة الماجستير او ما يعادله",
        },
      ],
      2: [
        {
          dropdownValue: 3,
          label:
            dir === "ltr"
              ? "Bachelor degree or equivalent"
              : "درجة البكالوريوس او ما يعادله",
        },
        {
          dropdownValue: 4,
          label:
            dir === "ltr" ? "Diploma or equivalent" : "دبلومة او ما يعادلها",
        },
        {
          dropdownValue: 5,
          label:
            dir === "ltr"
              ? "High school or equivalent"
              : "الثانوية العامة او ما يعادلها",
        },
        {
          dropdownValue: 6,
          label:
            dir === "ltr" ? "Less than high school" : "اقل من الثانوية العامة",
        },
        {
          dropdownValue: 7,
          label: dir === "ltr" ? "Higher Diploma" : "دبلوم عالي",
        },
      ],
      3: [],
    },
    Bachelors: {
      1: [
        {
          dropdownValue: 3,
          label:
            dir === "ltr"
              ? "Bachelor degree or equivalent"
              : "درجة البكالوريوس او ما يعادله",
        },
        {
          dropdownValue: 4,
          label:
            dir === "ltr" ? "Diploma or equivalent" : "دبلومة او ما يعادلها",
        },
        {
          dropdownValue: 5,
          label:
            dir === "ltr"
              ? "High school or equivalent"
              : "الثانوية العامة او ما يعادلها",
        },
        {
          dropdownValue: 6,
          label:
            dir === "ltr" ? "Less than high school" : "اقل من الثانوية العامة",
        },
        {
          dropdownValue: 7,
          label: dir === "ltr" ? "Higher Diploma" : "دبلوم عالي",
        },
      ],
      2: [],
      3: [],
    },
  };

  const formatDate = (date) => {
    try {
      const formattedDate = date.toISOString();
      return formattedDate;
    } catch (_) {
      return null;
    }
  };


  function getCrmReferenceID() {
    try {
      const params = new URLSearchParams(window.location.search);
      return params.get("crmreferenceId");
    } catch (_) { }
  }

  const qualificationDetailsOptions = useMemo(
    () => getLookupOptions(dir),
    [dir]
  );

  const [academicSpecialityOptions, setAcademicSpecialityOptions] = useState([]);

  useEffect(() => {
    if (status === "idle") {
      dispatch(fetchLookupData(["AcademiceSpeciality"]));
    }
  }, [dispatch, status]);

  useEffect(() => {
    if (status === "succeeded" && lookupData.AcademiceSpeciality) {
      const mappedSpecialityOptions = lookupData.AcademiceSpeciality.map((item) => ({
        labelEN: item.Value,
        labelAR: item.ValueAR,
        value: item.Key,
      }));

      setAcademicSpecialityOptions(mappedSpecialityOptions);
      // console.log("Lookup Data:", lookupData);
    }
  }, [lookupData, status]);

  // Determine education level based on qualification details length
  const determineEducationLevel = (qualificationDetails) => {
    if (!qualificationDetails || !Array.isArray(qualificationDetails)) {
      return "";
    }
    const length = qualificationDetails.length;
    if (length >= 3) return "PHD";
    if (length === 2) return "Masters";
    if (length === 1) return "Bachelors"; // or any other logic you want
    return "";
  };
  

  // Set education level when qualificationDetails changes
  useEffect(() => {
    const level = determineEducationLevel(qualificationDetails);
    setEducationLevel(level);
    setShowFields(level !== "");
  }, [qualificationDetails]);

  const numberOfFields = useMemo(() => {
    switch (educationLevel) {
      case "PHD":
        return 3;
      case "Masters":
        return 2;
      default:
        return 1; // Default for Bachelors, Diplomas, etc.
    }
  }, [educationLevel]);

  const generateInitialValues = () => {
    const safeQualificationDetails = Array.isArray(qualificationDetails)
      ? qualificationDetails
      : [];

    const initialQualificationDetails = safeQualificationDetails.map(
      (detail) => ({
        Degree: detail.Degree || "",
        School: detail.School || "",
        Speciality: detail.Speciality || "",
        Status: detail.Status || "",
        StartDate: detail.StartDate || null,
        EndDate: detail.EndDate || null,
        attachment: detail.attachtments || "",
        uniqueRecordId: detail.uniqueRecordId || "",
      })
    );

    return {
      EducationLevel: educationLevel,
      qualificationDetails:
        initialQualificationDetails.length > 0
          ? initialQualificationDetails
          : Array.from({ length: numberOfFields }, () => ({
            Degree: "",
            School: "",
            Speciality: "",
            Status: "",
            StartDate: null,
            EndDate: null,
            attachment: "",
            //uniqueRecordId:"",
          })),
    };
  };

  const initialValues = useMemo(
    () => generateInitialValues(),
    [qualificationDetails, numberOfFields, educationLevel]
  );

  const handleEducationLevelChange = useCallback((value, setFieldValue) => {
    setFieldValue("EducationLevel", value);
    setEducationLevel(value);
    if (value) {
      setShowFields(true);
    } else {
      setShowFields(false);
    }
  }, []);

  const handleFieldChange = useCallback((index, name, value, setFieldValue) => {
    setFieldValue(`qualificationDetails[${index}].${name}`, value);
  }, []);

  // // Convert base64 to Blob
  const base64ToBlob = (base64, contentType = "", sliceSize = 512) => {
    const byteCharacters = atob(base64.split(",")[1]);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);

      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  };



  useEffect(() => {
    if (qualificationDetails && qualificationDetails.length > 0) {
      const allFiles = qualificationDetails.map((detail) => {
        const attachments = detail.attachtments || [];

        // return attachments.map((attachment) => {
        const byteString = attachments.InputStream?._buffer || ""; // Fallback to empty string
        const contentType = attachments.ContentType;
        const fileName = attachments.FileName;

        const base64Image = `data:${contentType};base64,${byteString}`;
        //const blob = base64ToBlob(base64Image, contentType); // Convert to Blob

        return {
          name: fileName,
          type: contentType,
          size: attachments.ContentLength,
          image: base64Image, // Keep base64 for display
          //blob: blob, // Store Blob for submission
        };
      });
      // });

      const flattenedFiles = allFiles;
      setQualificationFiles(flattenedFiles);
    }
  }, [qualificationDetails]);

  const base64ToFile = (base64, fileName, contentType) => {
    const byteCharacters = atob(base64);
    const byteNumbers = new Uint8Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const blob = new Blob([byteNumbers], { type: contentType });
    return new File([blob], fileName, { type: contentType });
  };

  const onClickSubmit = async (values) => {
    setIsLoading(true);
    // Check if the qualificationDetails array is empty or fields are incomplete
    if (!Array.isArray(values.qualificationDetails) || values.qualificationDetails.length === 0) {
      console.error("No qualification details provided.");
      setSnackBarMessage(t("Something went wrong. We are having trouble completing your request, please try again"));
      setSnackBarSeverity("error");
      setSnackBarOpen(true);
      setIsLoading(false);
      return;
    }

    const processedQualificationData = values.qualificationDetails.map(
      ({ attachment, ...rest }) => rest
    );
    const imagesData = values.qualificationDetails.map((mapdata) => {
      if (mapdata.hasOwnProperty("uniqueRecordId") && mapdata.attachment?.hasOwnProperty("InputStream")) {
        const base64Image = mapdata.attachment.InputStream._buffer;
        const fileName = mapdata.attachment.FileName;
        const contentType = mapdata.attachment.ContentType;
        const imageFile = base64ToFile(base64Image, fileName, contentType);
        return imageFile;
      } else {
        return mapdata.attachment;
      }
    });

    // console.log("Processed Qualification Data:", processedQualificationData);
    // console.log("Images Data:", imagesData);

    // Dispatch the updateFormData action to store qualification details in Redux
    dispatch(
      updateFormData({ step: activeStep, data: processedQualificationData })
    );

    try {
      const result = await dispatch(
        submitFormData({
          qualificationDetails: processedQualificationData,
          selectedFile: imagesData,
          activeStep,
          parentId,
        }),
        // console.log("submit form data:", parentId)
      );

      if (result.meta.requestStatus === "fulfilled") {

        setSnackBarMessage("Form submitted successfully");
        setSnackBarSeverity("success");
        dispatch(setActiveStep(activeStep + 1));
        // console.log("Form submitted successfully");
        setIsLoading(false);
      } else {
        throw new Error(
          result.payload ||
            result.error.message ||
            t(
              "Something went wrong. We are having trouble completing your request, please try again"
            )
        );
        // setIsLoading(false);
      }
    } catch (error) {
      // setIsLoading(false);
      // console.error("Submission error:", error);
      setSnackBarMessage(
        t(
          "Something went wrong. We are having trouble completing your request, please try again"
        )
      );
      setSnackBarSeverity("error");
    } finally {
      setIsLoading(false); // Stop loading state in both success and error cases
      setSnackBarOpen(true); // Open Snackbar with the message
    }
  };
const isArabic = i18n.language === "ar";
  return (
    <>
     <Snackbar
        open={snackBarOpen}
        autoHideDuration={6000} // Duration in milliseconds
        onClose={() => setSnackBarOpen(false)}
        anchorOrigin={{
          vertical: "top",
          horizontal: isArabic ? "left" : "right",
        }} // Positioning
        style={{ top: "100px" }} // Adjust top positioning as needed
        // className={isArabic ? "rtl-snackbar" : "ltr-snackbar"}
      >
        <Alert
          onClose={() => setSnackBarOpen(false)}
          severity={snackBarSeverity}
          sx={{
            width: "auto",
            direction: isArabic ? "rtl" : "ltr", // Use a variable to determine language
            textAlign: isArabic ? "left" : "right", // Align text based on language
            margin: "16px", // Optional margin to keep spacing
            // top: "200px !important",
          }}
        >
          {snackBarMessage}
        </Alert>
      </Snackbar>
    <Formik
      initialValues={initialValues}
      validationSchema={getQualificationDetailsValidation(t)}
      onSubmit={onClickSubmit}
      enableReinitialize
    >
      {({ values, errors, touched, setFieldValue, setFieldTouched }) => {
        const handleFileUpload = (file, index) => {
          const updatedFiles = [...qualificationFiles];
          updatedFiles[index] = file || "";
          setQualificationFiles(updatedFiles);
          setFieldValue(`qualificationDetails[${index}].attachment`, file);
        };
        // console.log("academicSpecialityOptions", academicSpecialityOptions);
        const loaderContainerStyle = {
          display: "flex",
          justifyContent: "center", // Centers horizontally
          alignItems: "center", // Centers vertically
          //height: '100vh'            // Full viewport height
        };

        const loaderStyle = {
          border: "8px solid #f3f3f3", // Light grey
          borderTop: "8px solid #3498db", // Blue
          borderRadius: "50%",
          width: "60px",
          height: "60px",
          animation: "spin 2s linear infinite",
        };

        const spinKeyframes = `@keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }`;

        if (isLoading) {
          return (
            <div style={loaderContainerStyle}>
              <style>{spinKeyframes}</style> {/* Add the spin animation */}
              <div style={loaderStyle}></div> {/* Custom loader */}
            </div>
          );
        }
        return (
          <>
            <Form>
              <Field
                name="EducationLevel"
                label={t("Education Level")}
                containerClassName={styles.Educationdropdown}
                component={Dropdown}
                options={qualificationDetailsOptions.educationLevelOptionsOnboarding}
                value={values.EducationLevel || ""}
                helperText={touched.EducationLevel && errors.EducationLevel}
                className={classNames(styles.dropdownInput, {
                  [styles.error]:
                    touched.EducationLevel && Boolean(errors.EducationLevel),
                })}
                onChange={(e) =>
                  handleEducationLevelChange(e.target.value, setFieldValue)
                }
                required
                disabled={getCrmReferenceID() ? true : false}
              />

              {showFields && Array.isArray(values.qualificationDetails) && (
                <FieldArray name="qualificationDetails">
                  {({ push, remove }) => (
                    <>
                      {Array.isArray(values.qualificationDetails) && values.qualificationDetails.map((field, index) => (
                        <React.Fragment key={index}>
                          <Grid
                            className={styles.container}
                            container
                            direction="row"
                            justifyContent="flex-start"
                          >
                            <Field
                              name={`qualificationDetails[${index}].Degree`}
                              label={t("Please Select Degree Name")}
                              containerClassName={`${styles.dropdown}`}
                              component={Dropdown}
                              onChange={(e) =>
                                handleFieldChange(
                                  index,
                                  "Degree",
                                  e.target.value,
                                  setFieldValue
                                )
                              }
                              helperText={
                                touched.qualificationDetails?.[index]?.Degree &&
                                errors.qualificationDetails?.[index]?.Degree
                              }
                              className={classNames(styles.dropdownInput, {
                                [styles.error]:
                                  touched.qualificationDetails?.[index]
                                    ?.Degree &&
                                  Boolean(
                                    errors.qualificationDetails?.[index]?.Degree
                                  ),
                              })}
                              options={
                                // qualificationDetailsOptions.degreeOptions
                                Bindings?.[educationLevel]?.[index + 1] || []
                              }
                              value={field.Degree}
                              onBlur={() =>
                                setFieldTouched(
                                  `qualificationDetails[${index}].Degree`,
                                  true
                                )
                              } // Trigger validation on blur
                              onFocus={() => {
                                setFieldTouched(
                                  `qualificationDetails[${index}].Degree`,
                                  true
                                ); // Mark field as touched on focus
                              }}
                            />
                            <Field
                              name={`qualificationDetails[${index}].School`}
                              placeholder="School"
                              value={field.School}
                              onChange={(e) =>
                                handleFieldChange(
                                  index,
                                  "School",
                                  e.target.value,
                                  setFieldValue
                                )
                              }
                              containerClass={`${styles.inputField}`}
                              helperText={
                                touched.qualificationDetails?.[index]?.School &&
                                errors.qualificationDetails?.[index]?.School
                              }
                              className={classNames({
                                [styles.error]:
                                  touched.qualificationDetails?.[index]
                                    ?.School &&
                                  Boolean(
                                    errors.qualificationDetails?.[index]?.School
                                  ),
                              })}
                              component={InputField}
                              onBlur={() =>
                                setFieldTouched(
                                  `qualificationDetails[${index}].School`,
                                  true
                                )
                              } // Trigger validation on blur
                              onFocus={() => {
                                setFieldTouched(
                                  `qualificationDetails[${index}].School`,
                                  true
                                ); // Mark field as touched on focus
                              }}
                            />
                            <Field
                              name={`qualificationDetails[${index}].Speciality`}
                              placeholder={t("Speciality/Major")}
                              containerClass={`${styles.inputField}`}
                              // options={academicSpecialityOptions}
                              options={academicSpecialityOptions.map((option) => ({
                                label: dir === "rtl" ? option.labelAR : option.labelEN, // Conditional rendering based on language direction
                                value: option.value,
                              }))}
                              component={SearchableDropdownOB}
                              className={classNames({
                                [styles.error]:
                                  touched.qualificationDetails?.[index]
                                    ?.Speciality &&
                                  Boolean(
                                    errors.qualificationDetails?.[index]
                                      ?.Speciality
                                  ),
                              })}
                              helperText={
                                touched.qualificationDetails?.[index]
                                  ?.Speciality &&
                                errors.qualificationDetails?.[index]?.Speciality
                              }
                              onChange={(_, value) => {
                                // console.log("Dropdown value changed:", value);
                                const selectedKey = value?.value;
                                // console.log(
                                //   "Setting Formik field 'Specialty' to key:",
                                //   selectedKey
                                // );
                                setFieldValue(
                                  `qualificationDetails[${index}].Speciality`,
                                  selectedKey
                                );
                              }}
                              value={
                                academicSpecialityOptions.find(
                                  (option) => option.value === field.Speciality
                                )
                                  ? {
                                      label: dir === "rtl"
                                        ? academicSpecialityOptions.find(
                                            (option) => option.value === field.Speciality
                                          ).labelAR
                                        : academicSpecialityOptions.find(
                                            (option) => option.value === field.Speciality
                                          ).labelEN,
                                      value: field.Speciality,
                                    }
                                  : { label: "", value: "" } // Default if no match is found
                              }
                              onBlur={() =>
                                setFieldTouched(
                                  `qualificationDetails[${index}].Speciality`,
                                  true
                                )
                              } // Trigger validation on blur
                              onFocus={() => {
                                setFieldTouched(
                                  `qualificationDetails[${index}].Speciality`,
                                  true
                                ); // Mark field as touched on focus
                              }}
                            />

                            <Field
                              name={`qualificationDetails[${index}].Status`}
                              label={t("Status")}
                              containerClassName={`${styles.dropdown}`}
                              component={Dropdown}
                              onChange={(e) =>
                                handleFieldChange(
                                  index,
                                  "Status",
                                  e.target.value,
                                  setFieldValue
                                )
                              }
                              helperText={
                                touched.qualificationDetails?.[index]?.Status &&
                                errors.qualificationDetails?.[index]?.Status
                              }
                              className={classNames(styles.dropdownInput, {
                                [styles.error]:
                                  touched.qualificationDetails?.[index]
                                    ?.Status &&
                                  Boolean(
                                    errors.qualificationDetails?.[index]?.Status
                                  ),
                              })}
                              options={qualificationDetailsOptions.StatusOnbaording}
                              value={field.Status}
                              onBlur={() =>
                                setFieldTouched(
                                  `qualificationDetails[${index}].Status`,
                                  true
                                )
                              } // Trigger validation on blur
                              onFocus={() => {
                                setFieldTouched(
                                  `qualificationDetails[${index}].Status`,
                                  true
                                ); // Mark field as touched on focus
                              }}
                            />
                            <Field
                              name={`qualificationDetails[${index}].StartDate`}
                              placeholder="Start Date"
                              component={CustomDatePicker}
                              value={
                                field.StartDate
                                  ? new Date(field.StartDate)
                                  : null
                              } // Convert string to Date for the picker
                              onChange={(date) => {
                                // Convert date to ISO string if it's a valid date
                                const isoDate = date instanceof Date && !isNaN(date) ? date.toISOString() : ""; // Use toISOString for serialization
                                setFieldValue(
                                  `qualificationDetails[${index}].StartDate`,
                                  isoDate
                                );
                              }}
                              helperText={
                                touched.qualificationDetails?.[index]
                                  ?.StartDate &&
                                errors.qualificationDetails?.[index]?.StartDate
                              }
                              className={classNames(styles.inputField, {
                                [styles.error]:
                                  touched.qualificationDetails?.[index]
                                    ?.StartDate &&
                                  Boolean(
                                    errors.qualificationDetails?.[index]
                                      ?.StartDate
                                  ),
                              })}
                              onBlur={() =>
                                setFieldTouched(
                                  `qualificationDetails[${index}].StartDate`,
                                  true
                                )
                              } // Trigger validation on blur
                              onFocus={() => {
                                setFieldTouched(
                                  `qualificationDetails[${index}].StartDate`,
                                  true
                                ); // Mark field as touched on focus
                              }}
                            />
                            <Field
                              name={`qualificationDetails[${index}].EndDate`}
                              placeholder="End Date"
                              component={CustomDatePicker}
                              value={
                                field.EndDate ? new Date(field.EndDate) : null
                              } // Convert string to Date for the picker
                              onChange={(date) => {
                                // Convert date to ISO string if it's a valid date
                                const isoDate = date
                                  ? formatDate(date)
                                  : null; // Use toISOString for serialization
                                setFieldValue(
                                  `qualificationDetails[${index}].EndDate`,
                                  isoDate
                                );
                              }}
                              helperText={
                                touched.qualificationDetails?.[index]
                                  ?.EndDate &&
                                errors.qualificationDetails?.[index]?.EndDate
                              }
                              className={classNames(styles.inputField, {
                                [styles.error]:
                                  touched.qualificationDetails?.[index]
                                    ?.EndDate &&
                                  Boolean(
                                    errors.qualificationDetails?.[index]
                                      ?.EndDate
                                  ),
                              })}
                              onBlur={() =>
                                setFieldTouched(
                                  `qualificationDetails[${index}].EndDate`,
                                  true
                                )
                              } // Trigger validation on blur
                              onFocus={() => {
                                setFieldTouched(
                                  `qualificationDetails[${index}].EndDate`,
                                  true
                                ); // Mark field as touched on focus
                              }}
                            />
                            <Grid item xs={12} sm={12} md={12}>
                              <FileUploader
                                headingText={`Upload Qualification Detail Copy`}
                                helperText="Or Drag And Drop Qualification Details Attachment Here"
                                state={qualificationFiles[index] || ""}
                                setState={(file) => handleFileUpload(file, index)}
                                name={`qualificationDetails[${index}].attachment`}
                                error={
                                  touched?.qualificationDetails?.[index]
                                    ?.attachment &&
                                  Boolean(
                                    errors?.qualificationDetails?.[index]
                                      ?.attachment
                                  )
                                }
                              />

                              {/* Error message display */}
                              {touched.qualificationDetails?.[index]
                                ?.attachment && (
                                  <div
                                    style={{
                                      color: "red",
                                      fontSize: "0.575rem",
                                      marginTop: "-0.25rem",
                                    }}
                                  >
                                    {
                                      errors.qualificationDetails?.[index]
                                        ?.attachment
                                    }
                                  </div>
                                )}
                            </Grid>

                          </Grid>
                          {index < values.qualificationDetails.length - 1 && (
                            <Divider
                              style={{ margin: "10px 0" }}
                              className={styles.divider}
                            />
                          )}
                        </React.Fragment>
                      ))}
                    </>
                  )}
                </FieldArray>
              )}

              <Divider style={{ margin: "20px 0" }} />
              <ActionButtons activeStep={activeStep} />
            </Form>
          </>
        );
      }}
    </Formik>
    </>
  );
};

export default ScientificQualificationDetails;
