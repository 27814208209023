import { makeStyles } from "@material-ui/core";

export default makeStyles(() => ({
  footer: {
    textAlign: "center",
    marginTop: "auto",
    width: "100%",
  },
  footerText: {
    marginTop: "8px",
    marginBottom: "8px",
    fontSize: "0.875rem",
  },
}));
