import { makeStyles } from "@material-ui/core";
import search from "../../assets/images/icon-search.svg";

export default makeStyles(() => ({
    containerClass: {
        width: "300px",
        height: "35px",
    },
    input: {
        margin: "0 !important",
        padding: "5px 10px",
        height: "35px",
        width: "100%",
        border: "1px solid #005F74",
        transition: "all ease-in-out 0.4s",
        backgroundColor: "white",
        borderRadius: "4px",
    },
    dropdown: {
        marginTop: "5px",
        maxHeight: "300px",
        height: "fit-content",
        borderRadius: "5px",
        boxShadow: "0 4px 24px 0 rgba(24, 30, 54, 0.04)",
    },
    dropdown_label: {
        top: "7px ",
        padding: "0px 15px !important",
        zIndex: "100",
        opacity: "0.7",
        fontFamily: '"Roboto",  sans-serif',
        fontSize: "17px",
        color: "#3f3e45",
    },
    focus_dropdown: {
        opacity: "0 ",
    },
    no_animation: {
        transition: "none",
    },
    dropdownIcon: {
        width: "30px",
        backgroundSize: "17px 17px",
        backgroundImage: `url(${search})`,
        backgroundRepeat: "no-repeat",
        top: "calc(35%)",
        color: "rgba(0, 0, 0, 0.54)",
        right: "1px",
        position: "absolute",
        cursor: 'pointer'
    },
    // dropdownIconOpen: {
    //     width: "30px",
    //     backgroundSize: "13px 10px",
    //     backgroundImage: `url(${arrow})`,
    //     backgroundRepeat: "no-repeat",
    //     bottom: "calc(40%)",
    //     color: "rgba(0, 0, 0, 0.54)",
    //     right: "10%",
    //     position: "absolute",
    //     transform: "rotate(180deg)",
    //     // marginTop: "-3px"
    // },
    auto_complete_popup: {
        maxHeight: "295px"
    },
    auto_complete_paper: {
        margin: 0
    },
    input_container: {
        display: "flex",
        flexDirection: "column"
    },
    label: {
        display: "inline-block",
        fontFamily: '"Roboto",  sans-serif',
        fontSize: "17px",
        color: "#3f3e45",
    },
    cursor_default: {
        cursor: 'default'
    }
}));
