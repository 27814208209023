import * as yup from "yup";

export const getPersonalInfoValidation = (t) => yup.object().shape({
    FirstName: yup
        .string()
        .required(t('First Name is required')),
    SecondName: yup
        .string()
        .required(t('Second Name is required')),
    ThirdName: yup
        .string()
        .required(t('Third Name is required')),
    FamilyName: yup
        .string()
        .required(t('Family Name is required')),
    MobileNumber: yup
        .string()
        .min(13, t("Please enter complete Number"))
        .required(t('Mobile Number is required')),
    IsResidentNational: yup
        .boolean()
        .required(t('Please select an item in the list')),
    Email: yup
        .string()
        .email(t('Enter a valid email'))
        .required(t('Email is required')),
    Password: yup
        .mixed()
        .when("PasswordRequired", {
            is: true,
            then: yup.string().min(6, t("Password must be of minimum 6 characters")).required(t("Password is required"))
        }),
    Gender: yup
        .number()
        .required(t('Please select an item in the list')),
    DateOfBirth: yup
        .date()
        .max(new Date(), t("Date of birth cannot be later than today."))
        .required(t('Please select Date of Birth')),
    PlaceofBirth: yup
        .string()
        .required(t('Place of Birth is required')),
    PeopleofDetermination: yup
        .boolean()
        .required(t('Please select an item in the list')),
    CurrentAddress: yup
        .string()
        .required(t('Current Address is required')),
    City: yup
        .string()
        .required(t('City is required')),
    PreferredCityToWork: yup
        .string()
        .required(t('Please select an item in the list')),
    NationalService: yup
        .number()
        .when(["Gender", "Nationality"], {
            is: (Gender, Nationality) => Gender === 1 && Nationality === '191a9712-2c24-ec11-b820-005056b3b9cd',
            then: yup.number().required(t('Please select an item in the list'))
        }),
    Languages: yup
        .string()
        .required(t('Language is required')),
    EmiratesID: yup
        .mixed().when("IsResidentNational", {
            is: true, then: yup.string().min(18, t("Please enter complete Emirate ID")).required(t('Emirate ID  is required'))
        }),
    PassportNumber: yup
        .mixed()
        .when("IsResidentNational", {
            is: false,
            then: yup.string().required(t('Passport Number  is required'))
        }),
    PassportIssueDate: yup
        .date()
        .when("IsResidentNational", {
            is: false,
            then: yup.date().typeError(t('Please select Passport Issue Date')).max(new Date(), t("Passport issue date cannot be later than today."))
        }),
    PassportExpiryDate: yup
        .date()
        .when("IsResidentNational", {
            is: false,
            then: yup.date().typeError(t("Please select Passport Expiry Date")).min(
                yup.ref('PassportIssueDate'),
                ({
                    min
                }) => t(`Passport expiry date cannot be earlier than issue date.`),
            )
        }),
    IssueLocation: yup
        .string()
        .when("IsResidentNational", {
            is: false,
            then: yup.string().required(t('Please select an item in the list'))
        }),
    DisabilityDetail: yup
        .mixed()
        .when("PeopleofDetermination", {
            is: true,
            then: yup.string().required(t('Disability Detail is required'))

        }),
    Emirate: yup
        .string()
        .when("IsResidentNational", {
            is: true,
            then: yup.string().required(t('Please select an item in the list'))
        }),
    Reason: yup
        .mixed()
        .when(["NationalService", "Gender"], {
            is: (NationalService, Gender) => Gender !== 2 && NationalService === 0,
            then: yup.mixed().required(t('Reason is required'))
        }),
    NationalServiceDate: yup
        .string()
        .when(["NationalService", "Gender"], {
            is: (NationalService, Gender) => Gender !== 2 && NationalService === 1,
            then: yup.string().required(t('Please select National Service Date'))
        }),
    ExpectedPerformNationalService: yup
        .string()
        .when(["NationalService", "Gender"], {
            is: (NationalService, Gender) => Gender !== 2 && NationalService === 2,
            then: yup.string().required(t('Please select Expected Date'))
        }),
    Nationality: yup
        .string()
        .required(t('Please select an item in the list')),
    FreshGraduate: yup
        .boolean()
        .required(t('Please select an item in the list')),
    //TawteenID: yup
     //   .mixed()
       // .when("FreshGraduate", {
       //     is: true,
        //    then: yup.string().required(t('Tawteen ID is required'))
       // }),
});

export const initialValues = {
    FirstName: "",
    SecondName: "",
    ThirdName: "",
    FamilyName: "",
    MobileNumber: "",
    SocialStatus: "",
    IsResidentNational: "",
    EmiratesID: "",
    PassportNumber: "",
    PassportExpiryDate: "",
    PassportIssueDate: "",
    FreshGraduate: "",
    TawteenID: "",
    Email: "",
    Password: "",
    Gender: "",
    DateOfBirth: "",
    PlaceofBirth: "",
    PeopleofDetermination: "",
    DisabilityDetail: "",
    CurrentAddress: "",
    Emirate: "",
    City: "",
    PreferredCityToWork: "",
    NationalService: "",
    NationalServiceDate: "",
    Nationality: "",
    Reason: "",
    ExpectedPerformNationalService: "",
    Languages: "",
    IssueLocation: "",
}