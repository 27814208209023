import { makeStyles } from "@material-ui/core";

export default makeStyles(() => ({
  container: {
    padding: "10px 0",
    "@media (max-width: 770px)": {
      padding: "0",
    },
  },
  inputField: {
    width: "31.5%",
    minWidth: "110px !important",
    height: "35px",
    margin: "10px 0.9%",
    "@media (max-width: 770px)": {
      width: "100%",
    },
    "& input": {
      fontSize: "0.72em",
    },
  },
  dropdown: {
    width: "31.5%",
    minWidth: "110px !important",
    margin: "10px 0.9% 0 0.9%",
    "& label": {
      top: "-12px !important",
      fontSize: "0.72em",
    },
    "@media (max-width: 770px)": {
      width: "100%",
    },
  },
  dropdownInput: {
    height: "21px !important",
    padding: "6px 15px !important",
    fontSize: "0.72em"
  },
  divider: {
    margin: "20px 0 !important",
  },
  error: {
    border: "1px solid #f44336 !important",
    backgroundColor: " rgb(255 190 190 / 50%)"
  },
}));
