import { makeStyles } from "@material-ui/core";
import arrow from "../../assets/images/select-arrow-icon.svg";

export default makeStyles(() => ({
  wide: {
    width: "100%",
    minWidth: "100px",
  },
  input: {
    borderRadius: "4px !important",
    height: "34px !important",
    border: "1px solid #005F74 !important",
    outline: "none",
    backgroundColor: "white ",
    padding: "14px 0 0 15px !important",
  },
  dropdown: {
    marginTop: "5px",
    maxHeight: "300px",
    height: "fit-content",
    borderRadius: "5px",
  },
  dropdown_label: {
    top: "7px ",
    padding: "0px 15px !important",
    zIndex: "100",
    opacity: "0.7",
    fontFamily: '"Roboto",  sans-serif',
    fontSize: "17px",
    color: "#3f3e45",
  },
  focus_dropdown: {
    opacity: "0 ",
  },
  no_animation: {
    transition: "none",
  },
  menu_items: {
    whiteSpace: "pre-line",
    fontSize: "0.72em"
  },
  formControl: {
    marginTop: "0px !important",
  },
  dropdownIcon: {
    width: "30px",
    backgroundSize: "13px 10px",
    backgroundImage: `url(${arrow})`,
    backgroundRepeat: "no-repeat",
    top: "calc(30%)",
    color: "rgba(0, 0, 0, 0.54)",
    right: "1px",
    position: "absolute",
    pointerEvents: "none",
  },
  disabled: {
    opacity: 0.5,
  }
}));
