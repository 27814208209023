import React from "react";
import {
  FormControl,
  FormHelperText,
  Input,
  InputAdornment,
} from "@material-ui/core";
import useStyle from "./InputField.styles";
import { useTranslation } from "react-i18next";

const InputField = ({
  field,
  label,
  className,
  helperText,
  containerClass,
  startAdornment,
  endAdornment,
  placeholder,
  ...props
}) => {
  const { t } = useTranslation();
  const styles = useStyle();

  return (
    <FormControl className={`${styles.containerClass} ${containerClass}`}>
      <label className={styles.label}>{t(label)}</label>
      <Input
        {...field}
        {...props}
        inputProps={{ maxLength: 200 }}
        classes={{
          root: `${styles.inputField} ${className}`,
        }}
        disableUnderline
        placeholder={t(placeholder)}
        startAdornment={
          <InputAdornment position="start">{startAdornment}</InputAdornment>
        }
        endAdornment={
          <InputAdornment position="end">{endAdornment}</InputAdornment>
        }
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default InputField;
