import React, { useEffect, useState } from "react";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import useStyle from "./CustomDatePicker.styles";
import { EventNoteOutlined } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { arSA, enUS } from "date-fns/locale";
import './CustomDatePicker.css';

const CustomDatePicker = ({
  className,
  value = null,
  placeholder = "Select Date",
  onChange,
  // field,
  ...props
}) => {
  const styles = useStyle();
  const { t, i18n } = useTranslation();
  const [selectedDate, setSelectedDate] = useState(null);
  const [locale, setLocale] = useState(i18n.language === "ar" ? arSA : enUS);
  const [isOpen, setIsOpen] = useState(false);

  const onChangeDate = (date) => {
    setSelectedDate(date);
  };

  useEffect(() => {
    i18n.language === "ar" ? setLocale(arSA) : setLocale(enUS)
  }, [i18n.language]);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
      <KeyboardDatePicker
        className={
          className ? `${styles.inputField} ${className}` : styles.inputField
        }
        inputVariant="outlined"
        variant="inline"
        PopoverProps={{
          classes: { root: styles.paperRoot, paper: styles.paper },
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "center",
          },
        }}
        open={isOpen}
        value={value || selectedDate}
        placeholder={t(placeholder)}
        autoOk={true}
        onChange={(date, _value) => {
          typeof onChange === "function" ? onChange(date) : onChangeDate(date);
        }}
        onClick={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        format="MM/dd/yyyy"
        views={["year", "month", "date"]}
        openTo="date"
        keyboardIcon={
          <EventNoteOutlined className={styles.icon} />
        }
        {...props}
      />
    </MuiPickersUtilsProvider>
  );
};

export default CustomDatePicker;
