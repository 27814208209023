import React from 'react';
import { AppBar, Toolbar, IconButton, Typography } from '@material-ui/core';
import {
  Menu as MenuIcon,
  // Person as AccountIcon,
  ArrowBack as ArrowBackIcon,
} from '@material-ui/icons';
import classNames from 'classnames';
import LanguageSelect from "../../LanguageSelect/LanguageSelect";
// styles
import useStyles from './styles';
import './header.css';

//redux
import { useSelector, useDispatch } from 'react-redux';
import { generalReducerActions } from '../../../store/reducers/generalReducer';
import { useTranslation } from 'react-i18next';
import logo from '../../../assets/images/dmt-logo-career.svg';
import HeaderLinks from '../HeaderLinks/HeaderLinks';

const Header = () => {
  const { isSidebarOpened } = useSelector((state) => state.generalStates);
  const dispatch = useDispatch();
  const styles = useStyles();
  const { t } = useTranslation();

  return (
    <AppBar position="static" className={styles.appBar}>
      <Toolbar className={styles.toolbar}>
        <div className="title">
          {/* <IconButton
            color="inherit"
            onClick={() => dispatch(generalReducerActions.toggleSidebar(!isSidebarOpened))}
            className={classNames(
              styles.headerMenuButton,
              styles.headerMenuButtonCollapse,
              'menu-toggle'
            )}
          >
            {isSidebarOpened ? (
              <ArrowBackIcon
                styles={{
                  root: classNames(
                    styles.headerIcon,
                    styles.headerIconCollapse
                  ),
                }}
              />
            ) : (
              <MenuIcon
                styles={{
                  root: classNames(
                    styles.headerIcon,
                    styles.headerIconCollapse
                  ),
                }}
              />
            )}
          </IconButton> */}
          <Typography variant="h6" weight="medium" className={styles.logotype}>
            {t("DMT CAREER PORTAL SHORT")}
          </Typography>
        </div>
        <HeaderLinks />
        <LanguageSelect />
        <div className="logo">
          <img src={logo} title="dmt logo" alt="" />
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default React.memo(Header);
