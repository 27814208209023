import axios from "axios";
import { successResponseHandler, errorResponseHandler } from "./interceptor";

const contentType = {
  json: "application/json",
  multipart: "multipart/form-data",
};
//const API_BASE_URL = "https://jobsstg.dmt.gov.ae/Middleware";
const API_BASE_URL = "https://jobs.dmt.gov.ae/Middleware";
//const API_BASE_URL = "https://innovationmonth.dmt.gov.ae/Middleware";
export default class API {
  constructor(
    config = {
      headers: { contentType: contentType.json },
    }
  ) {
    this.config = {};
    this.instance = null;
    const headers = {
      "Content-Type": config.headers.contentType || contentType.json,
    };

    this.config = {
      baseURL: config.baseURL || API_BASE_URL,
      headers: headers,
      maxContentLength: Infinity,
      maxBodyLength: Infinity
    };

    this.instance = axios.create(this.config);
    this.instance.interceptors.response.use(
      successResponseHandler,
      errorResponseHandler
    );
    this.instance.interceptors.request.use(req => {
      !!window.localStorage.getItem("LoginId") ?
        req.headers = { ...req.headers, apikey: window.localStorage.getItem("apikey"), RegistrationId: window.localStorage.getItem("LoginId") }
        :
        req.headers = { ...req.headers, apikey: "d13ed23c-02e5-4152-9aee-1b5329d7fdca", }
      return req;
    });
  }

  get(url, id, params) {
    let endpoint = url;
    if (id) {
      endpoint += `/${id}`;
    }
    return this.instance.get(endpoint, { params });
  }

  post(url, body) {
    // console.log('expected formData: ', body)
    // console.log('isFormData: ', body instanceof FormData)
    const headers = body instanceof FormData ? {"content-type": "multipart/form-data", "Content-Type": "multipart/form-data", "testKey": "123test"} : { 'Content-Type': contentType.json };
    return this.instance.post(url, body, { headers });
    //return this.instance.post(url, body);
  }

  delete(url, id) {
    return this.instance.delete(`${url}/${id}`);
  }

  put(url, body, id) {
    let endpoint = url;
    if (id) {
      endpoint += `/${id}`;
    }
    return this.instance.put(endpoint, body);
  }

  patch(url, body) {
    return this.instance.patch(url, body);
  }
}
