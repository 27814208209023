import { Grid, Modal, Fade, Backdrop, Button } from "@material-ui/core";
import { ArrowForward, Clear } from "@material-ui/icons";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import DropDown from "../../components/DropDown/DropDown";
import Header from "../../components/Header/Header";
import InputField from "../../components/InputField/InputField";
import JobCard from "../../components/JobCard/JobCard";
import StyledButton from "../../components/StyledButton/StyledButton";
import {
  getFeaturedCategories,
  getFeaturedJobs,
} from "../../store/reducers/jobDataReducer";
import useStyle from "./HomePage.style";
import { sliderSettings } from "./utility";
import './homepage.css';
import { generalReducerActions } from "../../store/reducers/generalReducer";
import bg from "../../assets/images/Home page/HR_2_1366X255-13.png";
import { UpdateIsNafisPopupShown } from "../../store/reducers/registrationReducer";

const HomePage = () => {
  const styles = useStyle();
  const { t, i18n } = useTranslation();
  const { featuredJobs, featuredCategories } = useSelector(
    (state) => state.jobData
  );
  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState("");
  const [searchCategory, setSearchCategory] = useState("");
  const [clear, setClear] = useState("");
  const [allJobs, setAllJobs] = useState(false);
  const [open, setOpen] = useState(false);
  const [searchIsTrue, setIsSearchTrue] = useState(true);
  const handleClose = () => {
    window.location.replace('https://nafis.gov.ae');
    setOpen(false)
  };

  const handleNafisPopupYes = async () => {
    setOpen(false);

  };

  // on change of search text 
  const handleSetSearchTxt = (event) => {
    setSearchText(event.target.value);
    if (event.target.value !== 'undefined' && event.target.value !== '') {
      setIsSearchTrue(false);
   } 
   else {
      if(searchCategory === ''){
        setIsSearchTrue(true);
      }
     else{
        setIsSearchTrue(false);
      }
    }
  }

  // on change of option of search
  const handleSetOptionValue = (event) => {
    setSearchCategory(event.target.value);
    if (event.target.value !== 'undefined' && event.target.value !== '') {
      setIsSearchTrue(false);
    } else {
      setIsSearchTrue(true);
    }
  }

  const searchSubmitHandler = async (event) => {
    event.preventDefault();

    dispatch(generalReducerActions.toggleLoading(true))
    let data =
      i18n.language === "ar"
        ? {
          JobNameAr: searchText,
          IsFeaturedJob: false,
          JobCategoryId: searchCategory,
        }
        : {
          JobName: searchText,
          IsFeaturedJob: false,
          JobCategoryId: searchCategory,
        };
    const response = await dispatch(getFeaturedJobs(data));
    if (response.payload.status === 200) {
      dispatch(generalReducerActions.toggleLoading(false));
      setClear(true)
      allJobs && setAllJobs(false)
    }
  };


  const searchClear = async () => {
    setAllJobs(false);
    setIsSearchTrue(true);
    dispatch(generalReducerActions.toggleLoading(true))
    const response = await dispatch(getFeaturedJobs({ IsFeaturedJob: true }));
    if (response.payload.status === 200) {
      if(response.payload?.data == "No Record Found.")
      {
        setAllJobs(true);
        await dispatch(getFeaturedJobs({ IsFeaturedJob: false }));
      }
      dispatch(generalReducerActions.toggleLoading(false));
      setSearchText("");
      setSearchCategory("");
      setClear(false)
    }
  };

  let options = useMemo(() => {
    if (i18n.language === "ar") {
      return featuredCategories.map((category) => {
        return {
          dropdownValue: category.id,
          label: category.titleAR
        }
      })
    }
    else {
      return featuredCategories.map(category => {
        return {
          dropdownValue: category.id,
          label: category.titleEN
        }
      })
    }
  }, [featuredCategories, i18n.language]);

  const onClickView = async (data) => {
    dispatch(generalReducerActions.toggleLoading(true))
    const response = await dispatch(getFeaturedJobs({ JobCategoryId: data.id }))
    if (response.payload.status === 200) {
      dispatch(generalReducerActions.toggleLoading(false))
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      })
    }
  }

  const onClickMore = async (data) => {
    setAllJobs(true);
    dispatch(generalReducerActions.toggleLoading(true))
    const response = await dispatch(getFeaturedJobs({ IsFeaturedJob: false }))
    if (response.payload.status === 200) {
      dispatch(generalReducerActions.toggleLoading(false))
      if (clear) {
        setSearchText("");
        setSearchCategory("");
        setClear(false)
      }
    }
  }

  useEffect(() => {
    async function getJobs() {      
      dispatch(generalReducerActions.toggleLoading(true))
      const response = await dispatch(getFeaturedJobs({ IsFeaturedJob: true }));
      dispatch(getFeaturedCategories());
      if (response.payload?.status === 200) {
        if(response.payload?.data == "No Record Found.")
        {
          setAllJobs(true);
          await dispatch(getFeaturedJobs({ IsFeaturedJob: false }));
        }
        dispatch(generalReducerActions.toggleLoading(false))
      } else {
        dispatch(generalReducerActions.toggleLoading(false))
      }
    }
    

    if (!!localStorage.getItem("IsPopupShown") && localStorage.getItem("IsPopupShown") == 'false') {
      localStorage.setItem("IsPopupShown", true)
      let id = localStorage.getItem('LoginId') || localStorage.getItem('RegistrationId');
      dispatch(UpdateIsNafisPopupShown(id));
      setOpen(true)      
    }
    getJobs()

  }, [dispatch]);



  useEffect(() => {
    document.title = `${t("DMT Career Portal")}`
  }, [i18n.language, t]);
  

  return (
    <>
      <Modal
        className={styles.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={styles.paper}>
            <p id="transition-modal-description">{t(`Do you have account on nafis`)}</p>
            <Grid container direction="row" justifyContent="space-between">
              <Button variant="outlined" color="primary" onClick={handleNafisPopupYes}>{t("Yes")}</Button>
              <Button variant="outlined" color="primary" onClick={handleClose}>{t("No")}</Button>
              {/* <Button variant="outlined" color="default" onClick={handleClose}>{t("Cancle")}</Button> */}
            </Grid>
          </div>
        </Fade>
      </Modal>

      <React.Fragment>
        <Header bg={bg}>
          <Grid className={styles.containerHead} container direction="column">
            <div className={styles.mainHeading}>
              <p className={styles.welcomeText}>{t("Welcome to")}</p>
              <p className={styles.infoText}>{t("DMT CAREER PORTAL")}</p>
            </div>
          </Grid>
          <form className={styles.searchBox} onSubmit={searchSubmitHandler}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <InputField
                containerClass={styles.inputFields}
                placeholder="Keyword"
                value={searchText}
                onChange={(event) => handleSetSearchTxt(event)}
                startAdornment=""
                endAdornment={""}
              />
              <DropDown
                containerClassName={styles.dropdown}
                label="Category"
                value={searchCategory}
                onChange={(event) => handleSetOptionValue(event)}
                
                options={options}
              />
              {
                clear && <Clear fontSize="medium" className={styles.searchIcon} onClick={searchClear} />
              }
              <StyledButton className={styles.searchButton} type="submit" disabled={searchIsTrue}>
                {t("Search")}
              </StyledButton>
            </Grid>
          </form>
        </Header>
        {
          featuredJobs?.length > 0 ? (
            <React.Fragment>
              <Grid
                className={styles.containerBody}
                container
                direction="column"
                alignItems="center"
              >
                <h3 className={`${styles.featureText} section-centered-heading`}>{allJobs ? t("All Jobs") : clear ? t("Searched Jobs") : t("Feature Jobs")}</h3>
                <Grid
                  className={`${styles.cardContainer} job-card-container`}
                  container
                  direction="row"
                  alignItems="center"
                >
                  {featuredJobs.map((data) => (
                    <JobCard
                      className={`${styles.jobCard} homepage-jobs`}
                      key={data.id}
                      headerText={i18n.language === "ar" ? data.titleAR : data.titleEN}
                      cardBody={i18n.language === "ar" ? data?.descriptionAR : data?.descriptionEN}
                      cardFooter={
                        <Link to={`/job-details/${data?.id}`}>
                          <em>{t("Details")}</em>{" "}
                          <span>
                            <ArrowForward className={`${styles.icon} nnext-arrow`} />
                          </span>
                        </Link>
                      }
                    />
                  ))}
                </Grid>
                {
                  !allJobs && (
                    <StyledButton
                      onClick={onClickMore}
                    >
                      {t("All")}
                    </StyledButton>
                  )
                }
              </Grid>
              <div className={styles.sliderGrid}>
                <p className={styles.featureText}>{t("Feature Categories")}</p>
                <Slider
                  className={`${styles.slider} homepage-slider`}
                  {...sliderSettings}
                >
                  {featuredCategories.map((data, index) => (
                    <div className={styles.card} key={index}>
                      <Grid container direction="column" alignItems="center">
                        <div className={`${styles.iconDiv} icon`}>
                          <span className="icon-web-n-software-dev-icon"></span>
                        </div>
                        <p className={styles.welcomeText}>
                          {i18n.language === "ar" ? data?.titleAR : data?.titleEN}
                        </p>
                        <p className={styles.badge}>
                          {data?.numberOfJobs} {t("jobs")}
                        </p>
                        <Link
                          className={`${styles.btn} ${styles.btnView} h-jc-link`}
                          to={"/"}
                          onClick={() => onClickView(data)}
                        >
                          {t("View")}{" "}
                          <span>
                            <ArrowForward className="nnext-arrow" />
                          </span>
                        </Link>
                      </Grid>
                    </div>
                  ))}
                </Slider>
              </div>
            </React.Fragment>
          ) : (
            <Grid
              className={styles.containerBody}
              container
              direction="column"
              alignItems="center"
            >
              <p className={styles.featureText}>{t("The job updates will be published soon. Please keep checking this site...")}</p>
            </Grid>
          )
        }
      </React.Fragment>

    </>
  );
};

export default HomePage;
