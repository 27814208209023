import React, { useState } from 'react';
import {
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import { Inbox as InboxIcon } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

// styles
import useStyles from './styles';

// components
import Dot from '../Dot';
import { generalReducerActions } from '../../../../../store/reducers/generalReducer';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

export default function SidebarLink({
  link,
  icon,
  label,
  children,
  location,
  isSidebarOpened,
  nested,
  type,
  onClick
}) {
  const dispatch = useDispatch();
  const styles = useStyles();
  const { t } = useTranslation();
  // local
  const [isOpen, setIsOpen] = useState(false);
  let isLinkActive =
    link &&
    (location.pathname === link);

  if (type === 'title')
    return (
      <ListItem
        button
        onClick={() => { typeof onClick === "function" && onClick(); dispatch(generalReducerActions.toggleSidebar(false)) }}
        className={styles.link}
        disableRipple
      >
        <ListItemIcon
          className={classnames(styles.linkIcon)}
        >
          {nested ? <Dot color={isLinkActive && 'primary'} /> : icon}
        </ListItemIcon>
        <ListItemText
          styles={{
            primary: classnames(styles.linkText, {
              [styles.linkTextActive]: isLinkActive,
              [styles.linkTextHidden]: !isSidebarOpened,
            }),
          }}
          primary={t(label)}
        />
      </ListItem>
    );

  if (type === 'divider') return <Divider className={styles.divider} />;

  if (!children)
    return (
      <ListItem
        button
        component={link && Link}
        onClick={() => link && dispatch(generalReducerActions.toggleSidebar(false))}
        to={link}
        className={styles.link}
        styles={{
          root: classnames(styles.linkRoot, {
            [styles.linkActive]: isLinkActive && !nested,
            [styles.linkNested]: nested,
          }),
        }}
        disableRipple
      >
        <ListItemIcon
          className={classnames(styles.linkIcon, {
            [styles.linkIconActive]: isLinkActive,
          })}
        >
          {nested ? <Dot color={isLinkActive && 'primary'} /> : icon}
        </ListItemIcon>
        <ListItemText
          styles={{
            primary: classnames(styles.linkText, {
              [styles.linkTextActive]: isLinkActive,
              [styles.linkTextHidden]: !isSidebarOpened,
            }),
          }}
          primary={t(label)}
        />
      </ListItem>
    );

  return (
    <>
      <ListItem
        button
        component={link && Link}
        onClick={toggleCollapse}
        className={styles.link}
        to={link}
        disableRipple
      >
        <ListItemIcon
          className={classnames(styles.linkIcon, {
            [styles.linkIconActive]: isLinkActive,
          })}
        >
          {icon ? icon : <InboxIcon />}
        </ListItemIcon>
        <ListItemText
          styles={{
            primary: classnames(styles.linkText, {
              [styles.linkTextActive]: isLinkActive,
              [styles.linkTextHidden]: !isSidebarOpened,
            }),
          }}
          primary={t(label)}
        />
      </ListItem>
      {children && (
        <Collapse
          in={isOpen && isSidebarOpened}
          timeout="auto"
          unmountOnExit
          className={styles.nestedList}
        >
          <List component="div" disablePadding>
            {children.map((childrenLink) => (
              <SidebarLink
                key={childrenLink && childrenLink.link}
                location={location}
                isSidebarOpened={isSidebarOpened}
                styles={styles}
                nested
                {...childrenLink}
              />
            ))}
          </List>
        </Collapse>
      )}
    </>
  );

  // ###########################################################

  function toggleCollapse(e) {
    if (isSidebarOpened) {
      e.preventDefault();
      setIsOpen(!isOpen);
    }
  }
}
