import {
  SliderArrowNext,
  SliderArrowPrev,
} from "../../components/SliderArrows/SliderArrows";
import { getLookupOptions } from "../../lookupData.options";
import { format, parseISO } from "date-fns";

const dateFormatter = (date) => {
  return format(parseISO(date), "MM/dd/yyyy");
};

const sliderSettings = {
  arrows: true,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  nextArrow: <SliderArrowNext />,
  prevArrow: <SliderArrowPrev />,
  responsive: [
    {
      breakpoint: 990,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 770,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        // initialSlide: 2,
      },
    },
    {
      breakpoint: 490,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const getJobDetailsData = (data, language, translate) => {
  let educationRequirement = (data?.educationLevel instanceof Array && data?.educationLevel?.length > 0) && data?.educationLevel?.map((education, index) =>
    language === "ar"
      ? education?.EducationNameAr && `${education?.EducationNameAr}${(index !== data?.educationLevel?.length - 1 && data?.educationLevel?.length >= 2) ? data?.educationLevel?.length === 2 ? " و " : ", " : ''}`
      : education?.EducationName && `${education?.EducationName}${(index !== data?.educationLevel?.length - 1 && data?.educationLevel?.length >= 2) ? data?.educationLevel?.length === 2 ? " and " : ", " : ''}`
  );
  educationRequirement = (educationRequirement && educationRequirement.length > 0) && educationRequirement.join('');
  let academicSpecialtyRequirment = (data?.academicSpecialty instanceof Array && data?.academicSpecialty?.length > 0) && data?.academicSpecialty?.map((specialty, index) =>
    language === "ar"
      ? specialty?.DetailAr && `${specialty?.DetailAr}${(index !== data?.academicSpecialty?.length - 1 && data?.academicSpecialty?.length >= 2) ? data?.academicSpecialty?.length === 2 ? " و " : ", " : ''}`
      : specialty?.Detail && `${specialty?.Detail}${(index !== data?.academicSpecialty?.length - 1 && data?.academicSpecialty?.length >= 2) ? data?.academicSpecialty?.length === 2 ? " and " : ", " : ''}`
  );
  academicSpecialtyRequirment = (academicSpecialtyRequirment && academicSpecialtyRequirment.length > 0) && academicSpecialtyRequirment.join('');
  let certificatesRequirment = (data?.certificates instanceof Array && data?.certificates?.length > 0) && data?.certificates?.map((certificate, index) =>
    language === "ar"
      ? certificate?.DetailAr && `${certificate?.DetailAr}${(index !== data?.certificates?.length - 1 && data?.certificates?.length >= 2) ? data?.certificates?.length === 2 ? " و " : ", " : ''}`
      : certificate?.Detail && `${certificate?.Detail}${(index !== data?.certificates?.length - 1 && data?.certificates?.length >= 2) ? data?.certificates?.length === 2 ? " and " : ", " : ''}`
  );
  certificatesRequirment = (certificatesRequirment && certificatesRequirment.length > 0) && certificatesRequirment.join('');
  return {
    overviewData: [
      {
        key: "Date Posted",
        value: data?.date_posted ? dateFormatter(data?.date_posted) : '',
        icon: <span className="icon-date-posted-icon"></span>,
      },
      {
        key: "Expiration Date",
        value: data?.expiration_date ? dateFormatter(data?.expiration_date) : '',
        icon: <span className="icon-expiration-date"></span>,
      },
      {
        key: "Required No of Vacancies",
        value: data?.numberOfCandidates ? data?.numberOfCandidates : '',
        icon: <span className="icon-number-of-candidates"></span>,
      },
      {
        key: "Hiring Entity",
        value: data?.hiringEntity ? (
          language === "ar"
            ? getLookupOptions("rtl").hiringEntityOptions.find(
              (element) => element.dropdownValue === data?.hiringEntity
            )?.label
            : getLookupOptions("ltr").hiringEntityOptions.find(
              (element) => element.dropdownValue === data?.hiringEntity
            )?.label
        ) : '',
        icon: <span className="icon-hiring-entity"></span>,
      },
      {
        key: "Work Nature",
        value: data?.workNature ? (
          language === "ar"
            ? getLookupOptions("rtl").workNatureOptions.find(
              (element) => element.dropdownValue === data?.workNature
            )?.label
            : getLookupOptions("ltr").workNatureOptions.find(
              (element) => element.dropdownValue === data?.workNature
            )?.label
        ) : '',
        icon: <span className="icon-work-nature"></span>,
      },
      {
        key: "Job Location",
        value: data?.location ? (
          language === "ar"
            ? getLookupOptions("rtl").locationOptions.find(
              (element) => element.dropdownValue === data?.location
            )?.label
            : getLookupOptions("ltr").locationOptions.find(
              (element) => element.dropdownValue === data?.location
            )?.label
        ) : '',
        icon: <span className="icon-job-location"></span>,
      },
    ],
    offerList:
      language === "ar"
        ? [
          (data?.salaryTo && data?.salaryFrom) ? (data?.salaryTo !== 0 && `${translate("Salary")} ${data?.salaryFrom} - ${data?.salaryTo}`)
            :
            data?.salaryFrom ? (data?.salaryFrom !== 0 && `${translate("Salary")} ${data?.salaryFrom}`)
              :
              data?.salaryTo ? (data?.salaryTo !== 0 && `${translate("Salary")} ${data?.salaryTo}`)
                :
                '',
          data?.contractType ? data?.contractType : '',
          `${translate(
            "An exciting job where you can assume responsibility and develop professionally."
          )}`,
          `${translate(
            "A dynamic team with friendly, highly-qualified colleagues from all over the world."
          )}`,
          `${translate(
            "Strong, sustainable growth and fresh challenges every day."
          )}`,
          `${translate("Flat hierarchies and short decision paths.")}`,
        ]
        : [
          (data?.salaryTo && data?.salaryFrom) ? (data?.salaryTo !== 0 && `Salary ${data?.salaryFrom} - ${data?.salaryTo}`)
            :
            data?.salaryFrom ? (data?.salaryFrom !== 0 && `Salary ${data?.salaryFrom}`)
              :
              data?.salaryTo ? (data?.salaryTo !== 0 && `Salary ${data?.salaryTo}`)
                :
                '',
          data?.contractType ? data?.contractType : '',
          "An exciting job where you can assume responsibility and develop professionally.",
          "A dynamic team with friendly, highly-qualified colleagues from all over the world.",
          "Strong, sustainable growth and fresh challenges every day.",
          "Flat hierarchies and short decision paths.",
        ],
    keyRequirements: [
      educationRequirement,
      academicSpecialtyRequirment,
      certificatesRequirment,
      // data?.experience ? (
      //   data?.experience > 1
      //     ? `${data?.experience} ${translate('years of experience after graduation')}`
      //     : `${data?.experience} ${translate('year of experience after graduation')}`
      // ) : '',
      (data?.minExperience && data?.minExperience > 0) ? (
        data?.minExperience === 1
          ? `${translate('minimum')} ${translate('1year')} ${translate('year of experience after graduation')}`
          :
          data?.minExperience === 2
            ? `${translate('minimum')} ${translate('2years')} ${translate('year of experience after graduation')}`
            : `${translate('minimum')} ${data?.minExperience} ${translate('years of experience after graduation')}`
      ) : '',
      (data?.maxExperience && data?.maxExperience > 0) ? (
        data?.maxExperience === 1
          ? `${translate('maximum')} ${translate('1year')} ${translate('year of experience after graduation')}`
          :
          data?.maxExperience === 2
            ? `${translate('maximum')} ${translate('2years')} ${translate('year of experience after graduation')}`
            : `${translate('maximum')} ${data?.maxExperience} ${translate('years of experience after graduation')}`
      ) : '',
      data?.gender ? (
        data?.gender !== 0 && (language === "ar"
          ? `${getLookupOptions("rtl").genderOptions.find(
            (element) => +element.dropdownValue === data?.gender
          )?.label
          }`
          : `${getLookupOptions("ltr").genderOptions.find(
            (element) => +element.dropdownValue === data?.gender
          )?.label
          }`)
      ) : '',
      (data?.otherAR || data?.other) ?
        (language === "ar" ? `${data?.otherAR}` : `${data?.other}`) : '',
    ],
  };
};

export { sliderSettings, getJobDetailsData };
