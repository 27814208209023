import React, { useEffect, useMemo, useState } from "react";
import {
	Divider,
	Grid,
	IconButton,
} from "@material-ui/core";
import useStyle from "./ResetPassword.styles";
import logo_login from "../../assets/images/logo-login.svg";
import InputField from "../../components/InputField/InputField";
import {
	Lock,
	Visibility,
	VisibilityOff,
} from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import Slider from "react-slick";
import StyledButton from "../../components/StyledButton/StyledButton";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha";
import { checkVerificationCode, sendResetPasswordRequest, submitNewPassword } from "../../store/reducers/authReducer";
import { generalReducerActions } from "../../store/reducers/generalReducer";
import classNames from "classnames";
import slide1 from "../../assets/images/Login_Page/slide1.png";
import slide2 from "../../assets/images/Login_Page/slide2.png";
import slide3 from "../../assets/images/Login_Page/slide3.png";
import { getResetPasswordValidation } from "./validation";
import { useFormik } from 'formik';

const bg = [slide1, slide2, slide3];

const ResetPassword = (props) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const styles = useStyle();
	const { t, i18n } = useTranslation();
	const [captchaChecked, setCaptchaChecked] = useState(false);
	const [index, setIndex] = useState(0);
	const [values, setValues] = useState({
		showPassword: false,
		showRetypePassword: false,
		showOldPassword: false,
	});

	const sliderText = useMemo(() => ['Attracting young talents, to develop and maintain their abilities', 'Discovering the talents and competencies of human cadres in the specialized and technical fields', 'Our vision is an emirate with integrated sustainable urban development and outstanding municipal services for the well-being and happiness of the community'], [])

	const onChangeRecaptcha = (value) => {
		if (value) {
			setCaptchaChecked(true)
		}
	}

	const onExpiredRecaptcha = () => {
		setCaptchaChecked(false)
	}
	const submitNewPasswordHandler = async () => {
		if (!captchaChecked) {
			dispatch(generalReducerActions.snackBarUpdate({
				open: true,
				message: `Invalid Captcha`,
				severity: 'warning',
			}));
		} else {
			dispatch(generalReducerActions.toggleLoading(true));
			const data = props.location.state?.resetPassword ? {
				RegistrationId: props.location.state?.RegistrationId,
				Password: formik.values.password,
			} : {
				RegistrationId: JSON.parse(localStorage.getItem("UserData")).RegistrationId,
				Password: formik.values.password,
				OldPassword: formik.values.oldPassword,
			}
			const response = await dispatch(submitNewPassword(data));
			if (response.payload.status === 200) {
				dispatch(generalReducerActions.toggleLoading(false));
				dispatch(generalReducerActions.snackBarUpdate({
					open: true,
					message: "Your password has been changed successfully",
					severity: 'success',
				}));
				!props.location.state?.resetPassword ? history.replace("/") : history.replace("/login")
			} else {
				dispatch(generalReducerActions.toggleLoading(false));
				dispatch(generalReducerActions.snackBarUpdate({
					open: true,
					message: response.payload.data.Message || "Something went wrong. We are having trouble completing your request, please try again",
					severity: 'error',
				}));
			}
		}
	};

	const onVerificationCodeSubmitHandler = async () => {
		if (!captchaChecked) {
			dispatch(generalReducerActions.snackBarUpdate({
				open: true,
				message: `Invalid Captcha`,
				severity: 'warning',
			}));
		} else {
			dispatch(generalReducerActions.toggleLoading(true));
			const response = await dispatch(checkVerificationCode(formik.values.verificationCode));
			window.grecaptcha.reset();
			if (response.payload.status === 200) {
				dispatch(generalReducerActions.toggleLoading(false));
				formik.setValues({ ...formik.values, showVerifyCode: false });
				formik.setTouched({});
			} else {
				dispatch(generalReducerActions.toggleLoading(false));
				dispatch(generalReducerActions.snackBarUpdate({
					open: true,
					message: "Invalid verfication code",
					severity: 'error',
				}));
			}
		}
	};

	const formik = useFormik({
		initialValues: {
			oldPassword: "",
			password: "",
			retypePassword: "",
			verificationCode: "",
			showVerifyCode: !props.location.state?.resetPassword || false,
			showOldPassword: !props.location.state?.resetPassword
		},
		validationSchema: getResetPasswordValidation(t),
		onSubmit: () => {
			formik.values.showVerifyCode ? onVerificationCodeSubmitHandler() : submitNewPasswordHandler()
		},
	});

	useEffect(() => {
		async function sendVerificationCode() {
			if (!props.location.state?.resetPassword && !!localStorage.getItem("UserData")) {
				let data = { RegistrationId: JSON.parse(localStorage.getItem("UserData")).RegistrationId }
				dispatch(generalReducerActions.toggleLoading(true));
				const response = await dispatch(sendResetPasswordRequest(data));
				if (response.payload.status === 200) {
					dispatch(generalReducerActions.toggleLoading(false));
					dispatch(generalReducerActions.snackBarUpdate({
						open: true,
						message: "Password recovery email sent. If the email you entered exists in our record, you will receive a verification code in your email to recover your password",
						severity: 'success',
					}));
				} else {
					dispatch(generalReducerActions.toggleLoading(false));
					dispatch(generalReducerActions.snackBarUpdate({
						open: true,
						message: "Something went wrong. We are having trouble completing your request, please try again",
						severity: 'error',
					}));
				}
			}
		}
		sendVerificationCode()
	}, [dispatch, props.location.state?.resetPassword]);

	useEffect(() => {
		document.title = `${props.location.state?.resetPassword ? t('Reset Password') : t('Change Password')} - ${t("DMT Career Portal")}`
	}, [i18n.language, t, props.location.state?.resetPassword])

	return (
		<Grid container direction="row">
			<Grid item className={`${styles.loginFormGrid} login-form-wrapper`}>
				<div className={styles.loginLogo}>
					<img src={logo_login} className={styles.logo} alt="logo" />
				</div>
				<form style={{ width: "75%" }} onSubmit={formik.handleSubmit} >
					{
						formik.values.showVerifyCode ? (
							<InputField
								containerClass={styles.inputField}
								className={classNames({ [styles.error]: formik.touched.verificationCode && Boolean(formik.errors.verificationCode) })}
								label={t("Verification Code")}
								name="verificationCode"
								value={formik.values.verificationCode}
								onChange={formik.handleChange}
								helperText={formik.touched.verificationCode && formik.errors.verificationCode}
								startAdornment={<Lock classes={{ root: styles.icon }} />}
								endAdornment=""
							/>
						) : (
							<React.Fragment>
								{formik.values.showOldPassword && (
									<InputField
										containerClass={styles.inputField}
										className={classNames({ [styles.error]: formik.touched.oldPassword && Boolean(formik.errors.oldPassword) })}
										label={t("Old Password")}
										name="oldPassword"
										value={formik.values.oldPassword}
										onChange={formik.handleChange}
										helperText={formik.touched.oldPassword && formik.errors.oldPassword}
										type={values.showOldPassword ? "text" : "password"}
										startAdornment={<Lock className={styles.icon} />}
										endAdornment={
											<IconButton onClick={() => setValues({ ...values, showOldPassword: !values.showOldPassword })}>
												{values.showOldPassword ? (
													<Visibility className={styles.iconLowOpacity} />
												) : (
													<VisibilityOff className={styles.iconLowOpacity} />
												)}
											</IconButton>
										}
									/>
								)
								}
								<InputField
									containerClass={styles.inputField}
									className={classNames({ [styles.error]: formik.touched.password && Boolean(formik.errors.password) })}
									label={t("New Password")}
									name="password"
									value={formik.values.password}
									onChange={formik.handleChange}
									helperText={formik.touched.password && formik.errors.password}
									type={values.showPassword ? "text" : "password"}
									startAdornment={<Lock className={styles.icon} />}
									endAdornment={
										<IconButton onClick={() => setValues({ ...values, showPassword: !values.showPassword })}>
											{values.showPassword ? (
												<Visibility className={styles.iconLowOpacity} />
											) : (
												<VisibilityOff className={styles.iconLowOpacity} />
											)}
										</IconButton>
									}
								/>
								<InputField
									containerClass={styles.inputField}
									className={classNames({ [styles.error]: formik.touched.retypePassword && Boolean(formik.errors.retypePassword) })}
									label={t("Re-type Password")}
									name="retypePassword"
									value={formik.values.retypePassword}
									onChange={formik.handleChange}
									helperText={formik.touched.retypePassword && formik.errors.retypePassword}
									type={values.showRetypePassword ? "text" : "password"}
									startAdornment={<Lock className={styles.icon} />}
									endAdornment={
										<IconButton onClick={() => setValues({ ...values, showRetypePassword: !values.showRetypePassword })}>
											{values.showRetypePassword ? (
												<Visibility className={styles.iconLowOpacity} />
											) : (
												<VisibilityOff className={styles.iconLowOpacity} />
											)}
										</IconButton>
									}
								/>
							</React.Fragment>
						)
					}
					<Grid className={styles.reCaptchaGrid} container direction="row" justifyContent="center" alignItems="center" >
						<ReCAPTCHA
							hl={i18n.language}
							// sitekey="6Ld08YQaAAAAAKMjEaZ9u4WMXYfywPVmmjmQUuPM"   //old key
                //sitekey="6LcM98cUAAAAAPvIMa6wiMfDySx9Hj2TDAF77H-K"      //staging site key
                sitekey="6LdbctUfAAAAAADBTvbWN6i1_FKaA2owkOmz5qa7"    //production site key
							onChange={onChangeRecaptcha}
							onExpired={onExpiredRecaptcha}
						/>
					</Grid>
					<Grid
						container
						direction="row"
						justifyContent="space-between"
						alignItems="center"
						className={styles.actionGrid}
					>
						<StyledButton className={styles.submitButton} type="submit">
							{t("Submit")}
						</StyledButton>
					</Grid>
				</form>
				<Footer className={styles.footer} />
			</Grid>
			<Grid item className={styles.backgroundGrid} style={{ backgroundImage: `url(${bg[index]})` }}>
				<p className={styles.welcomeText}>{t("Welcome to")}</p>
				<p className={styles.infoText}>{t("DMT CAREER PORTAL")}</p>
				<Divider className={styles.divider} />
				<Slider
					arrows={false}
					dots={true}
					speed={500}
					autoplay={true}
					autoplaySpeed={5000}
					dotsClass={styles.sliderDots}
					afterChange={(slide) => setIndex(slide)}
				>
					{sliderText.map((txt, index) => (
						<div key={index}>
							<h4>{t(txt)}</h4>
						</div>
					))}
				</Slider>
			</Grid>
			<Footer className="slider-footer" />
		</Grid>
	);
};

export default ResetPassword;
