import { makeStyles } from "@material-ui/core";

export default makeStyles(() => ({
  container: {
    minHeight: "auto",
    padding: "5px 0",
    margin: "20px 0",
    border: "1px solid #bebebe",
    borderRadius: "3px",
    textAlign: "center",
    cursor: "pointer",
  },
  containerError: {
    borderColor: "tomato",
  },
  headingText: {
    fontSize: "11px",
    color: "#005F74",
    marginBottom: "2px",
  },
  helperText: {
    fontSize: "11px",
    color: "#bebebe",
    marginTop: "2px",
  },
  containerImageDiv: {
    position: "relative",
    display: "inline-block",
    verticalAlign: "top",
    margin: "16px",
    minHeight: "100px",
    zIndex: "20",
    cursor: "default",
  },
  image: {
    width: "120px",
    height: "120px",
    borderRadius: "20px",
  },
  noneImageTextDiv: {
    borderRadius: "20px",
    background: "linear-gradient(to bottom, #eee, #ddd)",
    width: "120px",
    height: "120px",
  },
  size: {
    marginBottom: "2px",
    fontSize: "16px",
  },
  fileName_not__hover: {
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  fileName: {
    width: "120px",
    whiteSpace: "pre-wrap",
    fontSize: "13px",
    marginTop: "10px",
    padding: "5px",
  },
  crossIconDiv: {
    zIndex: "500",
    position: "absolute",
    display: "block",
    top: "25%",
    left: "30%",
  },
  downloadIconDiv: {
    zIndex: "500",
    position: "absolute",
    display: "block",
    top: "25%",
    left: "5%",
  },
  blurTextDiv: {
    position: "absolute",
    display: "block",
    top: "25%",
  },
  crossIcon: {
    width: "54px",
    height: "54px",
    color: "#999",
    opacity: "0.8",
    cursor: "pointer",
  },
  blur: {
    filter: "blur(3px)",
    WebkitFilter: "blur(3px)",
    MozFilter: "blur(3px)",
    OFilter: "blur(3px)",
    MsFilter: "blur(3px)",
  },
  crossIconDivLeft: {
    left: "55%"
  },
  errorText: {
    color: 'tomato',
    margin: 0,
    fontSize: '1 rem',
  }
}));
