import { makeStyles } from "@material-ui/core";

export default makeStyles(() => ({
  containerClass: {
    width: "100%",
    height: "50px",
    marginBottom: "40px",
  },
  inputField: {
    margin: "0 !important",
    padding: "5px 10px",
    height: "50px",
    width: "100%",
    border: "1px solid #005F74",
    transition: "all ease-in-out 0.4s",
    backgroundColor: "white",
    borderRadius: "4px",
  },
  label: {
    display: "inline-block",
    fontFamily: '"Roboto",  sans-serif',
    fontSize: "17px",
    color: "#3f3e45",
  },
}));
