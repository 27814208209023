import { makeStyles } from "@material-ui/core";

export default makeStyles(() => ({
  header: {
    position: "relative",
    zIndex: "1",
    padding: "20px 0",
    backgroundSize: "cover",
    OBoxShadow: "-20px 3px 30px rgba(0, 0, 0, 0.12)",
    boxShadow: "-20px 3px 30px rgb(0 0 0 / 12%)",
  },
}));
