import React from "react";
import useStyle from "./DropDown.styles";
import { MenuItem, SvgIcon, TextField } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import './DropDown.css';

const Dropdown = (props) => {
  const styles = useStyle();
  const {
    containerClassName,
    className,
    label = "",
    options = [],
    field,
    ...properties
  } = props;
  const { t } = useTranslation();
  return (
    <TextField
      select
      {...field}
      {...properties}
      className="custom-select"
      classes={{ root: containerClassName }}
      label={t(label)}
      SelectProps={{
        disableUnderline: true,
        IconComponent: () => <SvgIcon className={`${styles.dropdownIcon} custom-icon`} />,
        classes: {
          root: `${styles.wide} ${styles.input} ${className}`,
        },
        MenuProps: {
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: (document.body.dir === "ltr") ? "left" : "right",
          },
          classes: {
            paper: styles.dropdown,
          },
        },
      }}
      InputLabelProps={{
        classes: {
          root: styles.dropdown_label,
          focused: styles.focus_dropdown,
          animated: styles.no_animation,
          shrink: styles.focus_dropdown,
        },
      }}
      InputProps={{ classes: { formControl: styles.formControl, disabled: styles.disabled } }}
    >
      {options.map((option) => {
        return (
          <MenuItem
            key={option.dropdownValue}
            classes={{ root: styles.menu_items }}
            value={option.dropdownValue}
          >
            {t(option.label)}
          </MenuItem>
        );
      })}
    </TextField>
  );
};

export default Dropdown;
