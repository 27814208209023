import {
  Button,
  Divider,
  Grid,
  Checkbox as MuiCheckbox,
  FormControlLabel,
  MenuItem,
  Select,
  Snackbar,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Add, Remove } from "@material-ui/icons";
import React, { useMemo, useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import InputField from "../../../components/InputField/InputField";
import CustomDatePicker from "../../../components/CustomDatePicker/CustomDatePicker";
import Dropdown from "../../../components/DropDown/DropDown";
import FileUploader from "../../../components/FileUploader/FileUploader";
// import FileUpload from "../../../components/FileUpload/FileUpload";
// import CheckboxField from "../../../components/CheckboxField/CheckboxField";
import ActionButtons from "../ActionButtons/ActionButtons";
import useStyle from "./CertificationDetails.styles";
import { getLookupOptions } from "../../../lookupData.options";
import { Form, FieldArray, Field, Formik, ErrorMessage } from "formik";
import { getCertificationDetailsValidation } from "./validation";
import {
  setActiveStep,
  updateFormData,
  submitFormData,
  fetchLookupData,
} from "../../../store/reducers/onboardingReducer";

import classNames from "classnames";
import ClipLoader from "react-spinners/ClipLoader"; // Optional, if using react-spinners

const CertificationDetails = () => {
  const { i18n, t } = useTranslation();
  const styles = useStyle();
  // const { activeStep, certificationDetails } = useSelector(
  //   (state) => state.onboarding
  // );
  const { dir } = useSelector((state) => state.generalStates);
  const {
    activeStep,
    certificationDetails,
    selectedFile,
    parentId,
    uniqueRecordId,
  } = useSelector((state) => state.onboarding);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false); // Loader state
  const certificationOptions = useMemo(() => getLookupOptions(dir), [dir]);

  // const personalPhotoRef = useRef(null);
  const [certificationFiles, setCertificationFiles] = useState([]);

  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [snackBarSeverity, setSnackBarSeverity] = useState("success");

  const initialCertificationData =
    Array.isArray(certificationDetails) && certificationDetails.length > 0
      ? certificationDetails.map((detail) => ({
          certificateName: detail.certificateName || "", // Certificate Name Textbox
          instituteName: detail.instituteName || "", // Institute Name Textbox (Coursera/LinkedIn)
          accomplishmentDate: detail.accomplishmentDate || "", // Accomplishment Date Date Picker
          certificateYear: detail.certificateYear || "", // Certificate Year Auto-populated
          expiry: detail.expiry || false, // Expiry Checkbox (assuming false if not present)
          expiryDate: detail.expiryDate || "", // Expiry Date Date Picker
          attachtments: detail.attachtments || "", // Attachment File Upload
          uniqueRecordId: detail.uniqueRecordId || "", // uniqueRecordId
        }))
      : [];

  // Convert base64 to Blob
  const base64ToBlob = (base64, contentType = "", sliceSize = 512) => {
    const byteCharacters = atob(base64.split(",")[1]);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);

      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  };

  useEffect(() => {
    if (certificationDetails && certificationDetails.length > 0) {
      const allFiles = certificationDetails.map((detail) => {
        const attachtments = detail.attachtments || [];

        // return attachments.map((attachment) => {
        const byteString = attachtments.InputStream?._buffer || ""; // Fallback to empty string
        const contentType = attachtments.ContentType;
        const fileName = attachtments.FileName;

        const base64Image = `data:${contentType};base64,${byteString}`;
        // const blob = base64ToBlob(base64Image, contentType); // Convert to Blob

        return {
          name: fileName,
          type: contentType,
          size: attachtments.ContentLength,
          image: base64Image, // Keep base64 for display
          // blob: blob, // Store Blob for submission
        };
        // });
      });

      const flattenedFiles = allFiles;
      setCertificationFiles(flattenedFiles); // Consider renaming this if needed
    }
  }, [certificationDetails]);

  const base64ToFile = (base64, fileName, contentType) => {
    const byteCharacters = atob(base64);
    const byteNumbers = new Uint8Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const blob = new Blob([byteNumbers], { type: contentType });
    return new File([blob], fileName, { type: contentType });
  };

  const onClickSubmit = async (values) => {
    setIsLoading(true);
    const processedCertificationData = values.certificationDetails.map(
      ({ attachtments, attachment, ...rest }) => rest
    );

    // let imagesData = certificationData.map((cert) => cert.attachment); // Directly get the file object

    const imagesData = values.certificationDetails.map((mapdata) => {
      if (mapdata.hasOwnProperty("uniqueRecordId")) {
        if (mapdata.attachtments.hasOwnProperty("InputStream")) {
          const base64Image = mapdata.attachtments.InputStream._buffer; // Extract base64 string
          const fileName = mapdata.attachtments.FileName;
          const contentType = mapdata.attachtments.ContentType;

          // Convert base64 to File object
          const imageFile = base64ToFile(base64Image, fileName, contentType);
          return imageFile;
        } else {
          return mapdata.attachtments;
        }
      } else {
        return mapdata.attachtments;
      }
    });

    // console.log(
    //   "Processed Certification Data (without images):",
    //   processedCertificationData
    // );
    // console.log("Images Data:", imagesData);

    // Dispatch the updateFormData action to store qualification details in Redux
    dispatch(
      updateFormData({ step: activeStep, data: processedCertificationData })
    );

    try {
      // Dispatch the action to submit form data
      const result = await dispatch(
        submitFormData({
          certificationDetails: processedCertificationData,
          selectedFile: imagesData,
          activeStep,
          parentId,
        })
      );
      // Check if form submission was successful
      if (result.meta.requestStatus === "fulfilled") {
        // Show success message using Snackbar
        setSnackBarMessage("Form submitted successfully");
        setSnackBarSeverity("success");
        dispatch(setActiveStep(activeStep + 1)); // Move to the next step
      } else {
        throw new Error(
          result.payload ||
            result.error.message ||
            t(
              "Something went wrong. We are having trouble completing your request, please try again"
            )
        );
      }
    } catch (error) {
      // Show error message using Snackbar
      setSnackBarMessage(
        t(
          "Something went wrong. We are having trouble completing your request, please try again"
        )
      );
      setSnackBarSeverity("error");
    } finally {
      setIsLoading(false); // Stop loading state in both success and error cases
      setSnackBarOpen(true); // Open Snackbar with the message
    }
  };

  const loaderContainerStyle = {
    display: "flex",
    justifyContent: "center", // Centers horizontally
    alignItems: "center", // Centers vertically
    //height: '100vh'            // Full viewport height
  };

  const loaderStyle = {
    border: "8px solid #f3f3f3", // Light grey
    borderTop: "8px solid #3498db", // Blue
    borderRadius: "50%",
    width: "60px",
    height: "60px",
    animation: "spin 2s linear infinite",
  };

  const spinKeyframes = `@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }`;

  if (isLoading) {
    return (
      <div style={loaderContainerStyle}>
        <style>{spinKeyframes}</style> {/* Add the spin animation */}
        <div style={loaderStyle}></div> {/* Custom loader */}
      </div>
    );
  }
  const isArabic = i18n.language === "ar";
  return (
    <>
      <Snackbar
        open={snackBarOpen}
        autoHideDuration={50000} // Duration in milliseconds
        onClose={() => setSnackBarOpen(false)}
        anchorOrigin={{
          vertical: "top",
          horizontal: isArabic ? "left" : "right",
        }} // Positioning
        style={{ top: "100px" }} // Adjust top positioning as needed
        // className={isArabic ? "rtl-snackbar" : "ltr-snackbar"}
      >
        <Alert
          onClose={() => setSnackBarOpen(false)}
          severity={snackBarSeverity}
          sx={{
            width: "auto",
            direction: isArabic ? "rtl" : "ltr", // Use a variable to determine language
            textAlign: isArabic ? "left" : "right", // Align text based on language
            margin: "16px", // Optional margin to keep spacing
            // top: "200px !important",
          }}
        >
          {snackBarMessage}
        </Alert>
      </Snackbar>
      <Formik
        initialValues={{
          certificationDetails: [...initialCertificationData],
        }}
        validationSchema={getCertificationDetailsValidation(t)}
        onSubmit={(values) => onClickSubmit(values)}
        enableReinitialize
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          handleChange,
          setFieldTouched,
        }) => {
          const handleFileUpload = (file, index) => {
            const updatedFiles = [...certificationFiles];
            updatedFiles[index] = file;
            // console.log("Updated Certification Files:", updatedFiles);
            setCertificationFiles(updatedFiles);
            setFieldValue(`certificationDetails[${index}].attachtments`, file);
          };
          return (
            <>
              <Form>
                <FieldArray name="certificationDetails">
                  {({ push, remove }) => (
                    <>
                      {values.certificationDetails.map((_, index) => (
                        <React.Fragment key={index}>
                          {values.certificationDetails.length > 0 && (
                            <Button
                              className={styles.textButtonRemove}
                              variant="text"
                              color="secondary"
                              startIcon={<Remove fontSize="small" />}
                              onClick={() => remove(index)}
                            >
                              {t("Remove Certification")}
                            </Button>
                          )}
                          <Grid
                            className={styles.container}
                            container
                            direction="row"
                            justifyContent="flex-start"
                          >
                            <Field
                              containerClass={`${styles.inputField}`}
                              name={`certificationDetails[${index}].certificateName`}
                              placeholder="Certificate Name"
                              helperText={
                                touched.certificationDetails?.[index]
                                  ?.certificateName &&
                                errors.certificationDetails?.[index]
                                  ?.certificateName
                              }
                              className={classNames({
                                [styles.error]:
                                  touched.certificationDetails?.[index]
                                    ?.certificateName &&
                                  Boolean(
                                    errors.certificationDetails?.[index]
                                      ?.certificateName
                                  ),
                              })}
                              component={InputField}
                              value={
                                values.certificationDetails[index]
                                  .certificateName
                              }
                              onBlur={() =>
                                setFieldTouched(
                                  `certificationDetails[${index}].certificateName`,
                                  true
                                )
                              } // Trigger validation on blur
                              onFocus={() => {
                                setFieldTouched(
                                  `certificationDetails[${index}].certificateName`,
                                  true
                                ); // Mark field as touched on focus
                              }}
                            />
                            <Field
                              containerClass={`${styles.inputField}`}
                              name={`certificationDetails[${index}].instituteName`}
                              placeholder="Institute Name"
                              helperText={
                                touched.certificationDetails?.[index]
                                  ?.instituteName &&
                                errors.certificationDetails?.[index]
                                  ?.instituteName
                              }
                              className={classNames({
                                [styles.error]:
                                  touched.certificationDetails?.[index]
                                    ?.instituteName &&
                                  Boolean(
                                    errors.certificationDetails?.[index]
                                      ?.instituteName
                                  ),
                              })}
                              component={InputField}
                              value={
                                values.certificationDetails[index].instituteName
                              }
                              onBlur={() =>
                                setFieldTouched(
                                  `certificationDetails[${index}].instituteName`,
                                  true
                                )
                              } // Trigger validation on blur
                              onFocus={() => {
                                setFieldTouched(
                                  `certificationDetails[${index}].instituteName`,
                                  true
                                ); // Mark field as touched on focus
                              }}
                            />
                            <Field
                              name={`certificationDetails[${index}].accomplishmentDate`}
                              placeholder="Accomplishment Date"
                              helperText={
                                touched.certificationDetails?.[index]
                                  ?.accomplishmentDate &&
                                errors.certificationDetails?.[index]
                                  ?.accomplishmentDate
                              }
                              className={classNames(styles.inputField, {
                                [styles.error]:
                                  touched.certificationDetails?.[index]
                                    ?.accomplishmentDate &&
                                  Boolean(
                                    errors.certificationDetails?.[index]
                                      ?.accomplishmentDate
                                  ),
                              })}
                              component={CustomDatePicker}
                              onChange={(date) => {
                                const isoDate =
                                  date instanceof Date && !isNaN(date)
                                    ? date.toISOString()
                                    : ""; // Convert to ISO string
                                const year = date
                                  ? new Date(date).getFullYear()
                                  : "";

                                setFieldValue(
                                  `certificationDetails[${index}].accomplishmentDate`,
                                  isoDate
                                );
                                setFieldValue(
                                  `certificationDetails[${index}].certificateYear`,
                                  year
                                );
                              }}
                              value={
                                values.certificationDetails[index]
                                  ?.accomplishmentDate
                                  ? new Date(
                                      values.certificationDetails[
                                        index
                                      ].accomplishmentDate
                                    ) // Convert back for the date picker
                                  : null
                              }
                              onBlur={() =>
                                setFieldTouched(
                                  `certificationDetails[${index}].accomplishmentDate`,
                                  true
                                )
                              } // Trigger validation on blur
                              onFocus={() => {
                                setFieldTouched(
                                  `certificationDetails[${index}].accomplishmentDate`,
                                  true
                                ); // Mark field as touched on focus
                              }}
                            />
                            <Field
                              // name={`certificationDetails[${index}].certificateYear`}
                              placeholder="Certificate Year"
                              containerClass={`${styles.inputField}`}
                              // helperText={
                              //   touched.certificationDetails?.[index]
                              //     ?.certificateYear &&
                              //   errors.certificationDetails?.[index]
                              //     ?.certificateYear
                              // }
                              // className={classNames({
                              //   [styles.error]:
                              //     touched.certificationDetails?.[index]
                              //       ?.certificateYear &&
                              //     Boolean(
                              //       errors.certificationDetails?.[index]
                              //         ?.certificateYear
                              //     ),
                              // })}
                              component={InputField}
                              value={
                                values.certificationDetails[index]
                                  .certificateYear
                              }
                              disabled
                            />

                            <Field
                              // name={`certificationDetails[${index}].expiry`}
                              name={`certificationDetails[${index}].expiry`}
                              label="Your Certificate is Expiry?"
                              containerClassName={`${styles.dropdown}`}
                              component={Dropdown}
                              options={certificationOptions.expiryOptions}
                              value={values.certificationDetails[index].expiry}
                              helperText={
                                Object.keys(touched)?.length > 0 &&
                                Object.keys(errors)?.length > 0 &&
                                touched?.certificationDetails[index]?.expiry &&
                                errors?.certificationDetails[index]?.expiry
                              }
                              className={classNames(styles.dropdownInput, {
                                [styles.error]:
                                  Object.keys(touched).length > 0 &&
                                  Object.keys(errors).length > 0 &&
                                  touched?.certificationDetails[index]
                                    ?.expiry &&
                                  Boolean(
                                    errors?.certificationDetails[index]?.expiry
                                  ),
                              })}
                              onChange={(e) => {
                                // console.log("Dropdown onChange:", e.target.value); // Log change
                                setFieldValue(
                                  `certificationDetails[${index}].expiry`,
                                  e.target.value
                                );
                              }}
                              onBlur={() =>
                                setFieldTouched(
                                  `certificationDetails[${index}].expiry`,
                                  true
                                )
                              } // Trigger validation on blur
                              onFocus={() => {
                                setFieldTouched(
                                  `certificationDetails[${index}].expiry`,
                                  true
                                ); // Mark field as touched on focus
                              }}
                            />

                            {values.certificationDetails[index].expiry ===
                              true && (
                              <Field
                                name={`certificationDetails[${index}].expiryDate`}
                                placeholder="Expiry Date"
                                helperText={
                                  touched.certificationDetails?.[index]
                                    ?.expiryDate &&
                                  errors.certificationDetails?.[index]
                                    ?.expiryDate
                                }
                                className={classNames(styles.inputField, {
                                  [styles.error]:
                                    touched.certificationDetails?.[index]
                                      ?.expiryDate &&
                                    Boolean(
                                      errors.certificationDetails?.[index]
                                        ?.expiryDate
                                    ),
                                })}
                                component={CustomDatePicker}
                                onChange={(date) => {
                                  // Convert the date to an ISO string before storing it
                                  const isoDate =
                                    date instanceof Date && !isNaN(date)
                                      ? date.toISOString()
                                      : "";
                                  setFieldValue(
                                    `certificationDetails[${index}].expiryDate`,
                                    isoDate
                                  );
                                }}
                                value={
                                  values.certificationDetails[index]?.expiryDate
                                    ? new Date(
                                        values.certificationDetails[
                                          index
                                        ].expiryDate
                                      ) // Convert back for the date picker
                                    : null
                                }
                                onBlur={() =>
                                  setFieldTouched(
                                    `certificationDetails[${index}].expiryDate`,
                                    true
                                  )
                                } // Trigger validation on blur
                                onFocus={() => {
                                  setFieldTouched(
                                    `certificationDetails[${index}].expiryDate`,
                                    true
                                  ); // Mark field as touched on focus
                                }}
                              />
                            )}

                            <Grid item xs={12} sm={12} md={12}>
                              <FileUploader
                                headingText={`Upload Certificate Photo`}
                                helperText="Or Drag And Drop Certificate Photo Here"
                                state={certificationFiles[index]} // Bind the specific certification's attachment
                                setState={(file) =>
                                  handleFileUpload(file, index)
                                } // Dynamically set the file at the correct index
                                name={`certificationDetails[${index}].attachtments`} // Set name dynamically
                                error={
                                  touched.certificationDetails?.[index]
                                    ?.attachtments &&
                                  Boolean(
                                    errors.certificationDetails?.[index]
                                      ?.attachtments
                                  )
                                }
                              />

                              {/* Error message display */}
                              {touched.certificationDetails?.[index]
                                ?.attachtments && (
                                <div
                                  style={{
                                    color: "red",
                                    fontSize: "0.575rem",
                                    marginTop: "-0.25rem",
                                  }}
                                >
                                  {
                                    errors.certificationDetails?.[index]
                                      ?.attachtments
                                  }
                                </div>
                              )}
                            </Grid>
                          </Grid>
                          {values.certificationDetails.length - 1 !== index && (
                            <Divider className={styles.divider} />
                          )}
                        </React.Fragment>
                      ))}
                      <Grid
                        className={styles.container}
                        container
                        direction="row"
                        justifyContent="flex-end"
                      >
                        <Button
                          className={styles.textButton}
                          variant="text"
                          startIcon={
                            <Add className={styles.icon} fontSize="small" />
                          }
                          onClick={() =>
                            push({
                              certificateName: "",
                              instituteName: "",
                              accomplishmentDate: "",
                              certificateYear: "",
                              expiry: "",
                              expiryDate: "",
                              attachtments: "",
                            })
                          }
                        >
                          {t("Add Certification")}
                        </Button>
                      </Grid>
                      <Divider style={{ margin: "20px 0" }} />
                    </>
                  )}
                </FieldArray>
                <ActionButtons activeStep={activeStep} />
              </Form>
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default CertificationDetails;
