import React from "react";
import { useTranslation } from "react-i18next";
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from "@material-ui/icons/Close";

const LastSection = () => {
  const { t } = useTranslation();
  const queryParams = new URLSearchParams(window.location.search);
  // const crmreferenceId = queryParams.get('crmreferenceId');
  const requestID = queryParams.get("requestId");
  // let thankyouText = t('Thank you Message Onboarding');
  // if (requestID != null && requestID != "") {
  //   thankyouText = t('No Request ID');
  // }
  let thankyouText = requestID
    ? t("Thank you Message Onboarding")
    : t("No Request ID");
  // return (<p>{t('Thank you Message Onboarding')}</p>);

 // Choose the color and icon based on requestID
 const textColor = requestID ? "#28a745" : "#dc3545";
 const borderColor = requestID ? "#28a745" : "#dc3545";
 const IconComponent = requestID ? CheckIcon : CloseIcon;

  return (
    <>
       <div
        className="success-msg"
        style={{
          border: `2px solid ${borderColor}`, // Border style based on the condition
          padding: "10px", // Padding inside the border
          display: "flex", // Adjust container size
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <span className="icon" alt="Status Icon">
          <IconComponent style={{ color: textColor, fontSize: "2rem" }} /> {/* Dynamically choose icon and color */}
        </span>
      </div>
      <p
        style={{
          fontFamily: "Roboto, sans-serif",
          fontSize: "1.3rem",
          fontWeight: "500",
          margin: "auto", // Centers horizontally
          textAlign: "center", // Centers text within the <p> element
          width: "100%",
          // color:'#28a745',
          color: textColor,
          // color: requestID ? "#28a745" : "#dc3545",
          // height:"100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {thankyouText}
      </p>
    </>
  );
};

export default LastSection;