import * as Yup from "yup";

export const getQualificationDetailsValidation = (t) => {
  return Yup.object().shape({
    qualificationDetails: Yup.array().of(
      Yup.object().shape({
        // School: Yup.string().required(t("School is required")),
        School: Yup.string()
          .required(t("School is required"))
          .matches(
            /^[A-Za-z\s\u0600-\u06FF]+$/, // Allow English and Arabic letters
            t("Please enter valid value")
          )
          .max(50, t("Character count exceeded")),
        Speciality: Yup.string().required(t("Speciality/Major is required")),
        // Degree: Yup.string().required(t("Please select an item in the list")),
        Degree: Yup.number() // Change to number to match dropdown values
        .required(t("Please select an item in the list"))
        .oneOf([1, 2, 3, 4, 5, 6, 7], t("Invalid degree selected")), // Expecting 1, 2, or 3
        // Status: Yup.string().required(t("Status is required")),
        Status: Yup.string().required(t("Status is required")),
        StartDate: Yup.date()
          .typeError(t("Start Date is required"))
          .required(t("Start Date is required"))
          .max(new Date(), t("Start date cannot be a future date")),
       
        EndDate: Yup.date()
          .transform((value, originalValue) => {
            // Agar original value string me "2" hai tu usko integer me convert karein
            return originalValue === "2" ? 2 : value;
          })
          .nullable()
          .typeError(t("End Date is required"))
          .required(t("End Date is required"))
          .when("Status", (status, schema) => {
            if (status === "2") {
              // Jab status string "2" ho to min aur max validations chalay
              return schema
                .required(t("End Date is required"))
                .min(
                  Yup.ref("StartDate"),
                  t("End Date must be after the Start Date")
                )
                .max(
                  new Date(),
                  t("End date cannot be a future date")
                );
            } else if (status === 2) {
              // Jab status integer 2 ho, invalid type error ko replace karne k liye custom message
              return schema.required(
                t("End Date is required")
              );
            } else {
              // Baqi cases ke liye future date ki validation
              return schema
                .min(
                  new Date(),
                  t("For In progress End Date must be in the future")
                )
                .nullable();
            }
          }),
        attachment: Yup.mixed()
          .nullable()
          .required(t("Attachment is required"))
          .test("fileType", t("Invalid Format"), (value) => {
            if (!value) return true;
            const SUPPORTED_FORMATS = [
              "application/pdf",
              "image/jpg",
              "image/jpeg",
              "image/png",
            ];
            // return SUPPORTED_FORMATS.includes(value?.type);
            return (
              SUPPORTED_FORMATS.includes(value?.type) ||
              SUPPORTED_FORMATS.includes(value?.ContentType)
            );
          }),
      })
    )
    .test(
      "degree-status-validation",
      t(
        "If PhD is 'In Progress', Master's and Bachelor's must be 'Completed'. If Master's is 'In Progress', Bachelor's must be 'Completed'."
      ),
      function (qualificationDetails) {
        if (!qualificationDetails) return true;

        const phd = qualificationDetails.find((qual) => qual.Degree === 1);
        const masters = qualificationDetails.find((qual) => qual.Degree === 2);
        const bachelors = qualificationDetails.find((qual) => qual.Degree === 3);
        const diploma = qualificationDetails.find((qual) => qual.Degree === 4);
        const HSchool = qualificationDetails.find((qual) => qual.Degree === 5);
        const LSchool = qualificationDetails.find((qual) => qual.Degree === 6);
        const Ldiploma = qualificationDetails.find((qual) => qual.Degree === 7);

         // Validate PhD requirements
    if ((phd && phd.Status === "1") || (phd && phd.Status === "2") ) {
      if (masters && masters.Status !== "2") {
        return this.createError({
          path: `${this.path}[${qualificationDetails.indexOf(masters)}].Status`,
          message: t("Status of this qualification must be completed."),
        });
      }
      if (bachelors && bachelors.Status !== "2") {
        return this.createError({
          path: `${this.path}[${qualificationDetails.indexOf(bachelors)}].Status`,
          message: t("Status of this qualification must be completed."),
        });
      }
      if ((diploma && diploma.Status === "2") || (diploma && diploma.Status === "1") ) {
        return this.createError({
          path: `${this.path}[${qualificationDetails.indexOf(diploma)}].Status`,
          message: t("Status of this qualification must be completed."),
        });
      }
      if ((HSchool && HSchool.Status === "2") || (HSchool && HSchool.Status === "1") ) {
        return this.createError({
          path: `${this.path}[${qualificationDetails.indexOf(HSchool)}].Status`,
          message: t("Status of this qualification must be completed."),
        });
      }
      if ((LSchool && LSchool.Status === "2") || (LSchool && LSchool.Status === "1") ) {
        return this.createError({
          path: `${this.path}[${qualificationDetails.indexOf(LSchool)}].Status`,
          message: t("Status of this qualification must be completed."),
        });
      }
      if ((Ldiploma && Ldiploma.Status === "2") || (Ldiploma && Ldiploma.Status === "1") ) {
        return this.createError({
          path: `${this.path}[${qualificationDetails.indexOf(Ldiploma)}].Status`,
          message: t("Status of this qualification must be completed."),
        });
      }
    }

        // Validate Master's requirements
        if ((masters && masters.Status === "1") || (masters && masters.Status === "2") ) {

          if (bachelors && bachelors.Status !== "2") {
            // return false;
            // console.error("Validation error for Master's: Bachelor's is not Completed");
            return this.createError({
              path: `${this.path}[${qualificationDetails.indexOf(bachelors)}].Status`,
              message: t("Status of this qualification must be completed."),
            });
          }
          if ((diploma && diploma.Status === "2") || (diploma && diploma.Status === "1") ) {
            return this.createError({
              path: `${this.path}[${qualificationDetails.indexOf(diploma)}].Status`,
              message: t("Status of this qualification must be completed."),
            });
          }
          if ((HSchool && HSchool.Status === "2") || (HSchool && HSchool.Status === "1") ) {
            return this.createError({
              path: `${this.path}[${qualificationDetails.indexOf(HSchool)}].Status`,
              message: t("Status of this qualification must be completed."),
            });
          }
          if ((LSchool && LSchool.Status === "2") || (LSchool && LSchool.Status === "1") ) {
            return this.createError({
              path: `${this.path}[${qualificationDetails.indexOf(LSchool)}].Status`,
              message: t("Status of this qualification must be completed."),
            });
          }
          if ((Ldiploma && Ldiploma.Status === "2") || (Ldiploma && Ldiploma.Status === "1") ) {
            return this.createError({
              path: `${this.path}[${qualificationDetails.indexOf(Ldiploma)}].Status`,
              message: t("Status of this qualification must be completed."),
            });
          }
        }

        return true;
      }
    ),
   
  });
};
