import React, { useEffect, useMemo, useState } from "react";
import {
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
} from "@material-ui/core";
import useStyle from "./Login.styles";
import logo_login from "../../assets/images/logo-login.svg";
import InputField from "../../components/InputField/InputField";
import {
  ArrowForward,
  Lock,
  Person,
  Visibility,
  VisibilityOff,
} from "@material-ui/icons";
import { Link, useHistory } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import Slider from "react-slick";
import StyledButton from "../../components/StyledButton/StyledButton";
import { login } from "../../store/reducers/authReducer";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { generalReducerActions } from "../../store/reducers/generalReducer";
import './login.css';
import ReCAPTCHA from "react-google-recaptcha";
import slide1 from "../../assets/images/Login_Page/slide1.png";
import slide2 from "../../assets/images/Login_Page/slide2.png";
import slide3 from "../../assets/images/Login_Page/slide3.png";
import { useFormik } from 'formik';
import { getLoginValidation } from "./validation";
import classNames from "classnames";
import { registrationDataActions } from "../../store/reducers/registrationReducer";

const bg = [slide1, slide2, slide3]
const Login = (props) => {
  const { user } = useSelector(
    (state) => state.auth
  );
  const dispatch = useDispatch();
  const styles = useStyle();
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [captchaChecked, setCaptchaChecked] = useState(false);
  const [index, setIndex] = useState(0); 


  const [values, setValues] = useState({
    email: "",
    password: "",
    showPassword: false,
    rememberPassword: "off",
  });

  const sliderText = useMemo(() => ['Attracting young talents, to develop and maintain their abilities', 'Discovering the talents and competencies of human cadres in the specialized and technical fields', 'Our vision is an emirate with integrated sustainable urban development and outstanding municipal services for the well-being and happiness of the community'], []);
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const loginHandler = async () => {
    if (captchaChecked) {
      let payload = {
        email: formik.values.email,
        password: formik.values.password,
      };

      dispatch(generalReducerActions.toggleLoading(true));
      const response = await dispatch(login(payload));

      if (response.payload.status === 200) {
        localStorage.setItem("IsPopupShown", response?.payload?.data?.IsPopupShown);
        window.grecaptcha.reset();
        dispatch(generalReducerActions.toggleLoading(false));
        dispatch(registrationDataActions.setActiveStep(0));
      }
      if (response.payload.status !== 200) {

        window.grecaptcha.reset();
        dispatch(generalReducerActions.toggleLoading(false))
        dispatch(generalReducerActions.snackBarUpdate({
          open: true,
          message: 'Invalid username or password',
          severity: 'error',
        }));
      }
    } else {
      dispatch(generalReducerActions.snackBarUpdate({
        open: true,
        message: `Invalid Captcha`,
        severity: 'warning',
      }));
    }
  };

 
  const onChangeRecaptcha = (value) => {
    if (value) {
      setCaptchaChecked(true)
    }
  }

  const onExpiredRecaptcha = () => {
    setCaptchaChecked(false)
  };

  const formik = useFormik({
    initialValues: {
      ...values
    },
    validationSchema: getLoginValidation(t),
    onSubmit: () => {
      loginHandler()

    },
  });

  useEffect(() => {
    user.isAuthenticated && (props.location.state?.jobDetailsPage ? history.goBack() : history.replace('/'))
  }, [user.isAuthenticated, history, props.location.state?.jobDetailsPage])

  useEffect(() => {
    document.title = `${t('Login')} - ${t("DMT Career Portal")}`
  }, [i18n.language, t])

  return (
    <Grid className={styles.root} container direction="row">
      <Grid item className={`${styles.loginFormGrid} login-form-wrapper`}>
        <div className={styles.loginLogo}>
          <img src={logo_login} className={styles.logo} alt="logo" />
        </div>
        <form style={{ width: "75%" }} onSubmit={formik.handleSubmit} >
          <FormGroup row={false}>
            <InputField
              containerClass={styles.inputFields}
              className={classNames({ [styles.error]: formik.touched.email && Boolean(formik.errors.email) })}
              label={t("Email")}
              name="email"
              startAdornment={<Person classes={{ root: styles.icon }} />}
              endAdornment=""
              value={formik.values.email}
              onChange={formik.handleChange}
              helperText={formik.touched.email && formik.errors.email}
            />
            <InputField
              containerClass={styles.inputFields}
              className={classNames({ [styles.error]: formik.touched.password && Boolean(formik.errors.password) })}
              label={t("Password")}
              name="password"
              type={values.showPassword ? "text" : "password"}
              startAdornment={<Lock className={styles.icon} />}
              endAdornment={
                <IconButton onClick={handleClickShowPassword}>
                  {values.showPassword ? (
                    <Visibility className={styles.iconLowOpacity} />
                  ) : (
                    <VisibilityOff className={styles.iconLowOpacity} />
                  )}
                </IconButton>
              }
              value={formik.values.password}
              onChange={formik.handleChange}
              helperText={formik.touched.password && formik.errors.password}
              autoComplete={values.rememberPassword}
            />
          </FormGroup>
          <Grid className={styles.reCaptchaGrid} container direction="row" justifyContent="center" alignItems="center" >
            <ReCAPTCHA
              hl={i18n.language}
              // sitekey="6Ld08YQaAAAAAKMjEaZ9u4WMXYfywPVmmjmQUuPM"   //old key
                //sitekey="6LcM98cUAAAAAPvIMa6wiMfDySx9Hj2TDAF77H-K"      //staging site key
                sitekey="6LdbctUfAAAAAADBTvbWN6i1_FKaA2owkOmz5qa7"    //production site key
              onChange={onChangeRecaptcha}
              onExpired={onExpiredRecaptcha}
            />
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            className={styles.actionGrid}
          >
            <FormControlLabel
              classes={{ label: styles.formControlLabel }}
              control={<Checkbox className={styles.checkbox} checked={values.rememberPassword === "on"} onClick={() => setValues(prevState => ({ ...prevState, rememberPassword: prevState.rememberPassword === "off" ? "on" : "off" }))} />}
              label={t("Remember my password")}
            />
            <Link to="/forgot-password" className={styles.link}>
              {t("Forgot password?")}
            </Link>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            className={`${styles.actionGrid} mb-15`}
          >
            <StyledButton className={styles.signInButton} type="submit">
              {t("SignIn")}
            </StyledButton>
            <Grid className={`${styles.creatAccount}, create-account-link`} item>
              {/* <p className={styles.label}>{t("Not registered yet?")}</p> */}
              <Link to="/registration-form" className={styles.linkBold}>
                {t("Create an account")}{" "}
                <ArrowForward className={` ${styles.icon} login-icon `} />
              </Link>
            </Grid>
          </Grid>
        </form>
        <Footer className={styles.footer} />
      </Grid>
      <Grid item className={`${styles.backgroundGrid} login-slider-section`} style={{ backgroundImage: `url(${bg[index]})` }}>
        <p className={styles.welcomeText}>{t("Welcome to")}</p>
        <p className={styles.infoText}>{t("DMT CAREER PORTAL")}</p>
        <Divider className={styles.divider} />
        <Slider
          arrows={false}
          dots={true}
          speed={500}
          autoplay={true}
          autoplaySpeed={5000}
          dotsClass={styles.sliderDots}
          afterChange={(slide) => setIndex(slide)}
        >
          {sliderText.map((txt, index) => (
            <div key={index}>
              <h4>{t(txt)}</h4>
            </div>
          ))}
        </Slider>
      </Grid>
      <Footer className="slider-footer" />
    </Grid>
  );
};

export default Login;
