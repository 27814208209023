import { makeStyles } from "@material-ui/core";

export default makeStyles(() => ({
  root: {
    minHeight: "100vh",
  },
  loginFormGrid: {
    flex: "1",
    minWidth: "350px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  loginLogo: {
    margin: "100px 0 32px",
    textAlign: "center",
  },
  logo: {
    maxWidth: "100%",
    height: "auto",
  },
  icon: {
    color: "#005F74",
  },
  iconLowOpacity: {
    opacity: "0.7",
  },
  inputFields: {
    width: "100%",
    minWidth: "100%",
    marginBottom: "40px",    
    "@media screen and (max-width: 710px)": {
      width: "100%",
    },
  },
  link: {
    color: "#005F74",
    fontFamily: '"Roboto",  sans-serif',
    fontSize: "16px",
    textDecoration: "none",
    whiteSpace: "nowrap",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  linkBold: {
    color: "#005F74",
    fontFamily: '"Roboto",  sans-serif',
    textDecoration: "none",
    fontSize: "16px",
    fontWeight: "500",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  signInButton: {
    height: "60px",
    width: "45%",
    "@media (max-width: 710px)": {
      width: "100%",
      marginTop: "5px",
    },
  },
  checkbox: {
    color: "#005F74",
  },
  label: {
    fontFamily: '"Roboto",  sans-serif',
    fontSize: "16px",
    color: "#3f3e45",
    whiteSpace: "pre-wrap",
    margin: "0",
    width: "100%",
  },
  formControlLabel: {
    fontFamily: '"Roboto",  sans-serif',
    fontSize: "16px",
    color: "#3f3e45",
    whiteSpace: "pre-wrap",
    margin: "0",
    width: "70%",
  },
  actionGrid: {
    width: "100%",
    flexWrap: "nowrap",
    "@media (max-width: 710px)": {
      width: "100%",
      flexWrap: "wrap",
    },
    "& label": {
      marginRight: 0,
      width: "70%",
    },
  },
  backgroundGrid: {
    padding: "108px 50px",
    flex: "1",
    minWidth: "350px",
    backgroundSize: "cover",
    color: "#fff",
    borderRadius: "52px 0 0 0",
    OBoxShadow: "-30px 3px 30px rgba(0, 0, 0, 0.1)",
    boxShadow: "-30px 3px 30px rgb(0 0 0 / 10%)",
    "@media (max-width: 710px)": {
      height: "50%",
    },
    "@media (max-width: 699px)": {
      borderRadius: "0"
    },
  },
  divider: {
    margin: "30px 0",
    width: "42%",
    backgroundColor: "white",
  },
  sliderDots: {
    padding: "0",
    float: "left",
    "& li": {
      display: "inline-block",
      margin: "0 5px",
      "& button": {
        margin: "5px",
        borderRadius: "50%",
        backgroundColor: "#fff",
        border: "0",
        padding: "5px",
        fontSize: "0",
        cursor: "pointer",
        "&:hover": {
          background: "rgba(219, 219, 219, 0.8)",
          outline: "0",
        },
        "&:focus": {
          background: "rgba(219, 219, 219, 0.8)",
          outline: "0",
        },
      },
    },
  },
  welcomeText: {
    margin: "0",
    fontSize: "1.25rem",
    fontWeight: "500",
  },
  infoText: {
    margin: "0",
    fontSize: "2rem",
    fontWeight: "700",
    textTransform: "uppercase",
  },
  footer: {
    "@media (max-width: 699px)": {
      display: "none",
    }
  },
  creatAccount: {
    width: "45%",
    "@media (max-width: 710px)": {
      width: "100%",
    },
  },
  reCaptchaGrid: {
    marginBottom: "15px"
  },
  error: {
    border: "1px solid #f44336",
    backgroundColor: " rgb(255 190 190 / 50%)"
  },  
}));
