import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../api/index.js";

const api = new API();

const initialState = {
  activeStep: 0,
  experienceFieldOptions: [],
  nationalityOptions: [],
  hiringEntityOptions: [],
  workNatureOptions: [],
  academiceSpecialityOptions: [],
  issueLocationOptions: [],
  qualificationDetails: [],
  experienceDetails: [],
  personalDetails: {},
  attachments: [],
};

export const savePersonalDetails = createAsyncThunk(
  "registration/savePersonalDetails",
  async (data, { rejectWithValue }) => {
    try {
      const response = await api.post("api/Registration/SaveRegisration", data);

      return { data: response.data, status: response.status };
    }
    catch (error) {
      return rejectWithValue({ data: error.data, status: error.statusCode });
    }
  }
);

export const getPersonalDetailsById = createAsyncThunk(
  "registration/getPersonalDetailsById",
  async (id, { rejectWithValue }) => {
    try {
      const response = await api.get(
        `api/Registration/GetRegistrationByRegistrationId?registrationId=${id}`
      );

      return { data: response.data, status: response.status };
    }
    catch (error) {
      return rejectWithValue({ data: error.data, status: error.statusCode })
    }
  }
);

export const saveQualificationDetails = createAsyncThunk(
  "registration/saveQualificationDetails",
  async (data, { rejectWithValue }) => {
    try {
      const response = await api.post(
        "api/Registration/SaveScientificQualificationDetail",
        data
      );

      return { data: response.data, status: response.status };
    }
    catch (error) {
      return rejectWithValue({ data: error.data, status: error.statusCode })
    }
  }
);

export const getQualificationDetailsById = createAsyncThunk(
  "registration/getQualificationDetailsById",
  async (id, { rejectWithValue }) => {
    try {
      const response = await api.get(
        `api/Registration/GetScientificQualificationDetailByRegistrationID?registrationId=${id}`
      );

      return { data: response.data, status: response.status };
    }
    catch (error) {
      return rejectWithValue({ data: error.data, status: error.statusCode })
    }
  }
);

export const saveExperienceDetails = createAsyncThunk(
  "registration/saveExperienceDetails",
  async (data, { rejectWithValue }) => {
    try {
      const response = await api.post(
        "api/Registration/SaveExperienceDetail",
        data
      );

      return { data: response.data, status: response.status };
    }
    catch (error) {
      return rejectWithValue({ data: error.data, status: error.statusCode })
    }
  }
);

export const getExperienceDetailsById = createAsyncThunk(
  "registration/getExperienceDetailsById",
  async (id, { rejectWithValue }) => {
    try {
      const response = await api.get(
        `api/Registration/GetExperienceDetailByRegistrationID?registrationId=${id}`
      );

      return { data: response.data, status: response.status };
    }
    catch (error) {
      return rejectWithValue({ data: error.data, status: error.statusCode })
    }
  }
);

export const saveAttachments = createAsyncThunk(
  "registration/saveAttachments",
  async (data, { rejectWithValue }) => {
    try {
      const response = await api.post(
        `api/Registration/UploadDocument?documentListName=CareerPortal&folderName=${data.registrationId}&isOverride=true
        `,
        data.data,
        {
          headers: { 'content-type': 'multipart/form-data' }
        }
      );

      return { data: response.data, status: response.status };
    }
    catch (error) {
      return rejectWithValue({ data: error.data, status: error.statusCode })

    }
  }
);

export const saveLogs = createAsyncThunk(
  "registration/saveLogs",
  async (data, { rejectWithValue }) => {
    try {
      const response = await api.post(
        `api/Registration/WriteErrorlog?message=${data}`
      );

      return { data: response.data, status: response.status };
    }
    catch (error) {
      return rejectWithValue({ data: error.data, status: error.statusCode })
    }
  }
);

export const getAttachmentsById = createAsyncThunk(
  "registration/getAttachmentsById",
  async (id, { rejectWithValue }) => {
    try {
      const response = await api.get(
        `api/DocumentUploadService/GetFileLists?registrationId=${id}`
      );

      return { data: response.data, status: response.status };
    }
    catch (error) {
      return rejectWithValue({ data: error.data, status: error.statusCode })
    }
  }
);

export const getExperienceFieldOptions = createAsyncThunk(
  "registration/getExperienceFieldOptions",
  async (_data, _thunkApi) => {
    const response = await api.get(
      "api/LookupCareerSevices/GetLookUpData?LookupKey=GetExperienceField"
    );

    return { data: response.data, status: response.status };
  }
);

export const getNationalityOptions = createAsyncThunk(
  "registration/getNationality",
  async (_data, _thunkApi) => {
    const response = await api.get(
      "api/LookupCareerSevices/GetLookUpData?LookupKey=GetNationality"
    );

    return { data: response.data, status: response.status };
  }
);

export const getHiringEntityOptions = createAsyncThunk(
  "registration/getHiringEntityOptions",
  async (_data, _thunkApi) => {
    const response = await api.get(
      "api/LookupCareerSevices/GetLookUpData?LookupKey=HiringEntity"
    );

    return { data: response.data, status: response.status };
  }
);

export const getWorkNatureOptions = createAsyncThunk(
  "registration/getWorkNatureOptions",
  async (_data, _thunkApi) => {
    const response = await api.get(
      "api/LookupCareerSevices/GetLookUpData?LookupKey=WorkNature"
    );

    return { data: response.data, status: response.status };
  }
);

export const getAcademiceSpecialityOptions = createAsyncThunk(
  "registration/getAcademiceSpecialityOptions",
  async (_data, _thunkApi) => {
    const response = await api.get(
      "api/LookupCareerSevices/GetLookUpData?LookupKey=AcademiceSpeciality"
    );

    return { data: response.data, status: response.status };
  }
);

export const getIssueLocationOptions = createAsyncThunk(
  "registration/getIssueLocationOptions",
  async (_data, _thunkApi) => {
    const response = await api.get(
      "api/LookupCareerSevices/GetLookUpData?LookupKey=IssueLocation"
    );

    return { data: response.data, status: response.status };
  }
);

export const submitRegistration = createAsyncThunk(
  "registration/submitRegistration",
  async (registrationId, { rejectWithValue }) => {
    try {
      const response = await api.post(
        `api/Registration/SubmitRegistration?RegistrationId=${registrationId}`
      );

      return { data: response.data, status: response.status };
    }
    catch (error) {
      return rejectWithValue({ data: error.data, status: error.statusCode })
    }
  }
);

export const UpdateIsNafisPopupShown = createAsyncThunk(
  "UserAccount/UpdateIsPopupShownField",
  async (registrationId, { rejectWithValue }) => {
    try {
      const response = await api.post(
        `api/UserAccount/UpdateIsPopupShownField?RegistrationId=${registrationId}`
      );

      return { data: response.data, status: response.status };
    }
    catch (error) {
      return rejectWithValue({ data: error.data, status: error.statusCode })
    }
  }
);

export const verifyRegistrationToken = createAsyncThunk(
  "registration/verifyRegistrationToken",
  async (token, { rejectWithValue }) => {
    try {
      const response = await api.post(
        `api/Registration/VerifyRegistrationToken?token=${token}`
      );

      return { data: response.data, status: response.status };
    }
    catch (error) {
      return rejectWithValue({ data: error.data, status: error.statusCode })
    }
  }
);

const registrationDataSlice = createSlice({
  name: "registration",
  initialState,
  reducers: {
    setActiveStep(state, action) {
      state.activeStep = action.payload;
    },
    clearRegistrationStates: (state) => {
      state.personalDetails = {};
      state.qualificationDetails = [];
      state.experienceDetails = [];
      state.activeStep = 0;
    },
  },
  extraReducers: {
    [getExperienceFieldOptions.fulfilled]: (state, action) => {
      state.experienceFieldOptions = action.payload.data;
    },
    [getNationalityOptions.fulfilled]: (state, action) => {
      state.nationalityOptions = action.payload.data;
    },
    [getHiringEntityOptions.fulfilled]: (state, action) => {
      state.hiringEntityOptions = action.payload.data;
    },
    [getWorkNatureOptions.fulfilled]: (state, action) => {
      state.workNatureOptions = action.payload.data;
    },
    [getAcademiceSpecialityOptions.fulfilled]: (state, action) => {
      state.academiceSpecialityOptions = action.payload.data;
    },
    [getIssueLocationOptions.fulfilled]: (state, action) => {
      state.issueLocationOptions = action.payload.data;
    },
    [getPersonalDetailsById.fulfilled]: (state, action) => {
      state.personalDetails = action.payload.data;
    },
    [getQualificationDetailsById.fulfilled]: (state, action) => {
      state.qualificationDetails = action.payload.data;
    },
    [getExperienceDetailsById.fulfilled]: (state, action) => {
      state.experienceDetails = action.payload.data;
    },
    [getAttachmentsById.fulfilled]: (state, action) => {
      state.attachments = action.payload.data;
    },
  },
});

export const registrationDataActions = registrationDataSlice.actions;

export default registrationDataSlice.reducer;
