
export const featuredJobsDataFormatter = (data) => {
  let formattedData = data instanceof Array ? data?.map((job) => {
    return {
      id: job.JobId,
      titleEN: job.JobNameEn,
      titleAR: job.JobNameAr,
      descriptionEN: job.JobRoleDescription,
      descriptionAR: job.JobRoleDescriptionAr,
    };
  })
    :
    [];
  formattedData = formattedData.length > 0 ? formattedData.map((data) => {
    let newData = { ...data }
    Object.keys(formattedData).map(key => (formattedData[key] === null || formattedData[key] === undefined) && delete newData[key]);
    return newData;
  })
    :
    [];
  return formattedData;
};

export const featuredCategoriesDataFormatter = (data) => {
  let formattedData = data instanceof Array ? data.map((category) => {
    return {
      id: category.JobCategoryId,
      titleEN: category.JobCategoryByName,
      titleAR: category.JobCategoryByNameAr,
      numberOfJobs: category.JobCount,
    };
  })
    :
    [];
  formattedData = formattedData.length > 0 ? formattedData.map((data) => {
    let newData = { ...data }
    Object.keys(formattedData).map(key => (formattedData[key] === null || formattedData[key] === undefined) && delete newData[key]);
    return newData;
  })
    :
    [];
  return formattedData;
};

export const jodDetailsDataFormatter = (data) => {
  let formattedData = {
    id: data.JobId,
    categoryId: data.JobCategory,
    titleEN: data.JobNameEn,
    titleAR: data.JobNameAr,
    descriptionEN: data.JobRoleDescription,
    descriptionAR: data.JobRoleDescriptionAr,
    date_posted: data.PublisingDate,
    expiration_date: data.EndOfPublishingDate,
    location: data.JobLocation,
    // experience: data.NumberOfExperienceAfterGraduation,
    minExperience: data.MinNumberOfExperienceAfterGraduation,
    maxExperience: data.MaxNumberOfExperienceAfterGraduation,
    // qualification: data.EducationLevel,
    numberOfCandidates: data.RequireNumberOfCandidate,
    hiringEntity: data.HiringEntity,
    workNature: data.WorkNature,
    educationLevel: data.QualificationModel,
    academicSpecialty: data.AcademicSpecialityModel,
    // certificates: data.CertificateAcademicModel,
    gender: data.Gender,
    other: data.Other,
    otherAR: data.OtherAr,
    salaryFrom: data.SalaryFrom,
    salaryTo: data.SalaryTo,
  };
  Object.keys(formattedData).forEach((key) => {
    if (formattedData[key] instanceof Array) {
      formattedData[key] = formattedData[key].map((nestedData) => {
        let nestedObjectData = { ...nestedData };
        Object.keys(nestedData).map(nestedKey => (nestedObjectData[nestedKey] === null || nestedObjectData[nestedKey] === undefined) && delete nestedObjectData[nestedKey]);
        return nestedObjectData;
      })
    }
    if (formattedData[key] === null || formattedData[key] === undefined) {
      delete formattedData[key];
    }
  });
  return formattedData;
};
