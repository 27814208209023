import * as yup from "yup";

export const getForgotPasswordValidation = (t) => yup.object().shape({
    email: yup
        .string()
        .when("showVerifyCode", {
            is: false,
            then: yup.string().email(t('Enter a valid email'))
                .required(t('Email is required'))
        }),
    verificationCode: yup
        .number()
        .when("showVerifyCode", {
            is: true,
            then: yup.number().required(t('Verification Code is required'))
                .test('len', t('Verification Code must be of excatly 4 digits'), val => !val || (val && val.toString().length === 4))
        }),
});