import React, { useMemo } from 'react';
import { Drawer, IconButton, List } from '@material-ui/core';
import { ArrowBack as ArrowBackIcon, HomeOutlined, PersonAddOutlined, LockOpenOutlined, PersonOutline, LockOutlined, VpnKeyOutlined } from '@material-ui/icons';
import classNames from 'classnames';
// styles
import useStyles from './styles';
// components
import SidebarLink from './components/SidebarLink/SidebarLink';
//redux
import { useSelector, useDispatch } from 'react-redux';
import { generalReducerActions } from '../../../store/reducers/generalReducer';
import { withRouter } from 'react-router-dom';
import { authActions } from '../../../store/reducers/authReducer';
import './sidebar.css';
import { registrationDataActions } from '../../../store/reducers/registrationReducer';

const linksUnAuthenticated = [
  {
    id: 0,
    label: 'Home',
    link: '/',
    icon: <HomeOutlined />,
  },
  { id: 1, label: 'Signup', link: '/registration-form', icon: <PersonAddOutlined /> },
  { id: 2, label: 'Login', link: '/login', icon: <LockOpenOutlined /> },
];

function Sidebar(props) {
  const { isSidebarOpened } = useSelector((state) => state.generalStates);
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const styles = useStyles();
  const userData = JSON.parse(localStorage.getItem("UserData"))
  const linksAuthenticated = useMemo(() => [
    {
      id: 0,
      label: 'Home',
      link: '/',
      icon: <HomeOutlined />,
    },
    { id: 1, label: userData?.FirstName, link: '/user-profile', icon: <PersonOutline /> },
    {
      id: 2,
      label: 'Change Password',
      link: '/change-password',
      icon: <VpnKeyOutlined />,
    },
    { id: 3, label: 'Logout', icon: <LockOutlined />, type: "title", onClick: () => { dispatch(authActions.logout()); dispatch(registrationDataActions.clearRegistrationStates()); props.history.replace("/login"); } },
  ], [dispatch, userData?.FirstName, props.history])

  return (
    <Drawer
      className={classNames(styles.drawer, {
        [styles.drawerOpen]: isSidebarOpened,
        [styles.drawerClose]: !isSidebarOpened,
      })}
      styles={{
        paper: classNames({
          [styles.drawerOpen]: isSidebarOpened,
          [styles.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
      onClose={() => dispatch(generalReducerActions.toggleSidebar(false))}
    >
      <div className={styles.mobileBackButton}>
        <IconButton onClick={() => dispatch(generalReducerActions.toggleSidebar(!isSidebarOpened))}>
          <ArrowBackIcon
            styles={{
              root: classNames(styles.headerIcon, styles.headerIconCollapse),
            }}
            className="sd-toggle-sidebar"
          />
        </IconButton>
      </div>
      <List className={styles.sidebarList}>
        {user.isAuthenticated ? (
          linksAuthenticated.map((link) => (
            <SidebarLink
              key={link.id}
              location={props.location}
              isSidebarOpened={isSidebarOpened}
              {...link}
            />
          ))
        ) : (
          linksUnAuthenticated.map((link) => (
            <SidebarLink
              key={link.id}
              location={props.location}
              isSidebarOpened={isSidebarOpened}
              {...link}
            />
          ))
        )}
      </List>
    </Drawer>
  );
}
export default withRouter(Sidebar);
