import axios from "axios";

const contentType = {
  json: "application/json",
  multipart: "multipart/form-data",
};

// const API_BASE_URL = "http://localhost:44381/api";
// const API_BASE_URL = "https://jobsstg.dmt.gov.ae/OnboardingApi/api";
const API_BASE_URL = "https://jobs.dmt.gov.ae/OnboardingApi/api";
//const API_BASE_URL = "https://innovationmonth.dmt.gov.ae/OnboardingApi/api";

// console.log("api url onbarding api", API_BASE_URL);

export default class APIOnboarding {
  constructor(
    config = {
      headers: { contentType: contentType.json },
    }
  ) {
    this.config = {};
    this.instance = null;
    const headers = {
      "Content-Type": config.headers.contentType || contentType.json,
    };

    this.config = {
      baseURL: config.baseURL || API_BASE_URL,
      headers: headers,
      maxContentLength: Infinity,
      maxBodyLength: Infinity,
    };

    this.instance = axios.create(this.config);
    // No interceptors needed for this public API
  }

  // GET request method
  get(url, params) {
    return this.instance.get(url, { params });
  }

  // POST request method
  post(url, body, contentType) {
    console.log('expected formData onboarding: ', body)
    console.log('isFormData onboarding: ', body instanceof FormData)
    // If contentType is provided, use it, otherwise let axios set the appropriate header
    const headers = contentType ? { 'Content-Type': contentType } : {};
  
    return this.instance.post(url, body, { headers });
  }

  // General method for fetching lookup data with dynamic parameters
  getLookupData(lookupKeys) {
    const endpoint = "/LookupCareerSevices/GetLookUpData";
    // Expecting an array of keys for multiple lookups
    const params = { LookupKey: lookupKeys };
    return this.get(endpoint, params);
  }
 // Method for fetching applicant data based on registrationId and stepNo
 getApplicantData(registrationId, stepNo) {
  const endpoint = `/CareerPortal/GetApplicantId?registrationId=${registrationId}&stepNo=${stepNo}`;
  return this.get(endpoint); // Assuming this.get makes a GET request
}
}

