import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  containerHead: {
    width: "100%",
    padding: "15px 15px 0 15px",
    marginRight: "auto",
    marginLeft: "auto",
  },
  containerBody: {
    width: "100%",
    padding: "40px 0",
    backgroundColor: "#f2f0f0",
    minHeight: "300px"
  },
  mainHeading: {
    padding: "10px 0 25px 100px",
    color: "#fff",
    "@media (max-width: 960px)": {
      padding: "10px 0 25px 8%",
    },
    "@media (max-width: 817px)": {
      padding: "10px 0 25px 2%",
    },
    "@media (max-width: 770px)": {
      padding: "10px 14% 25px 14%",
    },
  },
  searchBox: {
    position: "relative",
    margin: "5px auto -50px",
    padding: "30px 35px",
    backgroundColor: "#fff",
    width: "67%",
    borderRadius: "8px",
    OBoxShadow: "0 16px 22px rgba(0, 0, 0, 0.08)",
    boxShadow: "0 16px 22px rgb(0 0 0 / 8%)",
    "@media (max-width: 960px)": {
      width: "82%",
    },
    "@media (max-width: 817px)": {
      width: "92%",
    },
    "@media (max-width: 770px)": {
      width: "70%",
      padding: "30px 20px",
    },
  },
  inputFields: {
    width: "38%",
    minWidth: "150px",
    marginBottom:"0px",
    "@media (max-width: 770px)": {
      width: "100%",
    },
  },
  dropdown: {
    width: "40%",
    minWidth: "150px",
    "& label": {
      top: "-7px",
    },
    "@media (max-width: 770px)": {
      width: "100%",
      marginTop: "10px",
    },
  },
  welcomeText: {
    margin: "0",
    fontSize: "1.25rem",
    fontWeight: "500",
  },
  infoText: {
    margin: "0",
    fontSize: "2rem",
    fontWeight: "700",
    textTransform: "uppercase",
  },
  featureText: {
    color: "#3f3e45",
    fontSize: "1.5rem",
    fontWeight: "700",
    textAlign: "center",
  },
  cardContainer: {
    width: "80%",
    "@media (max-width: 960px)": {
      width: "85%",
    },
    "@media (max-width: 865px)": {
      width: "90%",
    },
    "@media (max-width: 817px)": {
      width: "95%",
    },
    "@media (max-width: 770px)": {
      width: "73%",
    },
  },
  icon: {
    color: "#005F74",
    padding: "3px 0 0 0",
  },
  sliderGrid: {
    width: "100%",
    padding: "20px 0",
    backgroundColor: "#fff",
    textAlign: "center",
  },
  slider: {
    padding: "0 50px 0 50px",
    height: "300px",
  },
  card: {
    width: "90% !important",
    minWidth: "150px",
    padding: "25px 10px",
    border: "1px solid #005F74",
    borderRadius: "8px",
    transition: "height ease-in-out 0.4s",
    "&:focus": {
      outline: "none",
    },
    "&:active": {
      outline: "none",
    },
    "&:hover": {
      textDecoration: "none",
      marginBottom: "0",
      OBoxShadow: "0 8px 16px rgba(0, 0, 0, 0.15)",
      boxShadow: "0 8px 16px rgba(0, 0, 0, 0.15)",
      "& $btnView": {
        maxHeight: "150px",
        opacity: "1",
        padding: "0.375rem 0.75rem",
      },
    },
  },
  iconDiv: {
    height: "50px",
    width: "50px",
    border: "1px solid #005F74",
    borderRadius: "50%",
    marginBottom: "10px",
    padding: "5px",
  },
  badge: {
    display: "inline-block",
    marginTop: "15px",
    padding: "5px 10px",
    color: "#005F74",
    backgroundColor: "#e6e8eb",
    fontSize: "75%",
  },
  btnView: {
    display: "block",
    maxHeight: "0px",
    opacity: "0",
    overflow: "hidden",
    padding: "0",
    color: "#005F74",
    fontWeight: "400",
    transition: "all ease-in-out 0.4s",
    "& span": {
      marginRight: "5px",
      verticalAlign: "middle",
      fontSize: "0.85rem",
    },
  },
  btn: {
    textAlign: "center",
    verticalAlign: "middle",
    userSelect: "none",
    backgroundColor: "transparent",
    border: "1px solid transparent",
    fontSize: "1rem",
    lineHeight: "1.5",
    borderRadius: "0.25rem",
    textDecoration: "none",
  },
  jobCard: {
    width: "30%",
    marginRight: "12px",
    marginLeft: "12px",
    minWidth: "200px",
    height: "220px",
    "@media (max-width: 770px)": {
      width: "100%",
    },
  },
  searchButton: {
    "@media (max-width: 770px)": {
      marginTop: "10px",
      width: "100%",
    },
  },
  searchIcon: {
    cursor: "pointer",
    color: "#005F74",
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));
