import { makeStyles } from "@material-ui/core";
import sliderBg from "../../assets/images/login-slider-bg.jpg";

export default makeStyles(() => ({
    loginFormGrid: {
        flex: "1",
        minWidth: "350px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
    },
    loginLogo: {
        margin: "100px 0 32px",
        textAlign: "center",
    },
    logo: {
        maxWidth: "100%",
        height: "auto",
    },
    icon: {
        color: "#005F74",
    },

    backgroundGrid: {
        padding: "108px 50px",
        flex: "1",
        minWidth: "350px",
        backgroundSize: "cover",
        color: "#fff",
        borderRadius: "52px 0 0 0",
        OBoxShadow: "-30px 3px 30px rgba(0, 0, 0, 0.1)",
        boxShadow: "-30px 3px 30px rgb(0 0 0 / 10%)",
        backgroundImage: `url(${sliderBg})`,
        "@media (max-width: 710px)": {
            height: "50%",
        },
        "@media (max-width: 699px)": {
            borderRadius: "0"
        },
    },
    divider: {
        margin: "30px 0",
        width: "42%",
        backgroundColor: "white",
    },
    sliderDots: {
        padding: "0",
        float: "left",
        "& li": {
            display: "inline-block",
            margin: "0 5px",
            "& button": {
                margin: "5px",
                borderRadius: "50%",
                backgroundColor: "#fff",
                border: "0",
                padding: "5px",
                fontSize: "0",
                cursor: "pointer",
                "&:hover": {
                    background: "rgba(219, 219, 219, 0.8)",
                    outline: "0",
                },
                "&:focus": {
                    background: "rgba(219, 219, 219, 0.8)",
                    outline: "0",
                },
            },
        },
    },
    welcomeText: {
        margin: "0",
        fontSize: "1.25rem",
        fontWeight: "500",
    },
    infoText: {
        margin: "0",
        fontSize: "2rem",
        fontWeight: "700",
        textTransform: "uppercase",
    },
    footer: {
        "@media (max-width: 699px)": {
            display: "none",
        }
    },
    messageText: { width: 350, marginTop: 15, fontSize: '1.3rem', fontWeight: 500 }
}));
