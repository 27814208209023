import { makeStyles } from "@material-ui/core";

export default makeStyles(() => ({
  card: {
    width: "31%",
    height: "100%",
    minWidth: "200px",
    backgroundColor: "#fff",
    backgroundClip: "border-box",
    boxSizing: "border-box",
    "@media (max-width: 820px)": {
      width: "100%",
    },
  },
  jobCard: {
    position: "relative",
    marginBottom: "31px",
    border: "none",
    borderRadius: "8px",
    transition: "all ease-in-out 0.4s",
    boxSizing: 'border-box',
    "&::before": {
      content: '""',
      position: "absolute",
      top: "0",
      left: "0",
      width: "100%",
      height: "100%",
      background: "transparent",
      border: "2px solid #005F74",
      opacity: "0",
      borderRadius: "8px",
      transition: "opacity ease-in-out 0.4s",
      boxSizing: "border-box"
    },
    "&:hover": {
      OBoxShadow: "0 16px 20px rgba(0, 0, 0, 0.1)",
      boxShadow: "0 16px 20px rgba(0, 0, 0, 0.1)",
      "& $cardHeader": {
        "&::before": {
          width: "100%",
        },
      },
      "& $cardFooter": {
        "& a": {
          textDecoration: "none",
        },
        "& em": {
          display: "inline-block",
          verticalAlign: "middle",
        },
      },
    },
    "&:hover:before": {
      opacity: 1,
    },
  },
  cardHeader: {
    position: "relative",
    overflow: "hidden",
    padding: "1.5rem 1.25rem 0",
    borderRadius: "7px",
    background: "transparent",
    border: "none",
    fontSize: "1.1rem",
    fontWeight: "500",
    "&::before": {
      content: '""',
      position: "absolute",
      height: "7px",
      left: "0",
      top: "0",
      width: "45%",
      background: "#005F74",
      transition: "all ease-in-out 0.4s",
    },
  },
  cardBody: {
    fontSize: "0.813rem",
    lineHeight: "1.25rem",
    color: "#787d80",
    padding: "1.5rem 1.25rem 0",
  },
  cardFooter: {
    height: "57px",
    background: "transparent",
    border: "none",
    padding: "15px 1.25rem 0",
    "& a": {
      position: "relative",
      zIndex: "2",
    },
    "& em": {
      display: "none",
      fontStyle: "normal",
      color: "#005F74",
    },
    "& span": {
      marginRight: "4px",
      verticalAlign: "middle",
      fontSize: "0.85rem",
    },
  },
}));
