import * as Yup from "yup";

export const getCertificationDetailsValidation = (t) =>
  Yup.object().shape({
    certificationDetails: Yup.array().of(
      Yup.object().shape({
        // certificateName: Yup.string().required(
        //   t("Certificate Name is required")
        // ),
        certificateName: Yup
          .string()
          .required(t("Certificate Name is required"))
          .matches(
            /^[A-Za-z\s\u0600-\u06FF]+$/, // Allow English and Arabic letters
            t("Please enter valid value")
          )
          .max(50, t("Character count exceeded")),
        // instituteName: Yup.string().required(t("Institute Name is required")),
        instituteName: Yup
        .string()
        .required(t("Institute Name is required"))
        .matches(
          /^[A-Za-z\s\u0600-\u06FF]+$/, // Allow English and Arabic letters
          t("Please enter valid value")
        )
        .max(50, t("Character count exceeded")),
        accomplishmentDate: Yup.date()
          .required(t("Accomplishment Date is required"))
          .max(new Date(), t("Accomplishment Date cannot be in the future")),
        certificateYear: Yup.number().required(
          t("Certificate Year is required")
        ),
        expiry: Yup.boolean().required(t("Please select an item in the list")),
        expiryDate: Yup.date().when("expiry", {
          is: (expiry) => expiry === true, // Check if expiry is explicitly true
          then: Yup.date()
            .required(t("Expiry Date is required"))
            .min(
              Yup.ref("accomplishmentDate"),
              t("Expiry Date cannot be before Accomplishment Date")
            )
            .min(new Date(), t("Expiry Date must be in the future")) // Ensure expiryDate is not before today
            .typeError(t("Invalid date format")), // Add type error for invalid date formats
          otherwise: Yup.date().nullable(), // Ensure it remains a date type
        }),
     
        attachtments: Yup.mixed()
          .required(t("Attachment is required"))
          .test("fileType", t("Invalid Format"), (value) => {
            if (!value) return true; // attachment is optional
            const SUPPORTED_FORMATS = [
              "application/pdf",
              "image/jpg",
              "image/jpeg",
              "image/png",
            ];
            // return SUPPORTED_FORMATS.includes(value?.type);
            return SUPPORTED_FORMATS.includes(value?.type) || SUPPORTED_FORMATS.includes(value?.ContentType);
          }),
      })
    ),
  });