import * as Yup from "yup";

export const getEmployeePassportValidation = (t) => {
  return Yup.object().shape({
    // passportNumber: Yup.string().required(t('Passport No is Required')),
    employeePassportInfo: Yup.object().shape({
      // passportNumber: Yup.string().required(t("Passport No is Required")),
      passportNumber: Yup
        .string()
        .required(t("Passport No is Required"))
        .matches(
          /^[A-Za-z0-9]+$/, // Allow only English letters and numbers
          t(
            "Please enter valid value"
          )
        )
        .max(10, t("Character count exceeded")),
      issueDate: Yup.date()
        .typeError(t("Issue Date is required"))
        .required(t("Issue Date is required"))
        .max(new Date(), t("Issue Date cannot be later than today")),
      issuanceCountry: Yup.string().required(
        t("Please select an item in the list")
      ),
      expiryDate: Yup.date()
        .typeError(t("Expiry Date is required"))
        .required(t("Expiry Date is required"))
        .test(
          "is-future",
          t("Expiry Date must be in the future"),
          function (value) {
            return value && value > new Date(); // Expiry must be after today
          }
        )
        .min(
          Yup.ref("issueDate"),
          t("Expiry Date cannot be before Issue Date")
        ),
      // expiryDate: Yup.date()
      //   .typeError(t("Please select Expiry Date"))
      //   .required(t("Please select Expiry Date"))
      //   .test(
      //     "is-future",
      //     t("Expiry Date must be in the future"),
      //     function (value) {
      //       const { issueDate } = this.parent;
      //       return value > issueDate;
      //     }
      //   ),
      // issuanceEntity: Yup.string().required(t("Issuance Entity is Required")),
      issuanceEntity: Yup
          .string()
          .required(t("Issuance Entity is Required"))
          .matches(
            /^[A-Za-z\s\u0600-\u06FF]+$/, // Allow English and Arabic letters
            t("Please enter valid value")
          )
          .max(50, t("Character count exceeded")),
    }),
  });
};