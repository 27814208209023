import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../api";

const api = new API();

const initialState = {
  user: {
    isAuthenticated: false,
    data: {},
  },
};

export const login = createAsyncThunk("auth/login", async (payload, { rejectWithValue }) => {
  try {
    const response = await api.post("api/UserAccount/Login", payload);
    return { data: response.data, status: response.status };
  }
  catch (error) {
    return rejectWithValue({ data: error.data, status: error.status })
  }
});

export const sendResetPasswordRequest = createAsyncThunk("auth/sendResetPasswordRequest", async (payload, { rejectWithValue }) => {
  try {
    const response = await api.post("api/UserAccount/SendChangePasswordCodeEmail", payload);
    return { data: response?.data, status: response?.status };
  }
  catch (error) {
    return rejectWithValue({ data: error?.data, status: error?.status })
  }
});

export const checkVerificationCode = createAsyncThunk("auth/checkVerificationCode", async (verificationCode, { rejectWithValue }) => {
  try {
    const response = await api.post(`api/UserAccount/CheckVerificationCode?verficiationCode=${verificationCode}`);
    return { data: response?.data, status: response?.status };
  }
  catch (error) {
    return rejectWithValue({ data: error?.data, status: error?.status })
  }
});

export const submitNewPassword = createAsyncThunk("auth/submitNewPassword", async (payload, { rejectWithValue }) => {
  try {
    const response = await api.post(`api/UserAccount/ResetPassword`, payload);
    return { data: response?.data, status: response?.status };
  }
  catch (error) {
    return rejectWithValue({ data: error?.data, status: error?.status })
  }
});

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout(state) {
      state.user.isAuthenticated = false;
      localStorage.removeItem("LoginId")
      localStorage.removeItem("apikey")
      localStorage.removeItem("UserData")
      localStorage.removeItem("IsPopupShown")
    },
    alreadyLogin(state) {
      state.user.isAuthenticated = true;
    },
  },
  extraReducers: {
    [login.fulfilled]: (state, action) => {
      localStorage.removeItem("RegistrationStatus");
      localStorage.removeItem("RegistrationId");
      localStorage.setItem("LoginId", action.payload.data.RegistrationId);
      localStorage.setItem("apikey", action.payload.data.ApiKey);
      localStorage.setItem("UserData", JSON.stringify(action.payload.data));
      state.user.data = action.payload.data;
      state.user.isAuthenticated = true;
    },
  },
});

export const authActions = authSlice.actions;

export default authSlice.reducer;
