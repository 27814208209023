import React from "react";
import useStyle from "./JobCard.styles";

const JobCard = ({ headerText, cardBody, cardFooter, className }) => {
  const styles = useStyle();
  return (
    <div className={`${className} ${styles.card} ${styles.jobCard}`}>
      <div className={styles.cardHeader}>{headerText}</div>
      <div className={styles.cardBody}>{cardBody?.length > 60 ? cardBody.substring(0, 60) + "..." : cardBody}</div>
      <div className={styles.cardFooter}>{cardFooter}</div>
    </div>
  );
};

export default JobCard;
