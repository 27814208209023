import { makeStyles } from "@material-ui/core";

export default makeStyles(() => ({
  container: {
    width: "100%",
    height: "100%",
    padding: "3% 10% 0 10%",
    marginRight: "auto",
    marginLeft: "auto",
    backgroundColor: "#fff !important",
  },
  containerHead: {
    width: "100%",
    padding: "3% 10%",
    marginRight: "auto",
    marginLeft: "auto",
  },
  mainHeading: {
    color: "#fff !important",
  },
  infoText: {
    margin: "0",
    fontSize: "2rem",
    fontWeight: "700",
    textTransform: "uppercase",
  },
  stepperDiv: {
    width: "22.5%",
    minWidth: "150px",
    border: "1px solid #e1e1e1",
    borderBottom: "0px",
    OBoxShadow: "0 3px 8px rgba(0, 0, 0, 0.1)",
    boxShadow: "0 3px 8px rgb(0 0 0 / 10%)",
    "@media (max-width: 770px)": {
      width: "100%",
      height: "100%",
    },
  },
  iconDiv: {
    width: "24px",
    height: "24px",
    padding: "2px 0 0 2px",
    border: "1px solid #005F74",
    borderRadius: "50%",
    color: "#005F74",
  },
  hover: {
    "&:hover": {
      color: "#fff",
      backgroundColor: "#005F74",
      cursor: "pointer"
    },
  },
  active: {
    color: "#fff",
    backgroundColor: "#005F74",
  },
  step: {
    cursor: "pointer",
    "@media (max-width: 770px)": {
      width: "50%",
      margin: "5px 0",
    },
    "@media (max-width: 530px)": {
      width: "100%",
    },
  },
  contentDiv: {
    width: "75%",
    paddingBottom: "20px",
    "@media (max-width: 770px)": {
      width: "100%",
    },
  },
  headingDiv: {
    padding: "10px 13px",
    borderLeft: "2px solid #005F74",
    background: "#e6e8eb",
    fontSize: "1rem",
    fontWeight: "400",
    marginBottom: "10px",
  },
  stepperRow: {
    "@media (max-width: 770px)": {
      flexDirection: "row !important",
      flexWrap: "wrap",
      justifyContent: "space-between",
    },
  },
  connector: {
    "@media (max-width: 770px)": {
      display: "none",
    },
  },
}));
