import * as yup from "yup";

export const getResetPasswordValidation = (t) => yup.object().shape({
    verificationCode: yup
        .number()
        .when("showVerifyCode", {
            is: true,
            then: yup.number().required(t('Verification Code is required'))
                .test('len', t('Verification Code must be of excatly 4 digits'), val => !val || (val && val.toString().length === 4))
        }),
    oldPassword: yup
        .string()
        .when(["showVerifyCode", "showOldPassword"], {
            is: (showVerifyCode, showOldPassword) => !showVerifyCode && showOldPassword,
            then: yup.string().required(t('Old Password is required'))
        }),
    password: yup.string()
        .when("showVerifyCode", {
            is: false,
            then: yup.string().min(6, t("Password must be of minimum 6 characters")).required(t("Password is required"))
        }),
    retypePassword: yup.string()
        .when("showVerifyCode", {
            is: false,
            then: yup.string()
                .oneOf([yup.ref('password')], t('Please make sure your password match.')).required(t("Re-type Password is required"))
        }),
});