import * as yup from "yup";

export const getQualificationDetailsValidation = (t) => yup.object().shape({
    qualifications: yup.array().of(yup.object().shape({
        EducatioLevel: yup
            .number()
            .required(t('Please select an item in the list')),
        EducatioLevelOther: yup
            .string()
            .when("EducatioLevel", {
                is: 6,
                then: yup.string().required(t('Qualification is required'))
            }),
        AcademicSpecialty: yup
            .string()
            .when("EducatioLevel", {
                is: (EducatioLevel) => EducatioLevel !== 1,
                then: yup.string().required(t('Please select an item in the list'))
            }),
        GraduationDate: yup
            .date().typeError(t('Please select Graduation Date'))
            .max(new Date(), t("Graduation date cannot be later than today."))
            .required(t('Please select Graduation Date')),
        AcademyUniversity: yup
            .string()
            .required(t('Academy/University is required')),
    }))
});
