import React, { useEffect, useMemo, useState } from "react";
import {
    Divider,
    Grid,
} from "@material-ui/core";
import useStyle from "./RegistrationSuccess.styles";
import logo_login from "../../assets/images/logo-login.svg";
import Footer from "../../components/Footer/Footer";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import CheckIcon from '@material-ui/icons/Check';
import "../verifyUser/verifyUser.css";
import { Trans } from "react-i18next";
import slide1 from "../../assets/images/Login_Page/slide1.png";
import slide2 from "../../assets/images/Login_Page/slide2.png";
import slide3 from "../../assets/images/Login_Page/slide3.png";

const bg = [slide1, slide2, slide3];

const RegistrationSuccess = (props) => {
    const styles = useStyle();
    const { t, i18n } = useTranslation();
    const [index, setIndex] = useState(0);
    const sliderText = useMemo(() => ['Attracting young talents, to develop and maintain their abilities', 'Discovering the talents and competencies of human cadres in the specialized and technical fields', 'Our vision is an emirate with integrated sustainable urban development and outstanding municipal services for the well-being and happiness of the community'], [])
    useEffect(() => {
        document.title = `${t('Registration Successful')} - ${t("DMT Career Portal")}`
    }, [i18n.language, t])

    return (
        <Grid container direction="row">
            <Grid item className={`${styles.loginFormGrid} login-form-wrapper`}>
                <>
                    <div className={styles.loginLogo}>
                        <img src={logo_login} className={styles.logo} alt="logo" />
                    </div>
                    <div className="success-msg">
                        <span className="icon" alt="Success Icon"><CheckIcon /></span>
                    </div>
                    <div className={`${styles.messageText} success-text`}>
                        {t('Thank you for registration with us. A verification email has been sent to your email account')}
                        <br />
                        <Trans>Please <Link to="/login" className="link">login</Link> to proceed for further actions</Trans>
                    </div>
                </>
                <Footer className={styles.footer} />
            </Grid>
            <Grid item className={`${styles.backgroundGrid} login-slider-section`} style={{ backgroundImage: `url(${bg[index]})` }}>
                <p className={styles.welcomeText}>{t("Welcome to")}</p>
                <p className={styles.infoText}>{t("DMT CAREER PORTAL")}</p>
                <Divider className={styles.divider} />
                <Slider
                    arrows={false}
                    dots={true}
                    speed={500}
                    autoplay={true}
                    autoplaySpeed={5000}
                    dotsClass={styles.sliderDots}
                    afterChange={(slide) => setIndex(slide)}
                >
                    {sliderText.map((txt, index) => (
                        <div key={index}>
                            <h4>{t(txt)}</h4>
                        </div>
                    ))}
                </Slider>
            </Grid>
            <Footer className="slider-footer" />
        </Grid>
    );
};

export default RegistrationSuccess;
