import React, { useEffect, useMemo } from "react";
import {
  Grid,
  Stepper,
  Step,
  StepLabel,
  StepConnector,
} from "@material-ui/core";
import Header from "../../components/Header/Header";
import useStyle from "./RegistrationForm.styles";
import {
  DescriptionOutlined,
  LocalMallOutlined,
  PersonOutline,
  SpeakerNotes,
} from "@material-ui/icons";
import PersonalInformation from "./PersonalInformation/PersonalInformation";
import ScientificQualificationDetails from "./ScientificQualificationDetails/ScientificQualificationDetails";
import ExperienceDetails from "./ExperienceDetails/ExperienceDetails";
import Attachments from "./Attachments/Attachments";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import "./RegistrationForm.css";
import { registrationDataActions } from "../../store/reducers/registrationReducer";
import bg from "../../assets/images/Details & Registration Page/HR_3_1366X14318.png";
import classNames from "classnames";

const RegistrationForm = () => {
  const { t } = useTranslation();
  const styles = useStyle();
  const { activeStep } = useSelector((state) => state.registration);
  const dispatch = useDispatch();
  const steps = useMemo(
    () => [
      "Personal Information",
      "Qualification Details",
      "Experience Details",
      "Attachments",
    ],
    []
  );
  const content = useMemo(
    () => [
      <PersonalInformation />,
      <ScientificQualificationDetails />,
      <ExperienceDetails />,
      <Attachments />,
    ],
    []
  );

  function getStepIcons(props) {
    const { active } = props;
    const icons = {
      1: <PersonOutline fontSize="small" />,
      2: <SpeakerNotes fontSize="small" />,
      3: <LocalMallOutlined fontSize="small" />,
      4: <DescriptionOutlined fontSize="small" />,
    };
    return (
      <div
        className={classNames(styles.iconDiv, {
          [styles.active]: active,
          [styles.hover]: !!localStorage.getItem("LoginId")
        })}
      >
        {icons[String(props.icon)]}
      </div>
    );
  }

  useEffect(() => {
    document.title = `${window.location.pathname === "/user-profile" ? t("User Profile") : t("Registration Form")} - ${t("DMT Career Portal")}`
  }, [t])

  return (
    <React.Fragment>
      <Header bg={bg}>
        <Grid className={styles.containerHead} container direction="column">
          <div className={styles.mainHeading}>
            <p className={styles.infoText}>{window.location.pathname === "/user-profile" ? t("User Profile") : t("Registration Form")}</p>
          </div>
        </Grid>
      </Header>
      <Grid
        className={styles.container}
        container
        direction="row"
        justifyContent="space-between"
      >
        <div className={styles.stepperDiv}>
          <Stepper
            className={styles.stepperRow}
            activeStep={activeStep}
            orientation="vertical"
            nonLinear={!!localStorage.getItem("LoginId")}
            connector={<StepConnector className={styles.connector} />}
          >
            {steps.map((label, index) => (
              <Step
                className={` ${styles.step}, stepper-icon`}
                key={label}
                onClick={() => !!localStorage.getItem("LoginId") && dispatch(registrationDataActions.setActiveStep(index))}
              >
                <StepLabel
                  StepIconComponent={getStepIcons}
                >
                  {t(label)}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </div>
        <div className={` ${styles.contentDiv} stepper-content-div`}>
          <div className={styles.headingDiv}>{t(steps[activeStep])}</div>
          {content[activeStep]}

          <div className="general-message">
            <div>{t("Please feel free to contact on following email and phone number in case of any inquiries and support.")}</div>
            <span className="inline-ele">{t("Email")}: <a href="mailto:Career.Info@dmt.gov.ae">Career.Info@dmt.gov.ae</a></span>
            {/* <span className="inline-ele">{t("Phone")}: <a href="tel:123456789">123456789</a></span> */}
            <div>{t("This email is dedicated for inquiries and support only, please refrain from sending the CV and upload it through the registration portal only.")}</div>
          </div>

        </div>
      </Grid>
    </React.Fragment>
  );
};

export default RegistrationForm;
