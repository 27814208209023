import React, { useEffect, useState } from "react";
import i18next from "i18next";
import Button from "@material-ui/core/Button";
import './LanguageSelect.css';

const languageMap = {
  en: { label: "English", dir: "ltr", active: true, lang: 'en' },
  ar: { label: "العربية", dir: "rtl", active: false, lang: 'ar' },
};

const LanguageSelect = () => {
  const [selected, setSelected] = useState(localStorage.getItem("i18nextLng") || "ar");

  const languageChanger = () => {
    let language = selected === "ar" ? "en" : "ar"
    setSelected(language);
  }

  useEffect(() => {
    i18next.changeLanguage(selected)
    document.body.dir = languageMap[selected].dir;
    document.documentElement.lang = languageMap[selected].lang;
  }, [selected])

  return (
    <div className="language-switcher" id={languageMap[selected].lang === 'en' ? "enSelected" : "arSelected"}>
      <Button onClick={languageChanger}>{languageMap[selected].lang === 'en' ? "العربية" : "English"}</Button>
    </div>
  );
};

export default LanguageSelect;
