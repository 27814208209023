import * as yup from "yup";

export const getLoginValidation = (t) => yup.object({
    email: yup
        .string()
        .email(t('Enter a valid email'))
        .required(t('Email is required')),
    password: yup
        .string()
        .required(t('Password is required')),
});