import { makeStyles } from "@material-ui/core";

export default makeStyles(() => ({
  container: {
    padding: "10px 0",
    "@media (max-width: 770px)": {
      padding: "0",
    },
  },
  inputField: {
    width: "31.5%",
    minWidth: "110px !important",
    height: "35px",
    margin: "10px 0.9%",
    "@media (max-width: 770px)": {
      width: "100%",
    },
    "& input": {
      fontSize: "0.72em",
    },
  },
  MBinputField: (props) => ({
    width: "100%",
    // minWidth: "110px !important",
    height: "38px",
    margin: "5px 0.9%",
    // border: "1px solid #005F74",
    direction: props.dir === 'rtl' ? 'rtl' : 'ltr', // Set direction based on RTL or LTR
    textAlign: props.dir === 'rtl' ? 'right' : 'left', // Adjust text alignment
    "@media (max-width: 770px)": {
      width: "100%",
    },
    "& input": {
      fontSize: "0.72em",
      width: "100% !important",
      border: "0.124rem solid #005F74 !important",
      paddingRight: props.dir === 'rtl' ? "10px" : "15px",
      paddingLeft: props.dir === 'rtl' ? "15px" : "10px",
    },
  }),
 
  dropdown: {
    width: "31.5%",
    minWidth: "110px !important",
    margin: "10px 0.9% 0 0.9%",
    "& label": {
      top: "-12px !important",
      fontSize: "0.72em",
    },
    "@media (max-width: 770px)": {
      width: "100%",
    },
  },
  dropdownInput: {
    height: "21px !important",
    padding: "6px 15px !important",
    fontSize: "0.72em"
  },
  divider: {
    margin: "20px 0 !important",
  },
  error: {
    border: "1px solid #f44336 !important",
    backgroundColor: " rgb(255 190 190 / 50%)"
  },
  uniquePhoneInputClass: {
    border: '2px solid green !important', /* Example style */
    padding: '12px', /* Example style */
    borderRadius: '4px', /* Example style */
  },
  SearchinputField: {
    width: "100%",
    minWidth: "110px !important",
    height: "35px",
    margin: "10px 0.9%",
    "@media (max-width: 770px)": {
      width: "100%",
    },
    "& input": {
      fontSize: "0.72em",
    },
  },
}));
