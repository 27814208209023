import { makeStyles } from "@material-ui/core";

export default makeStyles(() => ({
  inputField: {
    border: "1px solid #005F74",
    transition: "all ease-in-out 0.4s",
    backgroundColor: "white",
    height: "40px",
    borderRadius: "4px",
    "& .MuiOutlinedInput-root": {
      height: "inherit",
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
    "& .Mui-focused": {
      border: "none",
    },
    "& .MuiIconButton-root": {
      padding: "0",
    },
  },
  icon: {
    color: "#005F74",
    cursor: "text",
    fontSize: "17px"
  },
  paperRoot: { borderRadius: "5px", width: "inherit" },
  paper: {
    width: "inherit",
  },
}));
