import { Button, Divider, Grid } from "@material-ui/core";
import { Add, Remove } from "@material-ui/icons";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import CustomDatePicker from "../../../components/CustomDatePicker/CustomDatePicker";
import Dropdown from "../../../components/DropDown/DropDown";
import InputField from "../../../components/InputField/InputField";
import { getLookupOptions } from "../../../lookupData.options";
import { generalReducerActions } from "../../../store/reducers/generalReducer";
import { getAcademiceSpecialityOptions, getQualificationDetailsById, registrationDataActions, saveQualificationDetails } from "../../../store/reducers/registrationReducer";
import ActionButtons from "../ActionButtons/ActionButtons";
import useStyle from "./ScientificQualificationDetails.styles";
import { Form, FieldArray, Field, Formik } from 'formik';
import { getQualificationDetailsValidation } from "./validation";
import classNames from "classnames";
import SearchableDropdown from "../../../components/SearchableDropdown/SearchableDropdown";

const ScientificQualificationDetails = () => {
  const { t } = useTranslation();
  const styles = useStyle();
  const { dir } = useSelector((state) => state.generalStates);
  const { activeStep, academiceSpecialityOptions, qualificationDetails } = useSelector((state) => state.registration);
  const dispatch = useDispatch();

  const qualificationDetailsOptions = useMemo(
    () => getLookupOptions(dir),
    [dir]
  );

  const [id] = useState(localStorage.getItem('LoginId') || localStorage.getItem('RegistrationId'));
  const [qualificationData, setQualificationData] = useState([
    {
      EducatioLevel: "",
      EducatioLevelOther: "",
      AcademicSpecialty: "",
      SpecialistCertificates: "",
      AcademyUniversity: "",
      GraduationDate: null,
      RegistrationFormId: id,
    },
  ]);

  const onClickSubmit = async (values) => {
    let qualifications = [...values.qualifications];
    qualifications.forEach(data => {
      for (let key in data) {
        if (data[key]?.length === 0 || data[key] === null) {
          delete data[key];
        }
      };
    })
    dispatch(generalReducerActions.toggleLoading(true));
    const response = await dispatch(saveQualificationDetails(qualifications));
    if (response.payload.status === 200) {
      if (window.location.pathname === "/user-profile") {
        dispatch(generalReducerActions.snackBarUpdate({
          open: true,
          message: "Your profile has been updated successfully",
          severity: 'success',
        }));
      }
      dispatch(generalReducerActions.toggleLoading(false));
      dispatch(registrationDataActions.setActiveStep(activeStep + 1));
    } else {
      dispatch(generalReducerActions.toggleLoading(false));
      dispatch(generalReducerActions.snackBarUpdate({
        open: true,
        message: "Something went wrong. We are having trouble completing your request, please try again",
        severity: 'error',
      }));
    }
  };

  const formattedAcademiceSpecialityOptions = useMemo(() => {
    const options = academiceSpecialityOptions?.map((data) => {
      return {
        dropdownValue: data.Key,
        label: dir === "ltr" ? data.Value : data.ValueAR,
      };
    });
    return options;
  }, [dir, academiceSpecialityOptions]);

  useEffect(() => {
    async function getDetails() {
      dispatch(generalReducerActions.toggleLoading(true));
      const response = await dispatch(getQualificationDetailsById(id));
      if (response.payload.status === 200) {
        dispatch(generalReducerActions.toggleLoading(false));
      } else {
        dispatch(generalReducerActions.toggleLoading(false));
        dispatch(generalReducerActions.snackBarUpdate({
          open: true,
          message: "Something went wrong. We are having trouble completing your request, please try again",
          severity: 'error',
        }));
      }
    }
    getDetails();
  }, [dispatch, id]);

  useEffect(() => {
    academiceSpecialityOptions.length === 0 && dispatch(getAcademiceSpecialityOptions())
  }, [dispatch, academiceSpecialityOptions.length]);

  useEffect(() => {
    if (qualificationDetails?.length > 0) {
      let data = qualificationDetails.map(qualification => {
        return {
          EducatioLevel: qualification.EducatioLevel,
          AcademicSpecialty: qualification.AcademicSpecialtyId === "00000000-0000-0000-0000-000000000000" ? "" : qualification.AcademicSpecialtyId,
          SpecialistCertificates: qualification.SpecialistCertificates,
          AcademyUniversity: qualification.AcademyUniversity,
          GraduationDate: qualification.GraduationDate,
          RegistrationFormId: id,
          EducatioLevelOther: qualification.EducatioLevelOther || ""
        }
      })
      setQualificationData(data)
    }
  }, [qualificationDetails, id]);

  return (
    <Formik
      initialValues={{
        qualifications: [...qualificationData]
      }}
      validationSchema={getQualificationDetailsValidation(t)}
      onSubmit={(values) => onClickSubmit(values)}
      enableReinitialize
    >
      {({ values, errors, touched, handleChange, handleBlur }) => (
        < Form >
          <FieldArray name="qualifications">
            {({ push, remove }) => (
              <>
                {values.qualifications.map((_, index) => (
                  <React.Fragment key={index}>
                    {values.qualifications.length > 1 && <Button
                      className={styles.textButtonRemove}
                      variant="text"
                      color="secondary"
                      startIcon={<Remove fontSize="small" />}
                      onClick={() => remove(index)}
                    >
                      {t("Remove Qualification")}
                    </Button>}
                    <Grid
                      className={styles.container}
                      container
                      direction="row"
                      justifyContent="flex-start"
                    >
                      <Field
                        name={`qualifications[${index}].EducatioLevel`}
                        label="Education Level"
                        containerClassName={`${styles.dropdown}`}
                        component={Dropdown}
                        options={qualificationDetailsOptions.educationLevelOptions}
                        value={values.qualifications[index].EducatioLevel}
                        helperText={
                          (Object.keys(touched)?.length > 0 && Object.keys(errors)?.length > 0) &&
                          (touched?.qualifications[index]?.EducatioLevel && errors?.qualifications[index]?.EducatioLevel)
                        }
                        className={classNames(styles.dropdownInput,
                          {
                            [styles.error]: (Object.keys(touched).length > 0 && Object.keys(errors).length > 0) &&
                              (touched?.qualifications[index]?.EducatioLevel && Boolean(errors?.qualifications[index]?.EducatioLevel))
                          })}
                      />
                      {
                        values.qualifications[index].EducatioLevel === 6 && (
                          <Field
                            name={`qualifications.${index}.EducatioLevelOther`}
                            placeholder="Write Qualification"
                            containerClass={`${styles.inputField}`}
                            helperText={
                              (Object.keys(touched)?.length > 0 && Object.keys(errors)?.length > 0) &&
                              (touched?.qualifications[index]?.EducatioLevelOther && errors?.qualifications[index]?.EducatioLevelOther)
                            }
                            className={classNames(
                              {
                                [styles.error]: (Object.keys(touched).length > 0 && Object.keys(errors).length > 0) &&
                                  (touched?.qualifications[index]?.EducatioLevelOther && Boolean(errors?.qualifications[index]?.EducatioLevelOther))
                              })}
                            component={InputField}
                            value={values.qualifications[index].EducatioLevelOther}
                            startAdornment=""
                            endAdornment=""
                          />
                        )
                      }
                      <Field
                        name={`qualifications.${index}.AcademicSpecialty`}
                        id={`qualifications.${index}.AcademicSpecialty`}
                        placeholder="Academic Specialty"
                        containerClass={`${styles.inputField}`}
                        helperText={
                          (Object.keys(touched)?.length > 0 && Object.keys(errors)?.length > 0) &&
                          (touched?.qualifications[index]?.AcademicSpecialty && errors?.qualifications[index]?.AcademicSpecialty)
                        }
                        className={classNames(
                          {
                            [styles.error]: (Object.keys(touched).length > 0 && Object.keys(errors).length > 0) &&
                              (touched?.qualifications[index]?.AcademicSpecialty && Boolean(errors?.qualifications[index]?.AcademicSpecialty))
                          })}
                        component={SearchableDropdown}
                        options={formattedAcademiceSpecialityOptions}
                        onChange={(_event, value) => {
                          handleChange({
                            target: {
                              name: `qualifications.${index}.AcademicSpecialty`,
                              value: value?.dropdownValue || ''
                            },
                          })
                        }
                        }
                        value={values.qualifications[index].AcademicSpecialty}
                      />
                      <Field
                        name={`qualifications.${index}.GraduationDate`}
                        placeholder="Graduation Date"
                        helperText={
                          (Object.keys(touched)?.length > 0 && Object.keys(errors)?.length > 0) &&
                          (touched?.qualifications[index]?.GraduationDate && errors?.qualifications[index]?.GraduationDate)
                        }
                        className={classNames(styles.inputField,
                          {
                            [styles.error]: (Object.keys(touched).length > 0 && Object.keys(errors).length > 0) &&
                              (touched?.qualifications[index]?.GraduationDate && Boolean(errors?.qualifications[index]?.GraduationDate))
                          })}
                        component={CustomDatePicker}
                        onChange={(date) =>
                          handleChange({
                            target: {
                              name: `qualifications.${index}.GraduationDate`,
                              value: date
                            },
                          })
                        }
                        value={values.qualifications[index].GraduationDate}
                      />
                      <Field
                        name={`qualifications.${index}.AcademyUniversity`}
                        placeholder="Academy/University"
                        containerClass={`${styles.inputField}`}
                        helperText={
                          (Object.keys(touched)?.length > 0 && Object.keys(errors)?.length > 0) &&
                          (touched?.qualifications[index]?.AcademyUniversity && errors?.qualifications[index]?.AcademyUniversity)
                        }
                        className={classNames(
                          {
                            [styles.error]: (Object.keys(touched).length > 0 && Object.keys(errors).length > 0) &&
                              (touched?.qualifications[index]?.AcademyUniversity && Boolean(errors?.qualifications[index]?.AcademyUniversity))
                          })}
                        component={InputField}
                        value={values.qualifications[index].AcademyUniversity}
                        startAdornment=""
                        endAdornment=""
                      />
                      <Field
                        name={`qualifications.${index}.SpecialistCertificates`}
                        placeholder="Specialist Certificates"
                        containerClass={`${styles.inputField}`}
                        component={InputField}
                        value={values.qualifications[index].SpecialistCertificates}
                        startAdornment=""
                        endAdornment=""
                      />
                    </Grid>
                    {values.qualifications.length - 1 !== index && (
                      <Divider className={styles.divider} />
                    )}
                  </React.Fragment>
                ))}
                <Grid
                  className={styles.container}
                  container
                  direction="row"
                  justifyContent="flex-end"
                >
                  <Button
                    className={styles.textButton}
                    variant="text"
                    startIcon={<Add className={styles.icon} fontSize="small" />}
                    onClick={() => push({
                      EducatioLevel: "",
                      AcademicSpecialty: "",
                      SpecialistCertificates: "",
                      AcademyUniversity: "",
                      GraduationDate: null,
                      RegistrationFormId: id,
                    })}
                  >
                    {t("Add Another Qualification")}
                  </Button>
                </Grid>
                <Divider style={{ margin: "20px 0" }} />
                <ActionButtons />
              </>

            )}
          </FieldArray>
        </Form>
      )
      }
    </Formik >
  );
};

export default ScientificQualificationDetails;
