import * as yup from "yup";


export const getPersonalInfoValidation = (t) =>
  yup.object().shape({
    Title: yup.string().required(t("Please select an item in the list")),
    Gender: yup.string().required(t("Please select an item in the list")),
    FirstName: yup
      .string()
      .required(t("First Name English is required"))
      .matches(/^[A-Za-z\s]+$/, t("Please enter a valid First Name in English"))
      .max(50, t("Character count exceeded")),

    FirstNameAR: yup
      .string()
      .required(t("First Name Arabic is required"))
      .matches(
        /^[\u0600-\u06FF\s]*$/,
        t("Please enter a valid First Name in Arabic")
      )
      .max(50, t("Character count exceeded")),
    LastName: yup
      .string()
      .required(t("Last Name English is required"))
      .matches(/^[A-Za-z\s]+$/, t("Please enter a valid Last Name in English"))
      .max(50, t("Character count exceeded")),

    LastNameAR: yup.string().required(t("Last Name Arabic is required")),
    LastNameAR: yup
      .string()
      .required(t("Last Name Arabic is required"))
      .matches(
        /^[\u0600-\u06FF\s]*$/,
        t("Please enter a valid Last Name in Arabic")
      )
      .max(50, t("Character count exceeded")),

    FatherName: yup
      .string()
      .required(t("Father Name English is required"))
      .matches(
        /^[A-Za-z\s]+$/,
        t("Please enter a valid Father Name in English")
      )
      .max(50, t("Character count exceeded")),

    FatherNameAR: yup
      .string()
      .required(t("Father Name Arabic is required"))
      .matches(
        /^[\u0600-\u06FF\s]*$/,
        t("Please enter a valid Father Name in Arabic")
      )
      .max(50, t("Character count exceeded")),
    GrandFatherName: yup
      .string()
      .required(t("Grand Father Name English is required"))
      .matches(
        /^[A-Za-z\s]+$/,
        t("Please enter a valid Grand Father Name in English")
      )
      .max(50, t("Character count exceeded")),
    GrandFatherNameAR: yup
      .string()
      .required(t("Grand Father Name Arabic is required"))
      .matches(
        /^[\u0600-\u06FF\s]*$/,
        t("Please enter a valid Grand Father Name in Arabic")
      )
      .max(50, t("Character count exceeded")),

    //MotherName: yup.string().required(t("Mother Name is required")),
    MotherName: yup
      .string()
      .required(t("Mother Name is required"))
      .matches(
        /^[A-Za-z\s\u0600-\u06FF]+$/, // Allow English and Arabic letters
        t("Please enter valid value")
      )
      .max(50, t("Character count exceeded")),

    //PreferredName: yup.string().required(t("Preferred Name is required")),
    PreferredName: yup
      .string()
      .required(t("Preferred Name is required"))
      .matches(
        /^[A-Za-z\s\u0600-\u06FF]+$/, // Allow English and Arabic letters
        t("Please enter valid value")
      )
      .max(50, t("Character count exceeded")),
    LocalOrResident: yup
      .boolean()
      .required(t("Please select an item in the list")),
    // EmiratesID: yup.string().when("LocalOrResident", {
    //   is: true,
    //   then: yup.string().required(t("Emirate ID is required")),
    //   otherwise: yup.string().nullable(),
    // }),
    EmiratesID: yup.string().when("LocalOrResident", {
      is: true,
      then: yup
        .string()
        .required(t("Emirate ID is required"))
        .matches(/^784\d{12}$/, t("Please enter a valid Emirates ID")),
      otherwise: yup.string().nullable(),
    }),

    IsVisitVisa: yup.boolean().when("LocalOrResident", {
      is: false,
      then: yup.boolean().required(t("Please select if you have a visit visa")),
    }),

    unifiedNumber: yup.string().when("IsVisitVisa", {
      is: true,
      then: yup
        .string()
        .required(t("Unified Number is required"))
        .matches(/^\d+$/, t("Unified Number must be a number"))
        .min(10, t("Unified Number must be at least 10 digits"))
        .max(15, t("Unified Number can be at most 15 digits")),
      otherwise: yup.string().nullable(), // Allow it to be null if the condition isn't met
    }),

    Nationality: yup.string().required(t("Please select an item in the list")),
    DateofBirth: yup
      .date()
      .max(new Date(), t("Date of birth cannot be later than today."))
      .required(t("Please select Date of Birth"))
      .typeError("Invaid Format"),

    //PlaceofBirth: yup.string().required(t("Place of Birth is required")),
    // PlaceofBirth: yup
    //   .string()
    //   .required(t("Place of Birth is required"))
    //   .max(50, t("Character count exceeded")),
    PlaceofBirth: yup
      .string()
      .required(t("Place of Birth is required"))
      .matches(
        /^[A-Za-z\s\u0600-\u06FF]+$/, // Allow English and Arabic letters
        t("Please enter valid value")
      )
      .max(50, t("Character count exceeded")),

    // RegionofBirth: yup
    //   .string()
    //   .required(t("Please select an item in the list"))
    //   .max(45, t("Character count exceeded")),
    RegionofBirth: yup
      .string()
      .required(t("Place of Birth is required"))
      .matches(
        /^[A-Za-z\s\u0600-\u06FF]+$/, // Allow English and Arabic letters
        t("Please enter valid value")
      )
      .max(50, t("Character count exceeded")),

    CountryofBirth: yup
      .string()
      .required(t("Please select an item in the list")),

    Email: yup
      .string()
      .required(t("Personal Email is required"))
      .email(t("Please enter a valid email address")),

    MobileNumber: yup
      .string()
      .required(t("Mobile Number is required"))
      .min(11, t("Please enter a valid mobile number.")),

    Religion: yup.string().required(t("Please select an item in the list")),

    MaritalStatus: yup
      .string()
      .required(t("Please select an item in the list")),

    HasDisability: yup
      .boolean()
      .required(t("Please select an item in the list")),


    DisabilityDetails: yup.string().when("HasDisability", {
      is: true, // When HasDisability is true
      then: yup
        .string()
        .required(t("Disability details are required"))
        .matches(
          /^[A-Za-z\s\u0600-\u06FF]+$/, // Allow English and Arabic letters
          t("Please enter valid value")
        )
        .max(100, t("Character count exceeded")), // Set max length
      otherwise: yup.string().nullable(), // Allow it to be null if HasDisability is not true
    }),

    BloodGroup: yup.string().required(t("Please select an item in the list")),
    Height: yup
      .string()
      .required(t("Height is required"))
      .matches(/^\d+(\.\d+)?$/, t("Height must be a Enter decimal number")), // Allowing decimal values

    Weight: yup
      .string()
      .required(t("Weight is required"))
      .matches(/^\d+(\.\d+)?$/, t("Weight must be a Enter decimal number")), // Allowing decimal values

    ResidenceAddressinUAE: yup.string().when("LocalOrResident", {
      is: true,
      then: yup
        .string()
        .required(t("Residence Address in UAE is required"))
        // .matches(
        //   /^[A-Za-z\s\u0600-\u06FF]+$/, // Allow English and Arabic letters
        //   t("Please enter valid value")
        // )
        .max(100, t("Character count exceeded")),
      otherwise: yup.string().notRequired(),
    }),

    Emirate: yup.string().when("LocalOrResident", {
      is: true,
      then: yup.string().required(t("Please select an item in the list")),
      otherwise: yup.string().nullable(),
    }),

    Region: yup.string().when("Emirate", {
      is: (value) => {
        // console.log("tstt", value);
        // Abu Dhabi emirates id on staging: 12028452-5086-ef11-b843-005056b3b9cd
      // Abu Dhabi emirates id on production: 27f1f150-c493-ef11-b850-945680105b98
        return value === "27f1f150-c493-ef11-b850-945680105b9";
        // return value === "12028452-5086-ef11-b843-005056b3b9cd";  // Abu Dhabi emirates id on staging
      },
      // Assuming you only want this field to be required if it's NOT 'UAE'
      then: yup.string().required(t("Please select an item in the list")),
      otherwise: yup.string().notRequired(), // If the selected country is 'UAE', it's not required
    }),

    District: yup.string().when("Region", {
      is: (value) => {
        return (
          //Abu Dhabi Region ID on staging: 56ba1f4a-5186-ef11-b843-005056b3b9cd
          // Al Ain Region ID on staging: 1da94555-5186-ef11-b843-005056b3b9cd
          // Al Dhafrah Region ID on staging: 2728bb5f-5186-ef11-b843-005056b3b9cd

          //Abu Dhabi Region ID on production: f3d7dda8-c493-ef11-b850-945680105b98
          // Al Ain Region ID on production: 53aaa7d2-c493-ef11-b82c-005056b31ee1
          // Al Dhafrah Region ID on production: 45242afc-c493-ef11-b850-945680105b98

          value === "f3d7dda8-c493-ef11-b850-945680105b98" ||   
          value === "53aaa7d2-c493-ef11-b82c-005056b31ee1" ||
          value === "45242afc-c493-ef11-b850-945680105b98"

          // value === "56ba1f4a-5186-ef11-b843-005056b3b9cd" ||   
          // value === "1da94555-5186-ef11-b843-005056b3b9cd" ||
          // value === "2728bb5f-5186-ef11-b843-005056b3b9cd"
        );
      },
      // Assuming you only want this field to be required if it's NOT 'UAE'
      then: yup.string().required(t("Please select an item in the list")),
      otherwise: yup.string().notRequired(), // If the selected country is 'UAE', it's not required
    }),

    HomeCountryAddress: yup.string().when("Nationality", {
      is: (value) => {
        // UAE ID on production: d6a2c1a9-c393-ef11-b850-945680105b98
      // UAE ID on staging: 3be64a90-a285-ef11-b843-005056b3b9cd

        return value !== "d6a2c1a9-c393-ef11-b850-945680105b98";
        // return value !== "3be64a90-a285-ef11-b843-005056b3b9cd";
      },
      // Assuming you only want this field to be required if it's NOT 'UAE'
      then: yup
        .string()
        .required(t("Home Country Address is required"))
        // .matches(
        //   /^[A-Za-z\s\u0600-\u06FF]+$/, // Allow English and Arabic letters
        //   t("Please enter valid value")
        // )
        .max(100, t("Character count exceeded")),
      otherwise: yup.string().notRequired(), // If the selected country is 'UAE', it's not required
    }),

    Country: yup.string().when("Nationality", {
      is: (value) => {
        // UAE ID on production: d6a2c1a9-c393-ef11-b850-945680105b98
      // UAE ID on staging: 3be64a90-a285-ef11-b843-005056b3b9cd

        return value !== "d6a2c1a9-c393-ef11-b850-945680105b98";
        // return value !== "3be64a90-a285-ef11-b843-005056b3b9cd";
      },
      // Assuming you only want this field to be required if it's NOT 'UAE'
      then: yup.string().required(t("Please select an item in the list")),
      otherwise: yup.string().notRequired(), // If the selected country is 'UAE', it's not required
    }),
    // PersonalPhoto: yup.string().required(t("Personal photo is required")),
    PersonalPhoto: yup
      .mixed()
      .required(t("Personal photo is required")) // Required message
      .test("fileType", t("Invalid Format"), (value) => {
        // Allow empty value for the initial state
        if (!value) return true;

        // Allow both strings (base64, URL) and File objects
        if (typeof value === "string") return true; // Allow URLs or base64 strings

        // Check for File objects
        if (value && value instanceof File) {
          const SUPPORTED_FORMATS = [
            "application/pdf",
            "image/jpg",
            "image/jpeg",
            "image/png",
          ];
          return SUPPORTED_FORMATS.includes(value.type); // Check the file type
        }

        // If none of the conditions are met, return false
        return false;
      }),
    // PersonalPhoto: yup.mixed().required(t("Passport copy is required")),

    // PersonalPhoto: yup
    // .mixed()
    // .required(t("Personal photo is required")) // Required message
    // .test('fileType', t("Unsupported file format"), (value) => {
    //   // Allow empty value for the initial state
    //   if (!value) return true;

    //   // Allow both strings (base64, URL) and File objects
    //   if (typeof value === 'string') return true; // Allow URLs or base64 strings

    //   // Check for File objects
    //   if (value && value instanceof File) {
    //     const SUPPORTED_FORMATS = ["application/pdf", "image/jpg", "image/jpeg", "image/png"];
    //     return SUPPORTED_FORMATS.includes(value.type); // Check the file type
    //   }

    //   // If none of the conditions are met, return false
    //   return false;
    // }),
    Passportcopy: yup
      .mixed()
      .required(t("Passport copy is required")) // Required message
      .test("fileType", t("Invalid Format"), (value) => {
        // Allow empty value for the initial state
        if (!value) return true;

        // Allow both strings (base64, URL) and File objects
        if (typeof value === "string") return true; // Allow URLs or base64 strings

        // Check for File objects
        if (value && value instanceof File) {
          const SUPPORTED_FORMATS = [
            "application/pdf",
            "image/jpg",
            "image/jpeg",
            "image/png",
          ];
          return SUPPORTED_FORMATS.includes(value.type); // Check the file type
        }

        // If none of the conditions are met, return false
        return false;
      }),
    // Passportcopy: yup.mixed().required(t("Passport copy is required")),

    // Visacopy: yup.string().when("Nationality", {
    //   is: (value) => {
    //     return value !== "3be64a90-a285-ef11-b843-005056b3b9cd";
    //   },
    //   // Assuming you only want this field to be required if it's NOT 'UAE'
    //   then: yup.string().required(t("Visa Copy is required")),
    //   otherwise: yup.string().notRequired(), // If the selected country is 'UAE', it's not required
    // }),
    Visacopy: yup.mixed().when("Nationality", {

      // UAE ID on production: d6a2c1a9-c393-ef11-b850-945680105b98
      // UAE ID on staging: 3be64a90-a285-ef11-b843-005056b3b9cd

      is: (value) => value !== "d6a2c1a9-c393-ef11-b850-945680105b98",  
      // is: (value) => value !== "3be64a90-a285-ef11-b843-005056b3b9cd",  
      then: yup
        .mixed()
        .required(t("Visa Copy is required"))
        .test("fileType", t("Invalid Format"), (value) => {
          // Allow empty value for the initial state
          if (!value) return true;

          // Allow both strings (base64, URL) and File objects
          if (typeof value === "string") return true; // Allow URLs or base64 strings

          // Check for File objects
          if (value && value instanceof File) {
            const SUPPORTED_FORMATS = [
              "application/pdf",
              "image/jpg",
              "image/jpeg",
              "image/png",
            ];
            return SUPPORTED_FORMATS.includes(value.type); // Check the file type
          }

          // If none of the conditions are met, return false
          return false;
        }),
      otherwise: yup.mixed().notRequired(), // If the selected country is 'UAE', it's not required
    }),

    // FamilyBook: yup.string().when("Nationality", {
    //   is: (value) => {
    //     return value === "3be64a90-a285-ef11-b843-005056b3b9cd";
    //   },
    //   // Assuming you only want this field to be required if it's NOT 'UAE'
    //   then: yup.string().required(t("Family Book is required")),
    //   otherwise: yup.string().notRequired(), // If the selected country is 'UAE', it's not required
    // }),
    FamilyBook: yup.mixed().when("Nationality", {
      // UAE ID on production: d6a2c1a9-c393-ef11-b850-945680105b98
      // UAE ID on staging: 3be64a90-a285-ef11-b843-005056b3b9cd

      is: (value) => value === "d6a2c1a9-c393-ef11-b850-945680105b98",  
      // is: (value) => value === "3be64a90-a285-ef11-b843-005056b3b9cd",  
      then: yup
        .mixed()
        .required(t("Family Book is required"))
        .test("fileType", t("Invalid Format"), (value) => {
          // Allow empty value for the initial state
          if (!value) return true;

          // Allow both strings (base64, URL) and File objects
          if (typeof value === "string") return true; // Allow URLs or base64 strings

          // Check for File objects
          if (value && value instanceof File) {
            const SUPPORTED_FORMATS = [
              "application/pdf",
              "image/jpg",
              "image/jpeg",
              "image/png",
            ];
            return SUPPORTED_FORMATS.includes(value.type); // Check the file type
          }

          // If none of the conditions are met, return false
          return false;
        }),
      otherwise: yup.mixed().notRequired(), // If the selected country is not 'UAE', it's not required
    }),
    // Emiratesidcopy: yup.mixed().when("LocalOrResident", {
    //   is: true, // If LocalOrResident is true, require the Emirates ID copy
    //   then: yup.mixed().required(t("Emirate ID is required")),
    //   otherwise: yup.mixed().notRequired(), // If LocalOrResident is false, Emirates ID copy is not required
    // }),
    Emiratesidcopy: yup.mixed().when("LocalOrResident", {
      is: true, // If LocalOrResident is true, require the Emirates ID copy
      then: yup
        .mixed()
        .required(t("Emirate ID is required"))
        .test("fileType", t("Invalid Format"), (value) => {
          // Allow empty value for the initial state
          if (!value) return true;

          // Allow both strings (base64, URL) and File objects
          if (typeof value === "string") return true; // Allow URLs or base64 strings

          // Check for File objects
          if (value && value instanceof File) {
            const SUPPORTED_FORMATS = [
              "application/pdf",
              "image/jpg",
              "image/jpeg",
              "image/png",
            ];
            return SUPPORTED_FORMATS.includes(value.type); // Check the file type
          }

          // If none of the conditions are met, return false
          return false;
        }),
      otherwise: yup.mixed().notRequired(), // If LocalOrResident is false, Emirates ID copy is not required
    }),
  });

export const initialValues = {
  Title: "",
  RequestID: "",
  FirstName: "",
  FirstNameAR: "",
  LastName: "",
  LastNameAR: "",
  // MiddleName: "",
  FatherName: "",
  FatherNameAR: "",
  GrandFatherName: "",
  GrandFatherNameAR: "",
  // FamilyName: "",
  MotherName: "",
  PreferredName: "",
  EmiratesID: "",
  LocalOrResident: "", // Initialize with a boolean false
  Nationality: "",
  DateofBirth: "",
  PlaceofBirth: "",
  RegionofBirth: "",
  CountryofBirth: "",
  Email: "",
  MobileNumber: "",
  Religion: "",
  MaritalStatus: "",
  HasDisability: "", // Initialize with a boolean false
  DisabilityDetails: "",
  BloodGroup: "",
  Height: "",
  Weight: "",
  District: "",
  ResidenceAddressinUAE: "",
  // Address: "",
  City: "",
  Region: "",
  Emirate: "",
  HomeCountryAddress: "",
  Country: "",
  CountryCode: "ae",
  unifiedNumber: "",
  PersonalPhoto: "",
  Passportcopy: "",
  Visacopy: "",
  Emiratesidcopy: "",
  Gender: "",
  Remarks: "",
  // isEmiratesIdInProgress: "",
  FamilyBook: "",
  // HasUaeNationality: "",
  // isVisaCopyInProgress: "",
  IsVisitVisa: "", // Initialize with a boolean false
  // IssueLocation: "",
};