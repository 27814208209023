import { Grid } from "@material-ui/core";
import classNames from "classnames";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import StyledButton from "../../../components/StyledButton/StyledButton";
import ClipLoader from "react-spinners/ClipLoader";
// import { onboardingDataActions } from "../../../store/reducers/onboardingReducer";
import useStyle from "./ActionButtons.styles";
import {
  setActiveStep,
  updateFormData,
  fetchApplicantData,
  fetchLookupData
  } from "../../../store/reducers/onboardingReducer";
// import { useFormikContext } from "formik"; // Import useFormikContext

const ActionButtons = ({nationality}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { activeStep, parentId} = useSelector((state) => state.onboarding);
  const styles = useStyle();
  const [isLoading, setIsLoading] = useState(false);
  const [isHovered, setIsHovered] = useState(false)
 
  const handleBack = async () => {
    if (activeStep > 1) {
      setIsLoading(true); // Start loader when back button is clicked
  
      try {
        const registrationId = parentId; // Get the registration ID from state
  
        // Fetch applicant data for the previous step
        await dispatch(fetchApplicantData({ registrationId, stepNo: activeStep - 1 }));
  
        // Define the keys for lookup data you need to fetch
        const lookupKeys = ['GetNationality', 'Country', 'Region', 'AcademiceSpeciality', "Emirates", "Districts"];
  
        // Fetch lookup data
        await dispatch(fetchLookupData(lookupKeys));
  
        // Handle active step decrement logic based on nationality
        if (nationality?.nationality === t("United Arab Emirates")) {
          const newActiveStep = activeStep - 2;
          dispatch(setActiveStep(newActiveStep));
        } else {
          dispatch(setActiveStep(activeStep - 1));
        }
      } catch (error) {
        console.error("Error navigating to the previous step:", error);
      } finally {
        setIsLoading(false); // Stop loader when operations are complete
      }
    }
  };
   return (
    <Grid
      container
      direction="row"
      justifyContent={activeStep === 1 ? "flex-end" : "space-between"}
    >
      {/* {activeStep > 1 && (
        <StyledButton className={styles.button} onClick={handleBack}>
          {t("Previous")}
        </StyledButton>
      )} */}
        {activeStep > 1 && (
        <StyledButton
          className={classNames(styles.button)}
          onClick={handleBack}
          onMouseEnter={() => setIsHovered(true)} // Track when mouse enters
          onMouseLeave={() => setIsHovered(false)} // Track when mouse leaves
        >
          {isLoading ? (
            <ClipLoader size={20} color={isHovered ? "#ffffff" : "#000000"} /> // Change color based on hover state
          ) : (
            t("Previous")
          )}
        </StyledButton>
      )}
      <div>
     
        <StyledButton className={classNames(styles.button)} type="submit">
        {activeStep === 7 ? t("End") : t("Next")}
        </StyledButton>
      </div>
    </Grid>
  );
};

export default ActionButtons;
