import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import APIOnboarding from "../../api/onboardingapi.js";

const apiO = new APIOnboarding(); // Instantiate the API class

const queryParams = new URLSearchParams(window.location.search);
const crmreferenceId = queryParams.get('crmreferenceId');
const requestID = queryParams.get('requestId');
// console.log("crmreferenceId ", crmreferenceId);
// console.log("requestID ", requestID);
let stepNo = 8;
let pid = {};
if (requestID != null && requestID != "") {
  stepNo = 1;
}
if (crmreferenceId != null && crmreferenceId != "") {
  pid = crmreferenceId;
}

// Initial state
const initialState = {
  activeStep: stepNo, // To track the current step
  parentId: pid,
  uniqueRecordId: [],
  personalInformation: {}, // Moved this here
  qualificationDetails: {},
  certificationDetails: {},
  employeeDependentDetails: {},
  employeePassportInfo: {},
  employeeVisaInfo: {},
  isVisaSectionEnabled: true,
  previousEmployeeInfo: [],
  Remarks:"",
  status: "idle", // idle | loading | succeeded | failed
  error: null,
  lookupData: {
    GetNationality: [],
    Country: [],
    GetExperienceField: [],
    AcademiceSpeciality: [],
    Region: [],
  },
};

// Define async thunk Get Api code start
export const fetchLookupData = createAsyncThunk(
  "onboarding/fetchLookupData",
  async (lookupKeys, { rejectWithValue }) => {
    try {
      // console.log("Fetching lookup data...");

      // console.log(`Fetching data for key: ${lookupKeys}`); // Add this line
      // Create an array of promises for each API request
      const fetchPromises = lookupKeys.map((key) =>
      apiO.getLookupData(key).then((response) => {
          if (!response || !response.data) {
            // console.error(`No response data for key: ${key}`);
            throw new Error(`No data for key ${key}`);
          }
          // console.log(`Response for ${key}:`, response);
          if (response.data.Success) {
            return {
              key,
              data: response.data.Payload,
            };
          } else {
            throw new Error(
              response.data.ErrorMessage ||
              `Failed to fetch data for key ${key}`
            );
          }
        })
      );
      // Execute all promises in parallel
      const results = await Promise.all(fetchPromises);

      // Combine results into a single grouped data structure
      const groupedData = results.reduce((acc, { key, data }) => {
        acc[key] = data;
        return acc;
      }, {});

      // console.log("Grouped lookup data:", groupedData);
      return groupedData;
    } catch (error) {
      // console.error("Error fetching lookup data:", error);
      return rejectWithValue(error.message);
    }
  }
);
// Define async thunk Get Api code End

// Async thunk for submitting form data Post Api code
export const submitFormData = createAsyncThunk(
  "onboarding/submitFormData",
  async (
    { selectedFile, activeStep },
    { rejectWithValue, dispatch, getState }
  ) => {
    try {
      const state = getState(); // Get current state
      const activeStep = state.onboarding.activeStep; // Get the current active step
      const parentId = state.onboarding.parentId; // Retrieve parentId
      const uniqueRecordId =
        state.onboarding.uniqueRecordId[activeStep] || null; // Get unique ID for the current step
const isVisaSectionEnabledFromLocalStorage = JSON.parse(sessionStorage.getItem('isVisaSectionEnabled'));
      // console.log("Submitting with parentId:", parentId);
      // console.log("Submitting with uniqueRecordId:", uniqueRecordId);
      // console.log("personalInfo check", personalInfo);

      // Prepare the FormData instance
      const personalInfoToSend = new FormData(); // Declare here

      // Create the object to send based on the active step
      let personalInfoWithStep = {
        stepNo: activeStep, // Include the step number
        isVisaSectionEnabled: isVisaSectionEnabledFromLocalStorage,
      };

      // Function to check if UAE is selected
      const isUAESelected = () => {
        return state.onboarding.personalInformation.nationality === 'United Arab Emirates';
      };

      switch (activeStep) {
        case 1:
          const { attachtments, ...personalInformationWithoutAttachments } = state.onboarding.personalInformation;
          personalInfoWithStep.personalInformation = personalInformationWithoutAttachments;
          break;
        case 2:
          personalInfoWithStep.qualificationDetails =
            state.onboarding.qualificationDetails;
          personalInfoWithStep.parentId = parentId; // Add parentId directly to the main object
          break;
        // case 2:
        //   personalInfoWithStep.qualificationDetails = state.onboarding.qualificationDetails.map(({ attachtments, ...rest }) => rest);
        //   personalInfoWithStep.parentId = parentId; // Add parentId directly to the main object
        //   break;
        case 3:
          personalInfoWithStep.certificationDetails =
            state.onboarding.certificationDetails;
          personalInfoWithStep.parentId = parentId; // Add parentId directly to the main object
          break;
        case 4:
          personalInfoWithStep.employeeDependentDetails =
            state.onboarding.employeeDependentDetails;
          personalInfoWithStep.parentId = parentId; // Add parentId directly to the main object
          break;
        case 5:
          personalInfoWithStep.employeePassportInfo =
            state.onboarding.employeePassportInfo;
          personalInfoWithStep.parentId = parentId; // Add parentId directly to the main object
          break;
        case 6:
          if (!isUAESelected()) {
            personalInfoWithStep.employeeVisaInfo =
              state.onboarding.employeeVisaInfo;
            personalInfoWithStep.parentId = parentId; // Add parentId directly to the main object
          }
          break;
        case 7:
          personalInfoWithStep.previousEmployeeInfo =
            state.onboarding.previousEmployeeInfo;
          personalInfoWithStep.parentId = parentId; // Add parentId directly to the main object
          break;
        default:
          break;
      }

      // console.log("Form Data:", personalInfoToSend);
      // console.log("Form Data response model with Image:", selectedFile);

      personalInfoToSend.append(
        `onboardingProcessRequestModel`,
        JSON.stringify(personalInfoWithStep)
      );

      // console.log("form Data respone Model:", personalInfoWithStep);

      // Append the files
      if (selectedFile && selectedFile.length > 0) {
        selectedFile.forEach((file, index) => {
          personalInfoToSend.append("Attachment", file);
        });
      }

      // Log the personalInfo object to debug
      for (let pair of personalInfoToSend.entries()) {
        if (pair[1] instanceof File) {
          // console.log(
          //   `${pair[0]}: { name: ${pair[1].name}, size: ${pair[1].size}, type: ${pair[1].type} }`
          // );
        } else {
          // console.log(`${pair[0]}: ${pair[1]}`);
        }
      }

      // Make the API request with personalInfo
      // debugger;
      const response = await apiO.post(
        "/CareerPortal/SaveApplicant",
        personalInfoToSend,
        "multipart/form-data"
      );

      // Log the response for debugging
      // console.log("API response for submit data:", response);
      // console.log("APi submit response data for parent id:", response.data.Payload.parentId);
      // console.log("APi submit response data for unique Record id:", response.data.Payload.uniqueRecordId[0]);
      // console.log("APi submit response data for personal Information :", response.data.Payload.personalInformation);
      // console.log("APi submit response data for Qualification Details :", response.data.Payload.qualificationDetails);

      if (response.data.Success) {
        const generatedParentid = response.data.Payload.parentId; // This will be the new uniqueRecordId

        const uniqueRecordIds = response.data.Payload.uniqueRecordId || [];

        const qualificationDetails = response.data.Payload.qualificationDetails || []; // Extract qualification details

        // Set parentId only on the first submission (from personal information)
        if (activeStep === 1) {
          dispatch(setParentId(generatedParentid)); // Set parentId as uniqueRecordId from personal info

          const updatedPersonalInfo = {
            ...state.onboarding.personalInformation,
            uniqueRecordId: generatedParentid, // Set UniqueRecordId for personal information
          };
          // console.log(
          //   "Updated Personal Information before dispatch:",
          //   updatedPersonalInfo
          // );
          dispatch(
            updateFormData({ step: activeStep, data: updatedPersonalInfo })
          );
          // Dispatch to set qualification details in Redux store
          dispatch(setQualificationDetails(qualificationDetails)); // Set qualification details
        }

        if (activeStep > 1) {
          const detailsKey = activeStep === 2
            ? "qualificationDetails"
            : activeStep === 3
              ? "certificationDetails"
              : activeStep === 4
                ? "employeeDependentDetails"
                : activeStep === 5
                  ? "employeePassportInfo"
                  : (activeStep === 6 && !isUAESelected()) ? "employeeVisaInfo"
                    : activeStep === 7 ? "previousEmployeeInfo" : "";

          const details = state.onboarding[detailsKey];
          // For qualificationDetails (step 2)
          if (activeStep === 2) {
            const qualificationDetails = response.data.Payload.qualificationDetails || [];

            // Update state with an empty array if no qualification details are found
            if (qualificationDetails.length > 0) {
              const updatedDetails = qualificationDetails.map((detail, index) => ({
                ...detail,
                uniqueRecordId: uniqueRecordIds[index] || "",
              }));
              dispatch(updateFormData({ step: activeStep, data: updatedDetails }));
              
            }

            const certificationDetails = response.data.Payload.certificationDetails || []; // Assuming this is in your response
            // Dispatch certification details if available
            dispatch(setCertificationDetails(certificationDetails));
          } else if (activeStep === 3) {

            const certificationDetails = response.data.Payload.certificationDetails || [];

            if (certificationDetails.length > 0) {
              const updatedDetails = certificationDetails.map((detail, index) => ({
                ...detail,
                uniqueRecordId: uniqueRecordIds[index] || "",
              }));
              dispatch(updateFormData({ step: activeStep, data: updatedDetails }));
              
            }
            const dependentDetails = response.data.Payload.employeeDependentDetails || [];
            // Dispatch certification details if available
            dispatch(setEmployeeDependentDetails(dependentDetails));
          } else if (activeStep === 4) {

            const employeeDependentDetails = response.data.Payload.employeeDependentDetails || [];

            if (employeeDependentDetails.length > 0) {
              const updatedDetails = employeeDependentDetails.map((detail, index) => ({
                ...detail,
                uniqueRecordId: uniqueRecordIds[index] || "",
              }));
              dispatch(updateFormData({ step: activeStep, data: updatedDetails }));
              
            }
            const employeePassportInfo = response.data.Payload.employeePassportInfo || {};
            // Dispatch certification details if available
            dispatch(setEmployeePassportInfo(employeePassportInfo));
          }
          else if (activeStep === 5) {
            const employeePassportInfo = response.data.Payload.employeePassportInfo || {};



            if (Object.keys(employeePassportInfo).length > 0) {
              // Optionally, you can add uniqueRecordId or any other transformations here
              const updatedPassportInfo = {
                ...employeePassportInfo,
                uniqueRecordId: employeePassportInfo.uniqueRecordId || "", // If you have a uniqueRecordId to add
              };
              // console.log("Updated Employee Passport Info before dispatch:", updatedPassportInfo);
              if (response.data.Payload.isVisaSectionEnabled === false) {
                const previousEmployeeInfo = response.data.Payload.previousEmployeeInfo || [];
                dispatch(setPreviousEmployeeInfo(previousEmployeeInfo));
              }
              else {
                const employeeVisaInfo = response.data.Payload.employeeVisaInfo || {};
                dispatch(setEmployeeVisaInfo(employeeVisaInfo));
              }
            }
          }
          else if (activeStep === 6) {
            const employeeVisaInfo = response.data.Payload.employeeVisaInfo || {};

            if (Object.keys(employeeVisaInfo).length > 0) {
              // Optionally, you can add uniqueRecordId or any other transformations here
              const updatedVisaInfo = {
                ...employeeVisaInfo,
                uniqueRecordId: employeeVisaInfo.uniqueRecordId || "", // If you have a uniqueRecordId to add
              };
              // console.log("Updated Employee Visa Info before dispatch:", updatedVisaInfo);
            }

            const previousEmployeeInfo = response.data.Payload.previousEmployeeInfo || [];
            
              dispatch(setPreviousEmployeeInfo(previousEmployeeInfo));
          }

        }
      } else {
        // If the response indicates failure, reject with a custom message
        return rejectWithValue("Error submitting form data");
      }
    } catch (error) {
      // Log any error that occurs during the API call
      // console.error("Error submitting form data:", error.message);
      return rejectWithValue(error.message);
    }
  }
);
// Async thunk for submitting form data Post Api code

export const fetchApplicantData = createAsyncThunk(
  "onboarding/fetchApplicantData",
  async ({ registrationId, stepNo }, { rejectWithValue }) => {
    try {
      // console.log(
      //   `Fetching applicant data for Registration ID: ${registrationId}, Step No: ${stepNo}`
      // );
      const response = await apiO.getApplicantData(registrationId, stepNo);
      // console.log("API Response:", response);
      if (response.data.Success) {
        // console.log("response data paylod reducer", response.data.Payload);
        if(response.data.Payload.stepNo === 1)
        {
          sessionStorage.setItem('isVisaSectionEnabled', JSON.stringify(response.data.Payload.isVisaSectionEnabled));
          sessionStorage.setItem('Remarks', JSON.stringify(response.data.Payload.personalInformation.Remarks));
        }
        // Return the payload along with uniqueRecordId
        return response.data.Payload
      } else {
        throw new Error(
          response.data.ErrorMessage || "Failed to fetch applicant data"
        );
      }
    } catch (error) {
      // console.error("Error fetching applicant data:", error.message);
      return rejectWithValue(error.message);
    }
  }
);

// Create a slice
const onboardingDataSlice = createSlice({
  name: "onboarding",
  initialState,
  reducers: {
    setParentId(state, action) {
      state.parentId = action.payload; // Store the parentId
    },
    setActiveStep(state, action) {
      // Log the step change
      // console.log("Setting active step:", action.payload);
      state.activeStep = action.payload;
    },
    setuniqueRecordId: (state, action) => {
      const { step, id } = action.payload; // Destructure step and id
      state.uniqueRecordId[step] = id; // Store the unique ID for the corresponding step
    },
    setQualificationDetails(state, action) {
      state.qualificationDetails = action.payload;
    },
    setCertificationDetails(state, action) {
      state.certificationDetails = action.payload;
    },
    setEmployeeDependentDetails(state, action) {
      state.employeeDependentDetails = action.payload;
    },
    setEmployeePassportInfo(state, action) {
      state.employeePassportInfo = action.payload;
    },
    setEmployeeVisaInfo(state, action) {
      state.employeeVisaInfo = action.payload;
    },
    setPreviousEmployeeInfo(state, action) {
      state.previousEmployeeInfo = action.payload;
    },
    // Update form data in the state dynamically based on the step
    updateFormData(state, action) {
      const { step, data } = action.payload; // Destructure step and data from the payload
      // console.log("Updating form data for step:", step, "with data:", data);

      switch (step) {
        case 1:
          state.personalInformation = {
            ...state.personalInformation,
            ...data,
          };
          break;
        case 2:
          state.qualificationDetails = data;
         
          break;
        case 3:
          state.certificationDetails = data;
          
          break;

        case 4:
          state.employeeDependentDetails = data;
          
          break;
        case 5:
          // Assuming data looks like: { employeePassportInfo: { ... } }
          const { employeePassportInfo: passportInfo } = data; // Destructure to get passportInfo

          state.employeePassportInfo = {
            ...state.employeePassportInfo,
            ...passportInfo, // Merge directly without nesting
          };
          break;
        case 6:
          // Assuming data looks like: { employeePassportInfo: { ... } }
          const { employeeVisaInfo: visaInfo } = data; // Destructure to get passportInfo

          state.employeeVisaInfo = {
            ...state.employeeVisaInfo,
            ...visaInfo, // Merge directly without nesting
          };
          break;

        case 7:
          state.previousEmployeeInfo = data;
        
          break;
        // Add more cases for additional steps as needed
        default:
          console.warn("Unknown step:", step);
          break;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLookupData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchLookupData.fulfilled, (state, action) => {
        // console.log("Fetched lookup data successfully:", action.payload);

        // Ensure the payload is what you expect
        if (action.payload) {
          // console.log("Grouped lookup data:", action.payload);
        }

        state.status = "succeeded";
        state.lookupData = {
          ...state.lookupData,
          ...action.payload,
        };
      })
      .addCase(fetchLookupData.rejected, (state, action) => {
        // console.error("Failed to fetch lookup data:", action.payload);
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(submitFormData.pending, (state) => {
        // Log the pending state
        // console.log("Form submission pending");
        state.status = "loading";
      })
      .addCase(submitFormData.fulfilled, (state) => {
        // Log the success state
        // console.log("Form submission succeeded");
        state.status = "succeeded";
      })
      .addCase(submitFormData.rejected, (state, action) => {
        // Log the failure state
        // console.error("Form submission failed:", action.payload);
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(fetchApplicantData.pending, (state) => {
        state.status = "loading";
        // console.log("Fetching applicant data..."); // Log pending state
      })

      .addCase(fetchApplicantData.fulfilled, (state, action) => {
        // console.log("Fetched applicant data:", action.payload);

        // Destructure payload with default values
        const {
          personalInformation = {},
          qualificationDetails = [],
          certificationDetails = [],
          employeeDependentDetails = [],
          employeePassportInfo = {},
          employeeVisaInfo = {},
          previousEmployeeInfo = [],
          uniqueRecordId = null,
        } = action.payload || {}; // Default to empty object if action.payload is null or undefined

        // Use parentId as uniqueRecordId for personal information
        const parentId = state.parentId;
        // Define a helper function to update the state based on field type
        const updateState = (field, data) => {
          switch (field) {
            case "personalInformation":
              // console.log("Data being set:", data);
              // console.log("Unique Record ID:", uniqueRecordId);
              if (data && Object.keys(data).length > 0) {
                state.personalInformation = {
                  ...data,
                  // uniqueRecordId: uniqueRecordId || state.uniqueRecordId,
                  // uniqueRecordId: uniqueRecordId !== undefined ? uniqueRecordId : state.uniqueRecordId,
                  uniqueRecordId: parentId, // Set uniqueRecordId as parentId
                };
              }
              break;

            case "qualificationDetails":
              if (Array.isArray(data) && data.length > 0) {
                state.qualificationDetails = data.map((qualification) => ({
                  ...qualification,
                  //uniqueRecordId: uniqueRecordId || state.uniqueRecordId,
                }));
              }
              break;

            case "certificationDetails":
              if (Array.isArray(data) && data.length > 0) {
                state.certificationDetails = data.map((certification) => ({
                  ...certification,
                  //uniqueRecordId: uniqueRecordId || state.uniqueRecordId,
                }));
              }
              break;

            case "employeeDependentDetails":
              if (Array.isArray(data) && data.length > 0) {
                state.employeeDependentDetails = data.map((dependent) => ({
                  ...dependent,
                  //uniqueRecordId: uniqueRecordId || state.uniqueRecordId,
                }));
              }
              break;

            case "employeePassportInfo":
              if (data && Object.keys(data).length > 0) {
                state.employeePassportInfo = {
                  ...data,
                  //uniqueRecordId: uniqueRecordId || state.uniqueRecordId,
                };
              }
              break;

            case "employeeVisaInfo":
              if (data && Object.keys(data).length > 0) {
                state.employeeVisaInfo = {
                  ...data,
                  //uniqueRecordId: uniqueRecordId || state.uniqueRecordId,
                };
              }
              break;

            case "PreviousEmployeeInfo":
              if (Array.isArray(data) && data.length > 0) {
                state.previousEmployeeInfo = data.map((previous) => ({
                  ...previous,
                  //uniqueRecordId: uniqueRecordId || state.uniqueRecordId,
                }));
              }
              break;

            default:
              // console.log(`No matching field to update for: ${field}`);
              break;
          }
        };

        // Call the updateState function for each field
        updateState("personalInformation", personalInformation);
        updateState("qualificationDetails", qualificationDetails);
        updateState("certificationDetails", certificationDetails);
        updateState("employeeDependentDetails", employeeDependentDetails);
        updateState("employeePassportInfo", employeePassportInfo);
        updateState("employeeVisaInfo", employeeVisaInfo);
        updateState("PreviousEmployeeInfo", previousEmployeeInfo);

        // Debugging logs to ensure everything is being updated correctly
        // console.log("Updated Personal Information:", state.personalInformation);
        // console.log(
        //   "Updated Qualification Details:",
        //   state.qualificationDetails
        // );
        // console.log(
        //   "Updated Certification Details:",
        //   state.certificationDetails
        // );
        // console.log(
        //   "Updated Employee Dependent Details:",
        //   state.employeeDependentDetails
        // );
        // console.log(
        //   "Updated Employee Passport Info:",
        //   state.employeePassportInfo
        // );
        // console.log("Updated Employee Visa Info:", state.employeeVisaInfo);
        // console.log(
        //   "Updated Previous Employee Info:",
        //   state.PreviousEmployeeInfo
        // );

        // Mark the status as succeeded
        state.status = "succeeded";
      })

      .addCase(fetchApplicantData.rejected, (state, action) => {
        console.error("Failed to fetch applicant data:", action.payload);
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

// Export the actions
export const {
  setActiveStep,
  updateFormData,
  resetForm,
  setParentId,
  setuniqueRecordId,
  setQualificationDetails,
  setCertificationDetails,
  setEmployeeDependentDetails,
  setEmployeePassportInfo,
  setEmployeeVisaInfo,
  setPreviousEmployeeInfo,
} = onboardingDataSlice.actions;

// Export the reducer
export default onboardingDataSlice.reducer;
