import React from "react";
import ReactDom from "react-dom";
import { useSelector } from "react-redux";
import "./Loader.css";

const Loader = () => {
	const { isLoading } = useSelector(state => state.generalStates)
	if (!isLoading) return null;
	return ReactDom.createPortal(
		<div className="loader loader-fixed justify-content-center align-items-center" >
			<div className="loader-loader"><span></span><span></span></div>
		</div>
		, document.body);
}

export default Loader;
