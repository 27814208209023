import React from "react";
import { Route, Redirect } from "react-router-dom";

const PublicRoute = (props) => {
  const { path, exact } = props;
  return (
    <Route
      exact={exact}
      path={path}
      render={(prop) => {
        return !!localStorage.getItem("LoginId") ?
          (
            <Redirect to="/" />
          ) : (
            <props.component {...prop} />
          );
      }}
    />
  );
};

export default PublicRoute;
