import React, { useEffect, useMemo, useState } from "react";
import {
	Divider,
	Grid,
} from "@material-ui/core";
import useStyle from "./VerifyUser.styles";
import logo_login from "../../assets/images/logo-login.svg";
import Footer from "../../components/Footer/Footer";
import Slider from "react-slick";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams, Link } from "react-router-dom";
import LockOpenIcon from '@material-ui/icons/LockOpen';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import "./verifyUser.css";
import { Trans } from "react-i18next";
import { verifyRegistrationToken } from "../../store/reducers/registrationReducer";
import { generalReducerActions } from "../../store/reducers/generalReducer";
import slide1 from "../../assets/images/Login_Page/slide1.png";
import slide2 from "../../assets/images/Login_Page/slide2.png";
import slide3 from "../../assets/images/Login_Page/slide3.png";

const bg = [slide1, slide2, slide3];

const VerifyUser = (props) => {
	const dispatch = useDispatch();
	const styles = useStyle();
	const { t, i18n } = useTranslation();
	let { token } = useParams();
	const [validUser, setValidUser] = useState("");
	const [message, setMessage] = useState();
	const [index, setIndex] = useState(0);

	const sliderText = useMemo(() => ['Attracting young talents, to develop and maintain their abilities', 'Discovering the talents and competencies of human cadres in the specialized and technical fields', 'Our vision is an emirate with integrated sustainable urban development and outstanding municipal services for the well-being and happiness of the community'], [])

	useEffect(() => {
		async function verifyUser() {
			dispatch(generalReducerActions.toggleLoading(true));
			const response = await dispatch(verifyRegistrationToken(token));
			if (response.payload.status === 200) {
				setValidUser(true);
				dispatch(generalReducerActions.toggleLoading(false));
			} else {
				(response.payload.data.Message && response.payload.data.Message !== "false") && setMessage(response.payload.data.Message)
				setValidUser(false);
				dispatch(generalReducerActions.toggleLoading(false));
			}
		}
		verifyUser();
	}, [token, dispatch]);

	useEffect(() => {
		document.title = `${t('Verify User')} - ${t("DMT Career Portal")}`
	}, [i18n.language, t])

	return (
		<Grid container direction="row">
			<Grid item className={`${styles.loginFormGrid} login-form-wrapper`}>
				{
					validUser ? (
						<>
							<div className={styles.loginLogo}>
								<img src={logo_login} className={styles.logo} alt="logo" />
							</div>
							<div className="success-msg">
								<span className="icon" alt="Success Icon"><LockOpenIcon /></span>
								<div className={styles.messageText}>
									<Trans>Thank you for validating your email with us. Please <Link to="/login" className="link">login</Link> to proceed for further actions</Trans>
								</div>
							</div>
						</>
					) :
						(
							<>
								<div className={styles.loginLogo}>
									<img src={logo_login} className={styles.logo} alt="logo" />
								</div>
								<div className="error-msg">
									<span className="icon" alt="Success Icon"><LockOutlinedIcon /></span>
									<div className={styles.messageText}>
										{Boolean(message) ? t(message) : t("Your email is either validated already or not registered in our system.")}
									</div>
								</div></>
						)
				}
				<Footer className={styles.footer} />
			</Grid>
			<Grid item className={`${styles.backgroundGrid} login-slider-section`} style={{ backgroundImage: `url(${bg[index]})` }}>
				<p className={styles.welcomeText}>{t("Welcome to")}</p>
				<p className={styles.infoText}>{t("DMT CAREER PORTAL")}</p>
				<Divider className={styles.divider} />
				<Slider
					arrows={false}
					dots={true}
					speed={500}
					autoplay={true}
					autoplaySpeed={5000}
					dotsClass={styles.sliderDots}
					afterChange={(slide) => setIndex(slide)}
				>
					{sliderText.map((txt, index) => (
						<div key={index}>
							<h4>{t(txt)}</h4>
						</div>
					))}
				</Slider>
			</Grid>
			<Footer className="slider-footer" />
		</Grid>
	);
};

export default VerifyUser;
