import React, { useEffect, useMemo, useState } from "react";
import { Grid } from "@material-ui/core";
import Header from "../../components/Header/Header";
import useStyle from "./JobDetails.styles";
import StyledButton from "../../components/StyledButton/StyledButton";
import Slider from "react-slick";
import JobCard from "../../components/JobCard/JobCard";
import { Link, useHistory, useParams } from "react-router-dom";
import { sliderSettings, getJobDetailsData } from "./utility";
import { useDispatch, useSelector } from "react-redux";
import { ArrowForward } from "@material-ui/icons";
import {
  applyForJob,
  getJobDetailsById,
  getRelatedJobs,
} from "../../store/reducers/jobDataReducer";
import { useTranslation } from "react-i18next";
import { generalReducerActions } from "../../store/reducers/generalReducer";
import "./JobDetails.css";
import bg from "../../assets/images/Details & Registration Page/HR_3_1366X14318.png";
import { Modal, Backdrop, Fade, Button } from "@material-ui/core";

const JobDetails = () => {
  const { jobDetails, relatedJobs } = useSelector((state) => state.jobData);
  const dispatch = useDispatch();
  const styles = useStyle();
  const { t, i18n } = useTranslation();
  let { id } = useParams();
  const history = useHistory();
  const [open, setOpen] = useState(false);

  const jobDetailsData = useMemo(
    () => Object.keys(jobDetails)?.length !== 0 && getJobDetailsData(jobDetails, i18n.language, t),
    [jobDetails, i18n.language, t]
  );

  const applyforJobHandler = async () => {
    if (!!localStorage.getItem("LoginId")) {
      dispatch(generalReducerActions.toggleLoading(true))
      const response = await dispatch(
        applyForJob({
          ApplicantId: localStorage.getItem("LoginId"),
          JobId: id,
        })
      )
      if (response.payload.status === 200) {
        dispatch(generalReducerActions.toggleLoading(false))
        if (response.payload.data.IsValidated) {
          dispatch(generalReducerActions.snackBarUpdate({
            open: true,
            message: "Your application is submitted successfully.",
            severity: 'success',
          }));
        }
        if (!response.payload.data.IsValidated) {
          let message = response.payload.data?.Message instanceof Array ? response.payload.data.Message.map(ele => i18n.language === 'en' ? ele.MessageEn : ele.MessageAr) : response.payload.data?.Message;
          dispatch(generalReducerActions.snackBarUpdate({
            open: true,
            message: message || "Something went wrong. We are having trouble completing your request, please try again",
            severity: 'error',
          }));
        }
      }
      // else {
      //   dispatch(generalReducerActions.toggleLoading(false))
      //   dispatch(generalReducerActions.snackBarUpdate({
      //     open: true,
      //     message: response.payload.data?.Message.map(ele => i18n.language === 'en' ? ele.MessageEn : ele.MessageAr) || "Something went wrong. We are having trouble completing your request, please try again",
      //     severity: 'error',
      //   }));
      // }
    }
    else {
      setOpen(true);
    }
  };

  useEffect(() => {
    async function fetchData() {
      dispatch(generalReducerActions.toggleLoading(true))
      const response1 = await dispatch(getJobDetailsById(id));
      if (response1.payload.status === 200) {
        dispatch(generalReducerActions.toggleLoading(false))
        window.scroll({
          top: 0,
          left: 0,
        });
      }
    }
    fetchData()
  }, [dispatch, id]);

  useEffect(() => {
    if (jobDetails?.categoryId)
      dispatch(getRelatedJobs({ JobCategoryId: jobDetails?.categoryId }));
  }, [dispatch, jobDetails?.categoryId]);

  useEffect(() => {
    document.title = `${t("Job Details")} - ${t("DMT Career Portal")}`
  }, [t])

  if (Object.keys(jobDetails).length === 0) {
    return null;
  }

  return (
    <React.Fragment>
      <Header bg={bg}>
        <Grid className={styles.containerHead} container direction="column">
          <div className={styles.mainHeading}>
            <h4 className={styles.infoText}>
              {i18n.language === "ar" ? jobDetails?.titleAR : jobDetails?.titleEN}
            </h4>
          </div>
        </Grid>
      </Header>
      <Modal
        className={styles.modal}
        open={open}
        onClose={() => setOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={styles.paper}>
            <p className={styles.loginText}>{t("Please login, if you don't have an account please sign up")}</p>
            <Grid className={styles.signupGrid} container direction="row" justifyContent="space-between" alignItems="center">
              <Button className={styles.loginButton} variant="outlined" color="primary" onClick={() => history.push({
                pathname: "/login",
                state: { jobDetailsPage: true }
              })}>{t("SignIn")}</Button>
              <Link to="/registration-form" className={styles.linkBold}>
                {t("SignUp")}
              </Link>
            </Grid>
          </div>
        </Fade>
      </Modal>
      <Grid
        className={styles.jobDescriptionGrid}
        container
        direction="row"
        justifyContent="space-between"
      >
        <Grid className={styles.descriptionSection} item>
          <h5 className={styles.descriptionText}>{t("Job Description")}</h5>
          <p className="mb-35">
            {i18n.language === "ar"
              ? jobDetails?.descriptionAR
              : jobDetails?.descriptionEN}
          </p>
          <h5 className={styles.descriptionText}>{t("Key Requirements")}</h5>
          <ul className={`${styles.listGoldDots} description-ul mb-35`}>
            {jobDetailsData?.keyRequirements.map((requirement, index) => (
              (requirement && requirement?.length > 0) && <li key={index}>{requirement}</li>
            ))}
          </ul>
          <h5 className={styles.descriptionText}>{t("We Offer")}</h5>
          <ul className={`${styles.listGoldDots} description-ul mb-35`}>
            {jobDetailsData?.offerList.map((offer, index) => (
              offer && <li key={index}>{offer}</li>
            ))}
          </ul>
        </Grid>
        <Grid className={styles.overviewSection} item>
          <h5 className={styles.descriptionText}>{t("Overview")}</h5>
          <div className={styles.jobDetailAside}>
            {jobDetailsData?.overviewData.map((data, index) => (
              data.value && (
                <div className={styles.item} key={index}>
                  <div className={`${styles.icon} job-details-icon`}>{data?.icon}</div>
                  <div>
                    <p style={{ color: "black" }}>{t(data?.key)}</p>
                    <p>{data?.value}</p>
                  </div>
                </div>
              )
            ))}
            <div className={styles.footer}>
              <StyledButton className={styles.btn} onClick={applyforJobHandler}>
                {t("Apply For Job")}
              </StyledButton>
            </div>
          </div>
        </Grid>
      </Grid>
      <div className={styles.sliderGrid}>
        <p className={styles.featureText}>{t("Related Jobs")}</p>
        <Slider
          className={styles.slider}
          {...sliderSettings}
          slidesToShow={relatedJobs?.length > 4 ? 4 : relatedJobs?.length > 1 ? relatedJobs?.length - 1 : 1}
        >
          {relatedJobs?.map((data) => (
            <JobCard
              key={data?.id}
              className={`${styles.card} job-card-slider`}
              headerText={i18n.language === "ar" ? data?.titleAR : data?.titleEN}
              cardBody={i18n.language === "ar" ? data?.descriptionAR : data?.descriptionEN}
              cardFooter={
                <Link to={`/job-details/${data?.id}`} onClick={() => window.scroll({
                  top: 0,
                  left: 0,
                  behavior: "smooth",
                })}>
                  <span>
                    <ArrowForward className={styles.iconForward} />
                  </span>
                </Link>
              }
            />
          ))}
        </Slider>
      </div>
    </React.Fragment >
  );
};

export default JobDetails;
