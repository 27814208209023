import { Grid } from "@material-ui/core";
import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import StyledButton from "../../../components/StyledButton/StyledButton";
import { registrationDataActions } from "../../../store/reducers/registrationReducer";
import useStyle from "./ActionButtons.styles";

const ActionButtons = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { activeStep } = useSelector((state) => state.registration);
  const styles = useStyle();

  const handleBack = () => {
    dispatch(registrationDataActions.setActiveStep(activeStep - 1));
  };

  // const cancelButtonClicked = () => {
  //   dispatch(registrationDataActions.setActiveStep(0));
  //   window.scroll({
  //     top: 0,
  //     left: 0,
  //     behavior: "smooth",
  //   });
  // };

  return (
    <Grid
      container
      direction="row"
      justifyContent={activeStep === 0 ? "flex-end" : "space-between"}
    >
      {activeStep > 0 && (
        <StyledButton className={styles.button} onClick={handleBack}>
          {t("Previous")}
        </StyledButton>
      )}
      <div>
        {/* <StyledButton
          className={`${styles.button} ${styles.margin}`}
          onClick={cancelButtonClicked}
        >
          {t("Cancel")}
        </StyledButton> */}
        <StyledButton className={classNames(styles.button)} type="submit">
          {window.location.pathname === "/user-profile" ? t("Update") : activeStep === 3 ? t("Submit") : t("Next")}
        </StyledButton>
      </div>
    </Grid>
  );
};

export default ActionButtons;
