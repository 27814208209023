import Login from "../../pages/login/Login";
import RegistrationForm from "../../pages/RegistrationForm/RegistrationForm";
import ForgotPassword from "../../pages/forgotPassword/ForgotPassword";
import VerifyUser from "../../pages/verifyUser/VerifyUser";
import ResetPassword from "../../pages/resetPassword/ResetPassword";
import RegistrationSuccess from "../../pages/registrationSuccess/RegistrationSuccess";
import OnboardingForm from "../../pages/OnboardingForm/OnboardingForm";

const publicRoutes = [
  {
    path: "/forgot-password",
    exact: true,
    component: ForgotPassword
  },
  {
    path: "/reset-password",
    exact: true,
    component: ResetPassword
  },
  {
    path: "/verify-user/:token",
    exact: true,
    component: VerifyUser
  },
  {
    path: "/login",
    exact: true,
    component: Login,
  },
  {
    path: "/registration-form",
    exact: true,
    component: RegistrationForm,
  },
  {
    path: "/onboarding-form",
    exact: true,
    component: OnboardingForm,
  },
  {
    path: "/registration-success",
    exact: true,
    component: RegistrationSuccess,
  },
  // {
  //   path: "/",
  //   exact: true,
  //   component: HomePage,
  // },
  // {
  //   path: "/job-details/:id",
  //   exact: true,
  //   component: JobDetails,
  // },
];

const privateRoutes = [
  // {
  //   path: "/",
  //   exact: true,
  //   component: HomePage,
  // },
  // {
  //   path: "/job-details/:id",
  //   exact: true,
  //   component: JobDetails,
  // },
  {
    path: "/user-profile",
    exact: true,
    component: RegistrationForm,
  },
  {
    path: "/change-password",
    exact: true,
    component: ResetPassword
  },
];

export { publicRoutes, privateRoutes };
