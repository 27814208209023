import * as Yup from "yup";

export const getPreviousEmployeeValidation = (t) =>
  Yup.object().shape({
    previousEmployeeInfo: Yup.array()
      .of(
        Yup.object().shape({
          JobTitle: Yup.string()
            .required(t("Job Title is required"))
            .matches(
              /^[A-Za-z\s\u0600-\u06FF]+$/, // Allow English and Arabic letters
              t("Please enter valid value")
            )
            .max(50, t("Character count exceeded")), // Set max length
          CompanyName: Yup.string().required(t("Company Name is required"))
          .matches(
            /^[A-Za-z\s\u0600-\u06FF]+$/, // Allow English and Arabic letters
            t("Please enter valid value")
          )
          .max(50, t("Character count exceeded")), // Set max length,
          CompanyType: Yup.string().required(
            t("Please select an item in the list")
          ),
          StartDate: Yup.date()
            .required(t("Start Date is required"))
            .max(new Date(), t("Start date cannot be a future date")),
          EndDate: Yup.date()
            .required(t("End Date is required"))
            .max(new Date(), t("End date cannot be a future date")) // End date cannot be later than today
            .min(Yup.ref("StartDate"), t("End Date must be after Start Date")) // End date must be after start date
            .test(
              "is-after-start-date",
              t("End Date cannot be the same as Start Date"),
              function (value) {
                const { StartDate } = this.parent;
                return (
                  !StartDate || !value || new Date(value) > new Date(StartDate)
                ); // End date cannot be the same as Start Date
              }
            ),
          Country: Yup.string().required(
            t("Please select an item in the list")
          ),
          ReasonforLeaving: Yup.string().required(
            t("Reason for leaving is required")
          )
          .matches(
            /^[A-Za-z\s\u0600-\u06FF]+$/, // Allow English and Arabic letters
            t("Please enter valid value")
          )
          .max(100, t("Character count exceeded")), // Set max length,
          
        })
      )
      .test("no-date-overlap", function (value) {
        if (!value || value.length < 2) return true; // No overlap if less than 2 records
        const errors = [];
        for (let i = 0; i < value.length; i++) {
          const { StartDate: start1, EndDate: end1 } = value[i];

          for (let j = 0; j < value.length; j++) {
            if (i === j) continue; // Skip comparison with the same record

            const { StartDate: start2, EndDate: end2 } = value[j];

            if (
              new Date(start1) < new Date(end2) &&
              new Date(start2) < new Date(end1)
            ) {
              errors.push(
                this.createError({
                  path: `previousEmployeeInfo[${j}].StartDate`,
                  message: t("There is overlap in employment period"),
                })
              );

              errors.push(
                this.createError({
                  path: `previousEmployeeInfo[${j}].EndDate`,
                  message: t("There is overlap in employment period"),
                })
              );
            }
          }
        }
        if (errors.length > 0) {
          return new Yup.ValidationError(errors);
        }
        return true;
      }),

    acknowledge: Yup.boolean().oneOf([true], t("Acknowledgement is required")),
  });
