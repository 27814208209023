import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    dir: "",
    snackBar: {
        open: false,
        message: "",
        severity: 'error',
    },
    isSidebarOpened: false,
    isLoading: false,
};

const generalReducerSlice = createSlice({
    name: "general",
    initialState,
    reducers: {
        setDir(state, action) {
            state.dir = action.payload;
        },
        snackBarUpdate(state, action) {
            state.snackBar = action.payload
        },
        toggleSidebar(state, action) {
            state.isSidebarOpened = action.payload
        },
        toggleLoading(state, action) {
            state.isLoading = action.payload
        }
    },
});

export const generalReducerActions = generalReducerSlice.actions;

export default generalReducerSlice.reducer;
