import { Divider } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import useStyle from "./Footer.styles";

const Footer = ({ className }) => {
  const { t } = useTranslation();
  const styles = useStyle();
  return (
    <footer className={`${className} ${styles.footer} footer`}>
      <Divider />
      <p className={styles.footerText}>
        {t(
          "©2021 Copyright Department of Municipalities and Transport - All Rights Reserved"
        )}
      </p>
    </footer>
  );
};

export default Footer;
