import { makeStyles } from "@material-ui/core";
import goldDots from "../../assets/images/list-gold-dots-bg.svg";

export default makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: '35%',
    minWidth: '300px',
    textAlign: 'center',
  },
  signupGrid: {
    margin: '10px 10% 0 10%',
    width: '80%',
    "@media (max-width: 790px)": {
      flexDirection: 'column',
      margin: '0',
      width: '100%'
    },
  },
  loginButton: {
    "@media (max-width: 790px)": {
      margin: '10px 0'
    },
  },
  loginText: {
    margin: '10px 0 0 0',
  },
  linkBold: {
    color: "#005F74",
    fontFamily: '"Roboto",  sans-serif',
    textDecoration: "none",
    fontSize: "16px",
    fontWeight: "500",
    margin: '0',
    textAlign: 'center',
    "&:hover": {
      textDecoration: "underline",
    },
  },
  jobDescriptionGrid: {
    backgroundColor: "#fff",
    padding: "2% 8% 1%",
  },
  containerHead: {
    width: "100%",
    padding: "2% 8%",
    marginRight: "auto",
    marginLeft: "auto",
  },
  mainHeading: {
    color: "#fff",
  },
  infoText: {
    margin: "0",
    fontSize: "2rem",
    fontWeight: "700",
    textTransform: "uppercase",
  },
  descriptionText: {
    margin: "20px 0",
    fontSize: "1.1rem",
    fontWeight: "500",
  },
  listGoldDots: {
    marginBottom: "15px",
    listStyleType: "none",
    listStyleImage: `url(${goldDots})`,
    listStylePosition: "initial",
    "& li": {
      marginBottom: "8px",
      fontSize: "inherit",
      lineHeight: "1.2",
    },
  },
  jobDetailAside: {
    position: "relative",
    zIndex: 2,
    border: "1px solid #d8d8d8",
    padding: "18px 30px",
    OBoxShadow: "0 16px 20px rgba(0, 0, 0, 0.1)",
    boxShadow: "0 16px 20px rgba(0, 0, 0, 0.1)",
    marginBottom: "-25px",
    backgroundColor: "#fff",
    "@media (max-width: 770px)": {
      height: "97%",
      backgroundColor: "white",
    },
  },
  item: {
    display: "flex",
    padding: "30px 0",
    borderBottom: "1px solid #ddd",
    "& p": {
      margin: "5px 0 0 10px",
      fontSize: "0.95rem",
      color: "#005F74",
    },
  },
  icon: {
    display: "block",
    width: "44px",
    height: "44px",
    padding: "4px",
    border: "1px solid #005F74",
    borderRadius: "50%",
    fontSize: "1.5rem",
    color: "#005F74",
  },
  footer: {
    padding: "40px 0 55px",
    textAlign: "center",
  },
  btn: {
    width: "130px",
    fontSize: "0.7rem",
    fontWeight: 500,
  },
  sliderGrid: {
    width: "100%",
    padding: "40px 0",
    backgroundColor: "#f2f0f0",
  },
  iconForward: {
    color: "#005F74",
    float: "left",
    fontSize: "1.45rem",
  },
  featureText: {
    color: "#3f3e45",
    fontSize: "1.5rem",
    fontWeight: "700",
    textAlign: "left",
    padding: "0 0 0 120px",
  },
  card: {
    width: "90%",
    minHeight: "200px",
  },
  slider: {
    padding: "0 6% 0 8%",
    "@media (max-width: 490px)": {
      padding: "0 2% 0 10%",
    },
  },
  descriptionSection: {
    width: "65%",
    "@media (max-width: 770px)": {
      width: "100%",
    },
  },
  overviewSection: {
    width: "30%",
    minWidth: "220px",
    "@media (max-width: 770px)": {
      padding: "0 10%",
      width: "100%",
    },
  },
}));
