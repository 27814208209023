import React, { useEffect, useRef, useState } from "react";
import { Divider, Grid, Modal, Fade, Backdrop, Button } from "@material-ui/core";
import ActionButtons from "../ActionButtons/ActionButtons";
import FileUploader from "../../../components/FileUploader/FileUploader";
import { getAttachmentsById, registrationDataActions, saveAttachments, saveLogs } from "../../../store/reducers/registrationReducer";
import { useDispatch } from "react-redux";
import { generalReducerActions } from "../../../store/reducers/generalReducer";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useStyles from "./Attachments.styles";

const Attachments = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const styles = useStyles();
  const cvRef = useRef(null);
  const passportRef = useRef(null);
  const emiratesIdCopyRef = useRef(null);
  const personalPhotoRef = useRef(null);
  const nafisregistrationProofRef = useRef(null);
  const otherAttachmentsRef = useRef(null);
  const [cvFile, setCvFile] = useState({});
  const [passportFile, setPassportFile] = useState({});
  const [emiratesIdCopyFile, setEmiratesIdCopyFile] = useState({});
  const [personalPhotoFile, setPersonalPhotoFile] = useState({});
  const [nafisregistrationProofFile, setNafisregistrationProofFile] = useState({});
  const [otherAttachmentsFile, setOtherAttachmentsFile] = useState({});
  const [open, setOpen] = useState(false);
  const [missingFields, setMissingFields] = useState([])

  const onClickSubmit = async () => {
    let formData = new FormData();
    let id = localStorage.getItem('LoginId') || localStorage.getItem('RegistrationId');
    let fileNumber = 0
    if (Object.keys(cvFile).length > 0) {
      const file = new File([cvFile], `CV-${cvFile.name}`, { type: cvFile.type });
      formData.append(`myfile_${fileNumber}`, file);
      fileNumber = fileNumber + 1;
    }
    if (Object.keys(passportFile).length > 0) {
      const file = new File([passportFile], `Passport-${passportFile.name}`, { type: passportFile.type });
      formData.append(`myfile_${fileNumber}`, file);
      fileNumber = fileNumber + 1;
    }
    if (Object.keys(emiratesIdCopyFile).length > 0) {
      const file = new File([emiratesIdCopyFile], `EmiratesID-${emiratesIdCopyFile.name}`, { type: emiratesIdCopyFile.type });
      formData.append(`myfile_${fileNumber}`, file);
      fileNumber = fileNumber + 1;
    }
    if (Object.keys(personalPhotoFile).length > 0) {
      const file = new File([personalPhotoFile], `PersonalPhoto-${personalPhotoFile.name}`, { type: personalPhotoFile.type });
      formData.append(`myfile_${fileNumber}`, file);
      fileNumber = fileNumber + 1;
    }
    if (Object.keys(nafisregistrationProofFile).length > 0) {
      const file = new File([nafisregistrationProofFile], `NafisRegistrationProof-${nafisregistrationProofFile.name}`, { type: nafisregistrationProofFile.type });
      formData.append(`myfile_${fileNumber}`, file);
      fileNumber = fileNumber + 1;
    }
    if (Object.keys(otherAttachmentsFile).length > 0) {
      const file = new File([otherAttachmentsFile], `Others-${otherAttachmentsFile.name}`, { type: otherAttachmentsFile.type });
      formData.append(`myfile_${fileNumber}`, file);
    }
    dispatch(generalReducerActions.toggleLoading(true))
    const response = await dispatch(saveAttachments({ registrationId: id, data: formData }));
    dispatch(saveLogs(`registration-id=${id}, email=${JSON.parse(localStorage.getItem("UserData")).Email}, status=${response.payload.status}, response-message=${response.payload.data?.Message}`));
    if (response.payload.status === 200) {
      dispatch(generalReducerActions.toggleLoading(false));
      if (window.location.pathname !== "/user-profile") {
        localStorage.removeItem("UserData");
        history.replace("/registration-success");
      };
      if (window.location.pathname === "/user-profile") {
        dispatch(generalReducerActions.snackBarUpdate({
          open: true,
          message: "Your profile has been updated successfully",
          severity: 'success',
        }));
        history.replace("/");
      }
      // if (+localStorage.getItem("RegistrationStatus") === 1) {
      //   const registrationResponse = await dispatch(submitRegistration(id));
      //   if (registrationResponse.payload.status === 200) {
      //     localStorage.removeItem("RegistrationStatus");
      //     history.replace("/registration-success");
      //   }
      // }
      localStorage.removeItem("RegistrationId");
      dispatch(registrationDataActions.setActiveStep(0));
      dispatch(registrationDataActions.clearRegistrationStates());
    } else {
      dispatch(generalReducerActions.toggleLoading(false))
      dispatch(generalReducerActions.snackBarUpdate({
        open: true,
        message: "Something went wrong. We are having trouble completing your request, please try again",
        severity: 'error',
      }));
    }
  };

  const checkMissingFiels = (event) => {
    event.preventDefault();
    let showMissingFileMessage = false;
    let isResidentNational = JSON.parse(localStorage.getItem("UserData")).IsResidentNational;
    const messages = [];
    if (Object.keys(cvFile).length === 0) {
      showMissingFileMessage = true;
      messages.push("CV")
    }
    if (!isResidentNational && Object.keys(passportFile).length === 0) {
      showMissingFileMessage = true;
      messages.push("Passport")
    }
    if (isResidentNational && Object.keys(emiratesIdCopyFile).length === 0) {
      showMissingFileMessage = true;
      messages.push("Emirates ID")
    }
    if (Object.keys(personalPhotoFile).length === 0) {
      showMissingFileMessage = true;
      messages.push("Personal Photo")
    }
    if (Object.keys(nafisregistrationProofFile).length === 0) {
      showMissingFileMessage = true;
      messages.push("Nafis Registration Proof")
    }
    if (showMissingFileMessage) {
      setMissingFields(messages);
      setOpen(showMissingFileMessage)
    } else {
      onClickSubmit();
    }
  }

  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (window.location.pathname === "/user-profile") {
      async function getAttachment() {
        let id = !!localStorage.getItem('LoginId') && localStorage.getItem('LoginId');
        dispatch(generalReducerActions.toggleLoading(true));
        const response = await dispatch(getAttachmentsById(id));
        if (response.payload.status === 200) {
          dispatch(generalReducerActions.toggleLoading(false));
          response.payload.data.forEach(file => {
            if (file?.FileName?.includes('CV')) {
              setCvFile({ name: file.FileName, url: file.DownloadUrl, type: "", file: "remote" })
            }
            if (file?.FileName?.includes('Passport')) {
              setPassportFile({ name: file.FileName, url: file.DownloadUrl, type: "", file: "remote" })
            }
            if (file?.FileName?.includes('EmiratesID')) {
              setEmiratesIdCopyFile({ name: file.FileName, url: file.DownloadUrl, type: "", file: "remote" })
            }
            if (file?.FileName?.includes('PersonalPhoto')) {
              setPersonalPhotoFile({ name: file.FileName, url: file.DownloadUrl, type: "", file: "remote" })
            }
            if (file?.FileName?.includes('NafisRegistrationProof')) {
              setNafisregistrationProofFile({ name: file.FileName, url: file.DownloadUrl, type: "", file: "remote" })
            }
            if (file?.FileName?.includes('Others')) {
              setOtherAttachmentsFile({ name: file.FileName, url: file.DownloadUrl, type: "", file: "remote" })
            }
          })
        } else {
          dispatch(generalReducerActions.toggleLoading(false));
          dispatch(generalReducerActions.snackBarUpdate({
            open: true,
            message: "Something went wrong. We are having trouble completing your request, please try again",
            severity: 'error',
          }));
        }
      }
      getAttachment();
    }
  }, [dispatch]);

  // useEffect(() => {
  //   return () => {
  //     dispatch(registrationDataActions.setActiveStep(0));
  //   }
  // }, [dispatch])
  return (
    <>
      <Modal
        className={styles.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={styles.paper}>
            <h2 id="transition-modal-title">{missingFields.length > 1 ? t(`The required Attachments are missed`) : t(`The required Attachment is missed`)}</h2>
            <ol>
              {
                missingFields.map((field, index) => (
                  <li key={index}>{t(field)}</li>
                ))
              }
            </ol>
            <p id="transition-modal-description">{missingFields.length > 1 ? t(`Please upload missing Attachments`) : t(`Please upload missing Attachment`)}</p>
            <Grid container direction="row" justifyContent="space-between">
              <Button variant="outlined" color="primary" onClick={handleClose}>{t("Close")}</Button>
              {/* <Button variant="outlined" color="default" onClick={handleClose}>{t("Cancle")}</Button> */}
            </Grid>
          </div>
        </Fade>
      </Modal>
      <form onSubmit={checkMissingFiels}>
        <FileUploader
          headingText="Upload CV"
          helperText="Or Drag And Drop CV Here"
          inputFileRef={cvRef}
          state={cvFile}
          setState={setCvFile}
        />
        <FileUploader
          headingText="Upload Passport Copy"
          helperText="Or Drag And Drop Passport Here"
          inputFileRef={passportRef}
          state={passportFile}
          setState={setPassportFile}
        />
        <FileUploader
          headingText="Upload Emirates ID Copy"
          helperText="Or Drag And Drop Emirates ID Copy Here"
          inputFileRef={emiratesIdCopyRef}
          state={emiratesIdCopyFile}
          setState={setEmiratesIdCopyFile}
        />
        <FileUploader
          headingText="Upload Personal Photo"
          helperText="Or Drag And Drop Personal Photo Here"
          inputFileRef={personalPhotoRef}
          state={personalPhotoFile}
          setState={setPersonalPhotoFile}
        />
        <FileUploader
          headingText="Upload Nafis Registration Proof"
          helperText="Or Drag And Drop Nafis Registration Proof Here"
          inputFileRef={nafisregistrationProofRef}
          state={nafisregistrationProofFile}
          setState={setNafisregistrationProofFile}
        />
        
        <FileUploader
          headingText="Upload Other Attachments"
          helperText="Or Drag And Drop Other Attachments Here"
          inputFileRef={otherAttachmentsRef}
          state={otherAttachmentsFile}
          setState={setOtherAttachmentsFile}
        />
        <Divider style={{ margin: "30px 0" }} />
        <ActionButtons />
      </form>
    </>
  );
};

export default Attachments;
