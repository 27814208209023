import React from "react";
import useStyle from "./StyledButton.styles";
import { Button } from "@material-ui/core";

const StyledButton = ({ className, ...props }) => {
  const styles = useStyle();
  return (
    <Button
      variant="outlined"
      className={`${styles.button} ${className}`}
      {...props}
    >
      {props.children}
    </Button>
  );
};

export default StyledButton;
