import * as yup from "yup";

export const getExperienceDetailsValidation = (t) => yup.object().shape({
    experience: yup.array().of(yup.object().shape({
        Status: yup
            .number()
            .required(t('Status is required')),
        CompanyName: yup
            .mixed()
            .when("Status", {
                is: (Status) => /^(1|2)$/.test(Status),
                then: yup.string().required(t('Company Name is required')).nullable()
            }),
        ExperienceFieldId: yup
            .string()
            .when("Status", {
                is: (Status) => /^(1|2|4)$/.test(Status),
                then: yup.string().required(t('Experience Field is required'))
            }),
        JobName: yup
            .string().nullable()
            .when("Status", {
                is: (Status) => /^(1|2|4)$/.test(Status),
                then: yup.string().required(t('Job Name is required')).nullable()
            }),
        StartDate: yup
            .date()
            .when("Status", {
                is: (Status) => /^(1|2|4)$/.test(Status),
                then: yup.date().typeError(t('Please select Start Date')).max(new Date(), t("Start date cannot be later than today."))
                    .required(t('Please select Start Date'))
            }),
        EndDate: yup
            .date()
            .when("Status", {
                is: 2,
                then: yup.date().typeError(t("Please select End Date")).min(
                    yup.ref('StartDate'),
                    ({
                        min
                    }) => t(`End date cannot be earlier than Start date.`),
                )
                    .max((d => new Date(d.setDate(d.getDate() - 1)))(new Date), t("End date cannot be later than yesterday."))
                    .required(t('Please select End Date'))
            }),
        CurrentSalary: yup
            .number()
            .when("Status", {
                is: (Status) => /^(1|2)$/.test(Status),
                then: yup.number().required(t('Salary is required'))
            }),
    }))
});
