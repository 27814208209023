import { makeStyles } from "@material-ui/core";

export default makeStyles(() => ({
  arrowNext: {
    flip: false,
    position: "absolute",
    right: "0",
    transform: "translateY(-50%)",
    width: "unset",
    height: "unset",
    visibility: "hidden",
    "& img": {
      visibility: "visible",
    },
  },
  arrowPrev: {
    flip: false,
    display: "none",
    position: "absolute",
    transform: "translateY(-50%)",
    zIndex: "1",
    width: "unset",
    height: "unset",
    visibility: "hidden",
    "& img": {
      visibility: "visible",
    },
  },
}));
