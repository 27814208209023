import { makeStyles } from "@material-ui/core";

export default makeStyles(() => ({
  button: {
    height: "40px",
    width: "100px",
    border: "2px solid #707070 !important",
    "&:hover": {
      color: "white",
      backgroundColor: "black !important",
      borderColor: "black !important"
    },
  },
}));
