import React from "react";
import { Route, Redirect } from "react-router-dom";

const PrivateRoute = (props) => {
  const { path, exact } = props;
  return (
    <Route
      exact={exact}
      path={path}
      render={(prop) => {
        return !!localStorage.getItem("LoginId") ? (
          <props.component {...prop} />
        ) : (
          <Redirect to="/login" />
        );
      }}
    />
  );
};

export default PrivateRoute;
