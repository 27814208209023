import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../api";
import {
  featuredCategoriesDataFormatter,
  featuredJobsDataFormatter,
  jodDetailsDataFormatter,
} from "../../dataFormatters/formatters";

const api = new API();

const initialState = {
  featuredJobs: [],
  featuredCategories: [],
  jobDetails: {},
  relatedJobs: [],
};

export const getFeaturedJobs = createAsyncThunk(
  "jobData/getFeaturedJobs",
  async (body) => {
    const response = await api.post("api/Job/GetPublishedJobsListing", body);
    return { data: response.data, status: response.status };
  }
);

export const getFeaturedCategories = createAsyncThunk(
  "jobData/getFeaturedCategories",
  async () => {
    const response = await api.get("api/Job/GetFeaturedJobCountByCategory?isFeaturedJob=false");
    return { data: response.data, status: response.status };
  }
);

export const getJobDetailsById = createAsyncThunk(
  "jobData/getJobDetailsById",
  async (id) => {
    const response = await api.get(`api/Job/GetJobDetailByJobId?jobId=${id}`);
    return { data: response.data, status: response.status };
  }
);

export const applyForJob = createAsyncThunk(
  "jobData/applyForJob",
  async (data, { rejectWithValue }) => {
    try {
      const response = await api.post("api/Job/SubmitJobApplication", data);
      return { data: response.data, status: response.status };
    }
    catch (error) {
      return rejectWithValue({ data: error.data, status: error.status })
    }
  }
);

export const getRelatedJobs = createAsyncThunk(
  "jobData/getRelatedJobs",
  async (body) => {
    const response = await api.post("api/Job/GetPublishedJobsListing", body);
    return { data: response.data, status: response.status };
  }
);

const jobDataSlice = createSlice({
  name: "jobData",
  initialState,
  reducers: {},
  extraReducers: {
    [getFeaturedJobs.fulfilled]: (state, action) => {
      state.featuredJobs = featuredJobsDataFormatter(action.payload.data);
    },
    [getFeaturedCategories.fulfilled]: (state, action) => {
      state.featuredCategories = featuredCategoriesDataFormatter(action.payload.data);
    },
    [getJobDetailsById.fulfilled]: (state, action) => {
      state.jobDetails = jodDetailsDataFormatter(action.payload.data);
    },
    [getRelatedJobs.fulfilled]: (state, action) => {
      state.relatedJobs = featuredJobsDataFormatter(action.payload.data);
    },
  },
});

export const jobDataActions = jobDataSlice.actions;

export default jobDataSlice.reducer;
